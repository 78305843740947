
import * as React from 'react';
import { Route, Switch } from 'react-router';
import DashboardContainer from '../../Dashboard/DashboardContainer';
import EntityContainer from '../../Entity/EntityContainer';
import LoginContainer from '../../Login/LoginContainer';
import { ImplicitCallback, SecureRoute } from '@okta/okta-react';
import NotFoundView from './NotFoundVIew';
import RatePlanContainer from '../../RatePlans/RatePlanContainer';
import TransactionContainer from '../../Transaction/TransactionContainer';


const UnauthenticatedAppView: React.StatelessComponent =
  (props) =>
    (
      <Switch>
        <Route path='/implicit/callback' exact={true} component={ImplicitCallback} />
        <Route path='*' exact={true} component={LoginContainer} />
      </Switch>
    )

export default UnauthenticatedAppView
