import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './AppReducer';
import Dashboard from './DashboardReducer'
import Entity from './EntityReducer'
import Login from './LoginReducer'
import RatePlan from './RatePlanReducer'
import Transaction from './TransactionReducer'
import Members from './MembersReducer'
import EntityReport from './EntityReportReducer'
import LoginImage from './LoginImageReducer'

export const history = createBrowserHistory();

const initialState = { };

const enhancers :[] = [];

const middleware = [
	thunk,
	routerMiddleware(history)
];

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeEnhancers(
	applyMiddleware(...middleware),
	...enhancers
);

const rootReducer = combineReducers({
	router: connectRouter(history),
	App,
	Dashboard,
	Entity,
	Login,
	RatePlan,
	Transaction,
	Members,
	EntityReport,
	LoginImage
});

export default createStore(
	rootReducer,
	initialState,
	composedEnhancers
)
