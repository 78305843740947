import * as React from 'react';
import { Container, Form, Rail, Segment, Button, Message, Header, Icon, Label, Modal } from 'semantic-ui-react';
import { TransactionLayoutViewProps } from './interfaces/TransactionLayoutViewProps';
import { Link } from 'react-router-dom';

const TransactionLayoutView = (props: TransactionLayoutViewProps) => (

  <Container text style={{ paddingBottom: '4em' }}>
    <Form
      size='small'
      className='scottland-transaction-form'
      error={props.transactionForm.error}
      loading={props.transactionForm.loading}
    >
      <Container fluid>
        <Header as='h4' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
          <Icon name='money bill alternate outline' size='small' />
          Transaction
        </Header>
        <div style={{ float: 'right', marginBottom: '.25em' }}>
          {props.transactionActionButtons}
        </div>
        <hr className='scottland-divider-small' style={{ clear: 'both', marginBottom: '1em' }} />
      </Container>
      {props.transactionForm.fields.id.value !== null &&
        props.transactionForm.touched === true &&
        <Container
          fluid
          size='small'
          style={{
            margin: '0 0 2em',
          }}
        >
          <div>
            You've edited <strong>{props.transactionForm.touchedCount}</strong> {props.transactionForm.touchedCount > 1 ? 'fields' : 'field'}.
          </div>
          <Button
            icon='save'
            labelPosition='right'
            color='olive'
            type='submit'
            size='mini'
            floated='right'
            content='Save'
            compact
            loading={props.transactionForm.loading}
            disabled={props.transactionForm.loading}
            onClick={props.onEditTransaction}
          />
          <Button
            icon='undo'
            color='grey'
            basic
            content='Cancel'
            labelPosition='right'
            size='mini'
            type='button'
            floated='right'
            compact
            disabled={props.transactionForm.loading}
            onClick={props.onCancelFormEdits}
          />
          <div style={{ clear: 'both' }} />
        </Container>
      }
      <Message
        error
        icon='exclamation'
        header='Oops!'
        size='small'
        list={props.transactionForm.errorMessages}
      />
      {props.transactionFormComponents}
    </Form>
    <Modal
      basic
      open={props.addTransactionFileUploadForm.open}
      size='small'
    >
      <Modal.Header
        style={{ textAlign: props.addTransactionFileUploadForm.error === true ? undefined : 'center' }}
      >
        {props.addTransactionFileUploadForm.error === true &&
          <Icon name='exclamation' color='orange' size='large' />
        }
        {props.addTransactionFileUploadForm.error === false &&
          <Icon name='sync alternate' color='olive' loading size='large' />
        }
        Files uploading...
      </Modal.Header>
      {props.addTransactionFileUploadForm.error &&
        <>
          <Modal.Content>
            <p>Your transaction was created successfully, but there was a problem uploading one or more of the related files.</p>
            <p>
              <Link
                to={`/transaction/${props.addTransactionFileUploadForm.fields.transactionId.value}`}
                style={{ color: '#fff' }}
              >
                Click <strong style={{ textDecoration: 'underline' }}>here</strong> to go to your new transaction.
          </Link>
            </p>
          </Modal.Content>
          <Modal.Content>
            <Message warning
              icon='exclamation'
              list={props.addTransactionFileUploadForm.errorMessages}
            />
          </Modal.Content>
        </>
      }
    </Modal>
  </Container>
)

export default TransactionLayoutView