

import { Reducer } from 'redux';
import * as EntityReportActions from './creators/interfaces/EntityReportCreators';
import { EntityReportAction } from './creators/interfaces/EntityReportCreators';
import { EntityReportActionTypes } from './creators/EntityReportCreators';
import { handleCancelFormEdits, handleClearFormError, handleFormChange, handleSetFormError, handleSetFormErrorMessages, handleSetFormLoading, handleSetFormOpen } from './FormReducerHandlers';
import { EntityReportState } from './interfaces/EntityReportState';


const initialState: EntityReportState = {
  loading: false,
  error: false,
  errorMessages: [],
  reportSettingsForm: {
    id: 'reportSettingsForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      startDate: {
        id: 'startDate',
        label: 'Start Date',
        placeholder: 'Start Date',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
			},
			endDate: {
        id: 'endDate',
        label: 'End Date',
        placeholder: 'End Date',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
			},
			includeEphemeral: {
        id: 'includeEphemeral',
        label: 'Include Daily Interest Transactions',
        placeholder: 'Include Daily Interest Transactions',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: false },
			},
			includeAnnualCompounds: {
        id: 'includeAnnualCompounds',
        label: 'Include Annual Interest Transactions',
        placeholder: 'Include Annual Interest Transactions',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: false },
			}
    }
  }
};

const handleResetToInitialState = (state: EntityReportState, action: EntityReportAction): EntityReportState => {
  return {
    ...initialState
  };
}

const handleSetLoading = (state: EntityReportState, action: EntityReportActions.SetLoadingAction): EntityReportState => {
  return {
    ...state,
    loading: action.loading
  };
}

const handleSetError = (state: EntityReportState, action: EntityReportActions.SetErrorAction): EntityReportState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: EntityReportState, action: EntityReportActions.SetErrorMessagesAction): EntityReportState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handlers: { [x: string]: (state: EntityReportState, action: EntityReportAction) => EntityReportState } =
  {
    [EntityReportActionTypes.ResetToInitialState]: handleResetToInitialState,
    [EntityReportActionTypes.SetLoading]: handleSetLoading,
    [EntityReportActionTypes.SetError]: handleSetError,
    [EntityReportActionTypes.SetErrorMessages]: handleSetErrorMessages,
    [EntityReportActionTypes.SetFormLoading]: handleSetFormLoading,
    [EntityReportActionTypes.SetFormError]: handleSetFormError,
    [EntityReportActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
    [EntityReportActionTypes.ClearFormError]: handleClearFormError,
    [EntityReportActionTypes.FormChange]: handleFormChange,
    [EntityReportActionTypes.SetFormOpen]: handleSetFormOpen,
    [EntityReportActionTypes.CancelFormEdits]: handleCancelFormEdits
  } as any

const RatePlanReducer: Reducer<EntityReportState, EntityReportAction> = (state: EntityReportState | undefined, action: EntityReportAction): EntityReportState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default RatePlanReducer