

import { Reducer } from 'redux';
import { LoginAction } from './creators/interfaces/LoginCreators';
import { LoginActionTypes } from './creators/LoginCreators';
import { handleClearFormError, handleFormChange, handleSetFormError, handleSetFormErrorMessages, handleSetFormLoading } from './FormReducerHandlers';
import { LoginState } from './interfaces/LoginState';

const initialState: LoginState = {
  loginForm: {
    id: 'loginForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      username: {
        id: 'username',
        label: 'Username',
        placeholder: 'Username',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      password: {
        id: 'password',
        label: 'Password',
        placeholder: 'Password',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  }
};


const handleResetToInitialState = (state: LoginState, action: LoginAction) => {
  return {
    ...initialState,
  };
}


const handlers: { [x: string]: (state: LoginState, action: LoginAction) => LoginState } =
{
  [LoginActionTypes.ResetToInitialState]: handleResetToInitialState,
  [LoginActionTypes.SetFormLoading]: handleSetFormLoading,
  [LoginActionTypes.SetFormError]: handleSetFormError,
  [LoginActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
  [LoginActionTypes.ClearFormError]: handleClearFormError,
  [LoginActionTypes.FormChange]: handleFormChange
} as any

const LoginReducer: Reducer<LoginState, LoginAction> = (state: LoginState | undefined, action: LoginAction): LoginState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default LoginReducer