import * as RatePlanCreators from "./interfaces/RatePlanCreators";
import * as FormCreators from '../creators/FormCreators'
import { ScottlandRatePlanDetails } from "../../lib/interfaces/ScottlandRatePlanDetails";



export const RatePlanActionTypes = {
  ResetToInitialState: 'RATEPLAN_ACTIONS_ResetToInitialState',
  SetFormLoading: 'RATEPLAN_ACTIONS_SetFormLoading',
  SetFormError: 'RATEPLAN_ACTIONS_SetFormError',
  SetFormErrorMessages: 'RATEPLAN_ACTIONS_SetFormErrorMessages',
  ClearFormError: 'RATEPLAN_ACTIONS_ClearFormError',
  SetFormOpen: 'RATEPLAN_ACTIONS_SetFormOpen',
  CancelFormEdits: 'RATEPLAN_ACTIONS_CancelFormEdits',
  FormChange: 'RATEPLAN_ACTIONS_FormChange',
  SetLoading: 'RATEPLAN_ACTIONS_SetLoading',
  SetError: 'RATEPLAN_ACTIONS_SetError',
  SetErrorMessages: 'RATEPLAN_ACTIONS_SetErrorMessages',
  SetRatePlanData: 'RATEPLAN_ACTIONS_SetRatePlanData',
  AddRatePlan: 'RATEPLAN_ACTIONS_AddRatePlan',
  RemoveRatePlan: 'RATEPLAN_ACTIONS_RemoveRatePlan',
  SetEditRatePlanId: 'RATEPLAN_ACTIONS_SetEditRatePlanId',
  SetDeleteRateChangeId: 'RATEPLAN_ACTIONS_SetDeleteRateChangeId',
  SetDeleteRatePlanId: 'RATEPLAN_ACTIONS_SetDeleteRatePlanId',
  SetAddRateChangeRatePlanId: 'RATEPLAN_ACTIONS_SetAddRateChangeRatePlanId',
  SetRatePlan: 'RATEPLAN_ACTIONS_SetRatePlan'
}

export const ResetToInitialState: RatePlanCreators.RatePlanCreator = (): RatePlanCreators.RatePlanAction => (
  { type: RatePlanActionTypes.ResetToInitialState }
);

export const SetFormLoading: RatePlanCreators.RatePlanCreator = (formId: string, loading: boolean): RatePlanCreators.RatePlanAction => (
  FormCreators.SetFormLoading(
    RatePlanActionTypes.SetFormLoading,
    formId,
    loading
  )
);


export const SetFormError: RatePlanCreators.RatePlanCreator = (formId: string, error: boolean): RatePlanCreators.RatePlanAction => (
  FormCreators.SetFormError(
    RatePlanActionTypes.SetFormError,
    formId,
    error
  )
);

export const SetFormErrorMessages: RatePlanCreators.RatePlanCreator = (formId: string, errorMessages: string[]): RatePlanCreators.RatePlanAction => (
  FormCreators.SetFormErrorMessages(
    RatePlanActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
);

export const ClearFormError: RatePlanCreators.RatePlanCreator = (formId: string): RatePlanCreators.RatePlanAction => (
  FormCreators.ClearFormError(
    RatePlanActionTypes.ClearFormError,
    formId
  )
);


export const FormChange: RatePlanCreators.RatePlanCreator =
  (formId: string, field: string, value: string, error: boolean): RatePlanCreators.RatePlanAction => (
    FormCreators.FormChange(
      RatePlanActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );

export const SetFormOpen: RatePlanCreators.RatePlanCreator = (formId: string, loading: boolean): RatePlanCreators.RatePlanAction => (
  FormCreators.SetFormOpen(
    RatePlanActionTypes.SetFormOpen,
    formId,
    loading
  )
);

export const CancelFormEdits: RatePlanCreators.RatePlanCreator =
  (formId: string): RatePlanCreators.RatePlanAction => (
    FormCreators.CancelFormEdits(
      RatePlanActionTypes.CancelFormEdits,
      formId
    )
  );



export const SetLoading: RatePlanCreators.SetLoadingCreator =
  (loading: boolean): RatePlanCreators.SetLoadingAction => (
    {
      type: RatePlanActionTypes.SetLoading,
      loading
    }
  );

export const SetError: RatePlanCreators.SetErrorCreator =
  (error: boolean): RatePlanCreators.SetErrorAction => (
    {
      type: RatePlanActionTypes.SetError,
      error
    }
  );

export const SetErrorMessages: RatePlanCreators.SetErrorMessagesCreator =
  (errorMessages: string[]): RatePlanCreators.SetErrorMessagesAction => (
    {
      type: RatePlanActionTypes.SetErrorMessages,
      errorMessages
    }
  );

export const SetRatePlanData: RatePlanCreators.SetRatePlanDataCreator =
  (ratePlanData: ScottlandRatePlanDetails[]): RatePlanCreators.SetRatePlanDataAction => (
    {
      type: RatePlanActionTypes.SetRatePlanData,
      ratePlanData
    }
  );

export const AddRatePlan: RatePlanCreators.AddRatePlanCreator =
  (addedRatePlan: ScottlandRatePlanDetails): RatePlanCreators.AddRatePlanAction => (
    {
      type: RatePlanActionTypes.AddRatePlan,
      addedRatePlan
    }
  )

  export const RemoveRatePlan :RatePlanCreators.RemoveRatePlanCreator =
    (ratePlanId: number) :RatePlanCreators.RemoveRatePlanAction => (
      {
        type: RatePlanActionTypes.RemoveRatePlan,
        ratePlanId
      }
    );

export const SetEditRatePlanId: RatePlanCreators.SetEditRatePlanIdCreator =
  (ratePlanId: number | null): RatePlanCreators.SetEditRatePlanIdAction => (
    {
      type: RatePlanActionTypes.SetEditRatePlanId,
      ratePlanId
    }
  )

export const SetDeleteRatePlanId: RatePlanCreators.SetDeleteRatePlanIdCreator =
  (ratePlanId: number | null): RatePlanCreators.SetDeleteRatePlanIdAction => (
    {
      type: RatePlanActionTypes.SetDeleteRatePlanId,
      ratePlanId
    }
  )


export const SetAddRateChangeRatePlanId: RatePlanCreators.SetAddRateChangeRatePlanIdCreator =
  (ratePlanId: number | null): RatePlanCreators.SetAddRateChangeRatePlanIdAction => (
    {
      type: RatePlanActionTypes.SetAddRateChangeRatePlanId,
      ratePlanId
    }
  )

export const SetDeleteRateChangeId: RatePlanCreators.SetDeleteRateChangeIdCreator =
  (rateChangeId: number | null): RatePlanCreators.SetDeleteRateChangeIdAction => (
    {
      type: RatePlanActionTypes.SetDeleteRateChangeId,
      rateChangeId
    }
  )

export const SetRatePlan: RatePlanCreators.SetRatePlanCreator =
  (ratePlan: ScottlandRatePlanDetails): RatePlanCreators.SetRatePlanAction => (
    {
      type: RatePlanActionTypes.SetRatePlan,
      ratePlan
    }
  )
  










