import { push } from "connected-react-router";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import * as AuthenticationService from '../../../lib/Authentication/AuthenticationService';
import { OktaToken } from "../../../lib/Authentication/interfaces/OktaAuth";
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import * as AppCreators from '../../../redux/creators/AppCreators';
import * as LoginCreators from '../../../redux/creators/LoginCreators';
import { FormField } from "../../../redux/interfaces/common/FormField";
import { FormState } from "../../../redux/interfaces/common/FormState";
import { LoginFormFields } from "../../../redux/interfaces/LoginState";
import StoreState from "../../../redux/interfaces/StoreState";

export function onCheckAuthentication(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const authenticationToken :OktaToken | null = await AuthenticationService.CheckAuthentication();

    dispatch(AppCreators.SetAuthenticationToken(authenticationToken));

    if(authenticationToken != null) {
      dispatch(push('/dashboard'));
    }
  }
}

export function onLogin(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const loginFormState: FormState<LoginFormFields> = getState().Login.loginForm;

    try {

      dispatch(LoginCreators.ClearFormError(loginFormState.id));
      dispatch(LoginCreators.SetFormLoading(loginFormState.id, true));

      const formChangeCallback = (targetField: string, fieldValue: string | number | null, fieldIsValid: boolean) => {
        dispatch(LoginCreators.FormChange(loginFormState.id, targetField, fieldValue, !fieldIsValid));
      }

      const formIsValid: boolean = ValidateValidationForm(loginFormState.fields, formChangeCallback);

      if (formIsValid === true) {

        const authenticationToken: OktaToken | null = await AuthenticationService.Login(
          loginFormState.fields.username.value as string,
          loginFormState.fields.password.value as string
        )

        dispatch(AppCreators.SetAuthenticationToken(authenticationToken));

        if (authenticationToken !== null) {
          dispatch(LoginCreators.ResetToInitialState())
          dispatch(push('/dashboard'));
        }
        else {
          dispatch(LoginCreators.SetFormError(loginFormState.id, true));
        }
      }
    }
    catch{
      dispatch(LoginCreators.SetFormError(loginFormState.id, true));
    }
    finally {
      dispatch(LoginCreators.SetFormLoading(loginFormState.id, false));
    }
  }
}

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    let targetField: (string | null | undefined) = e.target.name;
    let fieldValue: (string | null | undefined) = e.target.value;

    if (targetField === null || targetField === undefined || targetField === '') {
      targetField = data.name;

      if (data.type === 'checkbox') {
        fieldValue = data.checked;
      }
      else {
        fieldValue = data.value;
      }
    }

    if (targetField === null ||
      targetField === undefined) {
      throw new TypeError(`No target field sent to change handler: ${targetField}`);
    }

    const formState = (getState().Login as any)[formId as any] as FormState<LoginFormFields>;

    if (formState === null ||
      formState === undefined) {
      throw new TypeError(`Unknown form ${formId}`);
    }

    const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

    if (formFieldState === null ||
      formFieldState === undefined) {
      throw new TypeError(`Unknown form field ${targetField}`);
    }

    const fieldIsValid = Validate(fieldValue,
      formFieldState.validation.required,
      formFieldState.validation.validators);

    dispatch(LoginCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

  }
}