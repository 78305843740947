import React from "react";
import { Link } from 'react-router-dom';
import { Container, Dropdown, Icon, Image, Menu } from "semantic-ui-react";
import { LayoutViewProps } from "./interfaces/LayoutViewProps";

export const LayoutView: React.StatelessComponent<LayoutViewProps> =
	(props: LayoutViewProps) =>
		(
			<div>
				<Menu fixed='top' inverted style={{ backgroundColor: '#563922' }}>
					<Container fluid>
						<Menu.Item as='a' header>
							<Image size='small' src='/Padlock-Ranch-Logo.png' style={{ marginRight: '1.5em' }} />
						</Menu.Item>
						<Menu.Item as={Link} to='/dashboard'>
							<Icon name='university' />
							Dashboard
            </Menu.Item>
						<Menu.Item as={Link} to='/add-transaction'>
							<Icon name='money bill alternate outline' />
							Add Transaction
            </Menu.Item>
						{props.administrativeMode === true &&
							<Dropdown item simple text='Setup'>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to='/members'><Icon name='user' />Members</Dropdown.Item>
									<Dropdown.Item as={Link} to='/rate-plans'><Icon name='percent' />Rate Plans</Dropdown.Item>
									<Dropdown.Item as={Link} to='/balance-report'><Icon name='line graph' />Transaction Report</Dropdown.Item>
									<Dropdown.Item as={Link} to='/login-image'><Icon name='image' />Login Image</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						}
						<Menu.Menu position='right'>
							<Menu.Item>{props.loggedInUser}</Menu.Item>
							<Menu.Item
								onClick={props.onLogout}
							>
								<Icon name='log out' />
								Logout
              </Menu.Item>
						</Menu.Menu>
					</Container>
				</Menu>
				<Container fluid style={{ marginTop: '7em' }}>
					{(props as any).children}
				</Container>
			</div>
		)