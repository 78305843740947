
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import StoreState from '../../redux/interfaces/StoreState';
import { EntityReportContainerProps } from './interfaces/EntityReportContainerProps';
import { onCancelFormEdits, onFormChange, onSetFormOpen, onDownloadReportFile } from './thunks/EntityReportThunks';
import EntityReportView from './views/EntityReportView';
import { EntityReportViewProps } from './views/interfaces/EntityReportViewProps';

export class EntityReportContainer extends React.Component<EntityReportContainerProps> {

	private reportFileDownloadRef: React.RefObject<HTMLFormElement>;

	constructor(props: EntityReportContainerProps) {
		super(props);
		this.reportFileDownloadRef = React.createRef();
	}
	
	public componentWillMount() {

		if (this.props.App.authenticationLoading === false &&
			(this.props.App.authenticationToken === null ||
				this.props.App.authenticationToken.claims.is_administrator === false)) {
			this.props.history.push('/404');
			return;
		}
	}

	public render(): React.ReactNode {

		const entityReportViewProps: EntityReportViewProps = {
			reportFileDownloadRef: this.reportFileDownloadRef,
			onDownloadReportFile: () => this.props.onDownloadReportFile(this.reportFileDownloadRef),
			onFormChange: (e :any, data: any) => this.props.onFormChange(this.props.EntityReport.reportSettingsForm.id, e, data),
			reportSettingForm: this.props.EntityReport.reportSettingsForm
		}

		return React.createElement(EntityReportView, entityReportViewProps);
	}

}

const mapStateToProps = (state: StoreState) => ({ App: state.App, EntityReport: state.EntityReport });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
	onFormChange,
	onCancelFormEdits,
	onSetFormOpen,
	onDownloadReportFile
}, dispatch);

export default withRouter(<any>connect(
	mapStateToProps,
	mapDispatchToProps
)(EntityReportContainer));


