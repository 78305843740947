import * as React from 'react';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { TransactionAddButtonsProps as TransactionActionButtonsProps } from './interfaces/TransactionAddButtonsProps';

const TransactionActionButtons = (props: TransactionActionButtonsProps) => (

  <>
    {props.actions.length > 0 &&
      <Button.Group
        size='mini'
        color='olive'
      >
        <Dropdown
          text={props.buttonLabel}
          icon='angle down'
          labeled
          button
          labelPosition=''
          className='icon right labeled'
          options={props.actions}
          loading={props.loading}
          disabled={props.loading}
        />
      </Button.Group>
    }
    {props.actionModal}
  </>

)

export default TransactionActionButtons