import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Reducer } from 'redux';
import { IValidator } from '../lib/Validation/types/Validation';
import { ValidationTypes } from '../lib/Validation/Validation';
import * as TransactionActions from './creators/interfaces/TransactionCreators';
import { TransactionAction } from './creators/interfaces/TransactionCreators';
import { TransactionActionTypes } from './creators/TransactionCreators';
import { handleClearFormError, handleFormChange, handleSetFormError, handleSetFormErrorMessages, handleSetFormLoading, handleSetFormOpen, handleCancelFormEdits, handleClearFormFieldErrors } from './FormReducerHandlers';
import { TransactionState } from './interfaces/TransactionState';
import { DashboardView } from '../lib/interfaces/DashboardView';


const buildTimeOfDayOptions = (): Array<{ key: string, value: string, text: string }> => {

  const timeOfDayOptionsToReturn: Array<{ key: string, value: string, text: string }> = []

  const period = 'minutes'
  const interval = 15;
  const periodsInADay = moment.duration(1, 'day').as(period);
  const startTime = moment('00:00', 'hh:mm');

  for (let i = 0; i < periodsInADay; i += interval) {

    startTime.add(i === 0 ? 0 : interval, period);

    const timeString = startTime.format('hh:mm A')

    timeOfDayOptionsToReturn.push(
      { key: timeString, value: timeString, text: timeString }
    );
  }

  return timeOfDayOptionsToReturn;

}

const initialState: TransactionState = {
  loading: false,
  error: false,
  errorMessages: [],
  transactionLogOpen: false,
  dashboardData: null,
  transaction: null,
  transactionForm: {
    id: 'transactionForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      id: {
        id: 'id',
        label: 'id',
        placeholder: 'id',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      forEntity: {
        id: 'forEntity',
        label: 'For Entity',
        placeholder: 'Select an entity...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      type: {
        id: 'type',
        label: 'Type',
        placeholder: 'Select a transaction type...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        options: [
          { key: 'Deposit', value: 'Deposit', text: 'Deposit' },
          { key: 'Advance', value: 'Advance', text: 'Advance' },
          { key: 'Interest Credit', value: 'Interest Credit', text: 'Interest Credit' },
          { key: 'Interest Charge', value: 'Interest Charge', text: 'Interest Charge' }
        ],
        validation: { required: true },
      },
      effectiveDate: {
        id: 'effectiveDate',
        value: moment().format("YYYY-MM-DD"),
        originalValue: moment().format("YYYY-MM-DD"),
        label: 'Effective Date',
        placeholder: 'Effective Date',
        error: false,
        touched: false,
        validation: {
          required: true,
          validators: {
            type: ValidationTypes.Date,
          } as IValidator
        }
      },
      amount: {
        id: 'amount',
        label: 'Amount',
        placeholder: 'Amount',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      description: {
        id: 'description',
        label: 'Description',
        placeholder: 'Enter a description',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      paymentType: {
        id: 'paymentType',
        label: 'Payment Type',
        placeholder: 'Select a payment type...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        options: [
          { key: 'check', value: 'check', text: 'Check' },
          { key: 'eft', value: 'eft', text: 'EFT' }
        ],
        validation: { required: true },
      },
      referenceNumber: {
        id: 'referenceNumber',
        label: 'Reference Number',
        placeholder: 'Enter a reference number...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  },
  transactionNotes: [],
  addNoteForm: {
    id: 'addNoteForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      noteId: {
        id: 'noteId',
        label: 'noteId',
        placeholder: 'noteId',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      isAdministrative: {
        id: 'isAdministrative',
        label: 'Administrative',
        placeholder: 'Administrative',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: false },
      },
      note: {
        id: 'note',
        label: 'Note',
        placeholder: 'Enter a note...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
    }
  },
  editNoteForm: {
    id: 'editNoteForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      noteId: {
        id: 'noteId',
        label: 'noteId',
        placeholder: 'noteId',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      isAdministrative: {
        id: 'isAdministrative',
        label: 'Administrative',
        placeholder: 'Administrative',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: false },
      },
      note: {
        id: 'note',
        label: 'Note',
        placeholder: 'Enter a note...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
    }
  },
  deleteNoteForm: {
    id: 'deleteNoteForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {}
  },
  deleteNoteId: null,
  editNoteId: null,
  addFileForm: {
    id: 'addFileForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      isAdministrative: {
        id: 'isAdministrative',
        label: 'Administrative',
        placeholder: 'Administrative',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  },
  addTransactionFileUploadForm: {
    id: 'addTransactionFileUploadForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      transactionId: {
        id: 'transactionId',
        label: 'transactionId',
        placeholder: 'transactionId',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  },
  deleteFileForm: {
    id: 'deleteFileForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {}
  },
  deleteFileId: null,
  files: [],
  actionModalForm: {
    id: 'actionModalForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      confirm: {
        id: 'confirm',
        label: 'Confirm',
        placeholder: 'Confirm',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: true },
      },
      actionKey: {
        id: 'actionKey',
        label: 'actionKey',
        placeholder: 'actionKey',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  }
};

const handleResetToInitialState = (state: TransactionState, action: TransactionAction): TransactionState => {
  return {
    ...initialState
  };
}

const handleSetLoading = (state: TransactionState, action: TransactionActions.SetLoadingAction): TransactionState => {
  return {
    ...state,
    loading: action.loading
  };
}


const handleSetTransactionLogOpen = (state: TransactionState, action: TransactionActions.SetTransactionLogOpenAction): TransactionState => {
  return {
    ...state,
    transactionLogOpen: action.open
  };
}

const handleSetError = (state: TransactionState, action: TransactionActions.SetErrorAction): TransactionState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: TransactionState, action: TransactionActions.SetErrorMessagesAction): TransactionState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handleSetDashboardData = (state: TransactionState, action: TransactionActions.SetDashboardDataAction): TransactionState => {
  return {
    ...state,
    dashboardData: action.dashboardData
  };
}

const handleSetNotes = (state: TransactionState, action: TransactionActions.SetNotesAction): TransactionState => {
  return {
    ...state,
    transactionNotes: action.notes
  };
}

const handleAddNote = (state: TransactionState, action: TransactionActions.AddNoteAction): TransactionState => {

  let newNotesArray = state.transactionNotes.concat(action.noteToAdd);

  newNotesArray = newNotesArray.sort((a, b) => (
    a.createdAt !== b.createdAt ?
      a.createdAt === null ? 1 :
        b.createdAt === null ? -1 :
          (a.createdAt! > b.createdAt!) ? -1 : 1 : 0
  ));

  return {
    ...state,
    transactionNotes: newNotesArray
  };
}

const handleDeleteNote = (state: TransactionState, action: TransactionActions.DeleteNoteAction): TransactionState => {
  return {
    ...state,
    transactionNotes: state.transactionNotes.filter(note => note.id !== action.noteIdToDelete)
  };
}

const handleEditNote = (state: TransactionState, action: TransactionActions.EditNoteAction): TransactionState => {

  let noteToEdit = state.transactionNotes.find(note => note.id === action.edittedNote.id);

  if (noteToEdit === undefined) {
    return {
      ...state
    }
  }

  let newNotesArray = state.transactionNotes.filter(note => note.id !== action.edittedNote.id);

  noteToEdit = cloneDeep(noteToEdit);
  noteToEdit.isAdministrative = action.edittedNote.isAdministrative;
  noteToEdit.note = action.edittedNote.note;

  newNotesArray.push(noteToEdit);

  newNotesArray = newNotesArray.sort((a, b) => (
    a.createdAt !== b.createdAt ?
      a.createdAt === null ? 1 :
        b.createdAt === null ? -1 :
          (a.createdAt! > b.createdAt!) ? -1 : 1 : 0
  )
  );

  return {
    ...state,
    transactionNotes: newNotesArray
  };
}

const handleSetEditNoteId = (state: TransactionState, action: TransactionActions.SetEditNoteIdAction): TransactionState => {
  return {
    ...state,
    editNoteId: action.editNoteId
  };
}

const handleSetDeleteFileId = (state: TransactionState, action: TransactionActions.SetDeleteFileIdAction): TransactionState => {
  return {
    ...state,
    deleteFileId: action.deleteFileId
  };
}

const handleSetDeleteNoteId = (state: TransactionState, action: TransactionActions.SetDeleteNoteIdAction): TransactionState => {
  return {
    ...state,
    deleteNoteId: action.deleteNoteId
  };
}

const handleSetTransaction = (state: TransactionState, action: TransactionActions.SetTransactionAction): TransactionState => {
  return {
    ...state,
    transactionForm: {
      ...state.transactionForm,
      error: false,
      errorMessages: [],
      touched: false,
      touchedCount: 0,
      fields: {
        ...state.transactionForm.fields,
        id: {
          ...state.transactionForm.fields.id,
          value: action.transaction.id,
          originalValue: action.transaction.id,
          error: false,
          touched: false
        },
        forEntity: {
          ...state.transactionForm.fields.forEntity,
          value: action.transaction.forEntity.key,
          originalValue: action.transaction.forEntity.key,
          error: false,
          touched: false
        },
        type: {
          ...state.transactionForm.fields.type,
          value: action.transaction.type,
          originalValue: action.transaction.type,
          error: false,
          touched: false
        },
        effectiveDate: {
          ...state.transactionForm.fields.effectiveDate,
          value: moment.unix(action.transaction.effectiveAt).format("YYYY-MM-DD"),
          originalValue: moment.unix(action.transaction.effectiveAt).format("YYYY-MM-DD"),
          error: false,
          touched: false
        },
        amount: {
          ...state.transactionForm.fields.amount,
          value: Math.abs(action.transaction.amount),
          originalValue: Math.abs(action.transaction.amount),
          error: false,
          touched: false
        },
        description: {
          ...state.transactionForm.fields.description,
          value: action.transaction.description,
          originalValue: action.transaction.description,
          error: false,
          touched: false
        },
        paymentType: {
          ...state.transactionForm.fields.paymentType,
          value: action.transaction.paymentType,
          originalValue: action.transaction.paymentType,
          error: false,
          touched: false
        },
        referenceNumber: {
          ...state.transactionForm.fields.referenceNumber,
          value: action.transaction.referenceNumber,
          originalValue: action.transaction.referenceNumber,
          error: false,
          touched: false
        }
      }
    },
    transaction: action.transaction
  };
}

const handleSetFiles = (state: TransactionState, action: TransactionActions.SetFilesAction): TransactionState => {
  return {
    ...state,
    files: action.files
  };
}

const handleAddFile = (state: TransactionState, action: TransactionActions.AddFileAction): TransactionState => {

  let newFilesArray = state.files.concat(action.fileToAdd);

  newFilesArray = newFilesArray.sort((a, b) => (
    a.createdAt !== b.createdAt ?
      a.createdAt === null ? 1 :
        b.createdAt === null ? -1 :
          (a.createdAt! > b.createdAt!) ? -1 : 1 : 0
  ));

  return {
    ...state,
    files: newFilesArray
  };
}

const handleDeleteFile = (state: TransactionState, action: TransactionActions.DeleteFileAction): TransactionState => {
  return {
    ...state,
    files: state.files.filter(file => file.id !== action.fileIdToDelete)
  };
}

const handleSetTransactionResponse = (state: TransactionState, action: TransactionActions.SetTransactionResponseAction): TransactionState => {

  const setTransactionMutationResult = handleSetTransaction(
    state,
    {
      type: TransactionActionTypes.SetTransaction,
      transaction: action.actionResponse.transaction
    }
  );

  let mutatedBranches = setTransactionMutationResult.dashboardData !== null ?
    setTransactionMutationResult.dashboardData.branches : null

  if (mutatedBranches !== null && action.actionResponse.affectedBranchId !== null) {

    const affectedBranch = mutatedBranches.find(branch => branch.id === action.actionResponse.affectedBranchId)!;

    mutatedBranches = mutatedBranches.filter(branch => branch.id !== action.actionResponse.affectedBranchId);

    mutatedBranches.push({
      ...affectedBranch,
      principal: action.actionResponse.branchBalance!,
      formattedPrincipal: action.actionResponse.formattedBranchBalance!
    });

  }

  let mutatedEntities = setTransactionMutationResult.dashboardData!.entities;

  const affectedEntity = mutatedEntities.find(entity => entity.id === action.actionResponse.affectedEntityId)!

  mutatedEntities = mutatedEntities.filter(entity => entity.id !== action.actionResponse.affectedEntityId);

  mutatedEntities.push({
    ...affectedEntity,
    interest: action.actionResponse.interest,
    formattedInterest: action.actionResponse.formattedInterest,
    principal: action.actionResponse.principal,
    formattedPrincipal: action.actionResponse.formattedPrincipal
  });

  mutatedEntities = mutatedEntities.sort((a, b) => (a.name > b.name ? 1 : -1));

  return {
    ...setTransactionMutationResult,
    dashboardData: {
      ...(setTransactionMutationResult.dashboardData as DashboardView),
      branches: mutatedBranches,
      entities: mutatedEntities
    }
  }
}

const handlers: { [x: string]: (state: TransactionState, action: TransactionAction) => TransactionState } =
  {
    [TransactionActionTypes.ResetToInitialState]: handleResetToInitialState,
    [TransactionActionTypes.SetFormOpen]: handleSetFormOpen,
    [TransactionActionTypes.SetFormLoading]: handleSetFormLoading,
    [TransactionActionTypes.SetFormError]: handleSetFormError,
    [TransactionActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
    [TransactionActionTypes.ClearFormError]: handleClearFormError,
    [TransactionActionTypes.FormChange]: handleFormChange,
    [TransactionActionTypes.SetLoading]: handleSetLoading,
    [TransactionActionTypes.SetError]: handleSetError,
    [TransactionActionTypes.SetErrorMessages]: handleSetErrorMessages,
    [TransactionActionTypes.SetDashboardData]: handleSetDashboardData,
    [TransactionActionTypes.SetNotes]: handleSetNotes,
    [TransactionActionTypes.AddNote]: handleAddNote,
    [TransactionActionTypes.DeleteNote]: handleDeleteNote,
    [TransactionActionTypes.EditNote]: handleEditNote,
    [TransactionActionTypes.SetEditNoteId]: handleSetEditNoteId,
    [TransactionActionTypes.SetDeleteNoteId]: handleSetDeleteNoteId,
    [TransactionActionTypes.SetDeleteFileId]: handleSetDeleteFileId,
    [TransactionActionTypes.SetTransaction]: handleSetTransaction,
    [TransactionActionTypes.CancelFormEdits]: handleCancelFormEdits,
    [TransactionActionTypes.SetFiles]: handleSetFiles,
    [TransactionActionTypes.AddFile]: handleAddFile,
    [TransactionActionTypes.DeleteFile]: handleDeleteFile,
    [TransactionActionTypes.ClearFormFieldErrors]: handleClearFormFieldErrors,
    [TransactionActionTypes.SetTransactionResponse]: handleSetTransactionResponse,
    [TransactionActionTypes.SetTransactionLogOpen]: handleSetTransactionLogOpen

  } as any

const TransactionReducer: Reducer<TransactionState, TransactionAction> = (state: TransactionState | undefined, action: TransactionAction): TransactionState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default TransactionReducer