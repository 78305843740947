
import * as OktaAuth from '@okta/okta-auth-js';
import { IOktaAuth, OktaConfig, OktaCredentials, OktaOAuthOptions, OktaToken, OktaTransaction } from "./interfaces/OktaAuth";
import Config from '../../config/Config';

export const oktaConfig: OktaConfig = {
	baseUri: Config.oktaBaseUri,
	clientId: Config.oktaClientId,
	issuer: Config.oktaIssuer,
	redirectUri: Config.oktaRedirectUri,
	url: Config.oktaUrl
};


const authClient: IOktaAuth = new OktaAuth(oktaConfig);

export const Login = async (username: string, password: string): Promise<OktaToken | null> => {
  let transaction: (OktaTransaction | null) = null;
  let tokens: (OktaToken[] | null) = null;

  const credentials: OktaCredentials = {
    username: username,
    password: password
  };

  transaction = await authClient.signIn(credentials);

  if (transaction !== null && transaction.status === 'SUCCESS') {
    const oAuthOptions: OktaOAuthOptions = {
      sessionToken: transaction.sessionToken,
      responseType: ['id_token', 'token'],
      scopes: ['openid', 'email', 'profile'],
      redirect_uri: oktaConfig.redirectUri
    }

    tokens = await authClient.token.getWithoutPrompt(oAuthOptions);

    if (tokens) {
      authClient.tokenManager.add('idToken', tokens[0]);
      authClient.tokenManager.add('accessToken', tokens[1]);

      return tokens[0];
    }
  }

  return null;
}

export const CheckAuthentication = async (): Promise<OktaToken | null> => {

  let session: any = null;
  let idToken: (OktaToken | null) = null;

  try {
    session = await authClient.session.get();
  }
  catch (err) {
    authClient.tokenManager.clear();
    return null;
  }

  idToken = await authClient.tokenManager.get('idToken');

  if (idToken && session) {

    if (session.status === 'INACTIVE') {
      authClient.tokenManager.clear();
      return null;
    }

    return idToken;
  }

  return null;
}

export const GetAccessToken = async (): Promise<OktaToken | null> => {

  let session: any = null;
  let accessToken: (OktaToken | null) = null;

  try {
    session = await authClient.session.get();
  }
  catch (err) {
    authClient.tokenManager.clear();
    return null;
  }

  accessToken = await authClient.tokenManager.get('accessToken');

  if (accessToken && session) {

    if (session.status === 'INACTIVE') {
      authClient.tokenManager.clear();
      return null;
    }

    return accessToken;
  }

  return null;
}

export const Logout = async () => {

  const logoutResult = await authClient.signOut();

  if (logoutResult) { throw TypeError(logoutResult) };

  authClient.tokenManager.clear();
}