import * as React from 'react';
import { Segment, Header, Icon, Button, Container, Form, Message, Dropdown, Input, Label, Modal, Image } from 'semantic-ui-react';
import { EntityReportViewProps } from './interfaces/EntityReportViewProps';
import Config from '../../../config/Config';

const EntityReportView = (props: EntityReportViewProps) => (
	<Container>
		<Header as='h3' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
			<Icon name='line graph' />
			Transaction &amp; Balance Report
    </Header>
		<hr className='scottland-divider' style={{ clear: 'both', marginBottom: '1em', marginTop: '.5em' }} />
		<Form
			error={props.reportSettingForm.error}
		>
			<Form.Group inline widths='equal'>
				<Form.Input
					id={props.reportSettingForm.fields.startDate.id}
					name={props.reportSettingForm.fields.startDate.id}
					label={props.reportSettingForm.fields.startDate.label}
					placeholder={props.reportSettingForm.fields.startDate.placeholder}
					error={props.reportSettingForm.fields.startDate.error}
					value={props.reportSettingForm.fields.startDate.value}
					onChange={props.onFormChange}
					type='date'
					fluid
				/>
				<Form.Input
					id={props.reportSettingForm.fields.endDate.id}
					name={props.reportSettingForm.fields.endDate.id}
					label={props.reportSettingForm.fields.endDate.label}
					placeholder={props.reportSettingForm.fields.endDate.placeholder}
					value={props.reportSettingForm.fields.endDate.value}
					error={props.reportSettingForm.fields.startDate.error}
					onChange={props.onFormChange}
					type='date'
					fluid
				/>
				<Form.Field style={{ cursor: 'pointer', position: 'relative', top: '.5em' }}
					onClick={() => props.onFormChange(
						{
							target: {
								name: props.reportSettingForm.fields.includeEphemeral.id,
								value: !(props.reportSettingForm.fields.includeEphemeral.value as any)
							}
						}, null
					)}
				>
					<Icon
						size='large'
						name={props.reportSettingForm.fields.includeEphemeral.value as any === true ?
							'check circle outline' : 'circle outline'
						}
						color={props.reportSettingForm.fields.includeEphemeral.value as any === true ?
							'olive' : 'grey'
						}
					/>
					{props.reportSettingForm.fields.includeEphemeral.label}
				</Form.Field>
				<Form.Field style={{ cursor: 'pointer', position: 'relative', top: '.5em' }}
					onClick={() => props.onFormChange(
						{
							target: {
								name: props.reportSettingForm.fields.includeAnnualCompounds.id,
								value: !(props.reportSettingForm.fields.includeAnnualCompounds.value as any)
							}
						}, null
					)}
				>
					<Icon
						size='large'
						name={props.reportSettingForm.fields.includeAnnualCompounds.value as any === true ?
							'check circle outline' : 'circle outline'
						}
						color={props.reportSettingForm.fields.includeAnnualCompounds.value as any === true ?
							'olive' : 'grey'
						}
					/>
					{props.reportSettingForm.fields.includeAnnualCompounds.label}
				</Form.Field>
			</Form.Group>
			<Message
				error
				icon='exclamation'
				header='Oops'
				list={props.reportSettingForm.errorMessages}
			/>
			<Button
				size='small'
				color='olive'
				icon='download'
				content='Download Report'
				labelPosition='right'
				as='a'
				href='/balance-transaction-report.xlsx'
				floated='right'
				onClick={(e: any) => { e.preventDefault(); props.onDownloadReportFile(); }}
			/>
		</Form>
		<form
			hidden={true}
			method='post'
			target='_blank'
			ref={props.reportFileDownloadRef}
		>
			<input hidden={true} name='clientId' />
			<input hidden={true} name='token' />
		</form>
	</Container>
)

export default EntityReportView