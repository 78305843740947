import * as React from 'react';
import { Comment, Header, Icon, Segment, Label, Grid, Form, Button, Input, Dropdown, Modal, Message } from 'semantic-ui-react';
import { EntityDetailsViewProps } from './interfaces/EntityDetailsViewProps';
import moment from 'moment';
import { EntityEditViewProps } from './interfaces/EntityEditViewProps';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const EntityEditView = (props: EntityEditViewProps) => (
  <Segment basic>
    <Header as='h5' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
      Entity Details
    </Header>
    <span style={{ float: 'right' }}>
      {props.editEntityForm.touched === true &&
        <>
          <span style={{ marginRight: '2em' }}>
            You've edited&nbsp;<strong>{props.editEntityForm.touchedCount}</strong>&nbsp;
                  {props.editEntityForm.touchedCount > 1 ? 'fields' : 'field'}.
          </span>
          <Button
            icon='save'
            color='olive'
            type='submit'
            size='tiny'
            compact
            loading={props.editEntityForm.loading}
            disabled={props.editEntityForm.loading}
            onClick={props.onSumbit}
            style={{ marginBottom: '.25em' }}
          />
        </>
      }
      <Button
        icon='undo'
        basic
        color='grey'
        compact
        size='tiny'
        onClick={props.onCancel}
        disabled={props.editEntityForm.loading}
        style={{ marginBottom: '.25em' }}
      />
    </span>


    <hr className='scottland-divider-small' style={{ clear: 'both', marginBottom: '1em', marginTop: '.5em' }} />
    <Form
      size='tiny'
      className='entity-edit-form'
      error={props.editEntityForm.error}
      loading={props.editEntityForm.loading}
    >
      <Message
        error
        icon='exclamation'
        header='Oops. Something went wrong editing this entity.'
        list={props.editEntityForm.errorMessages}
      />
      <Grid columns='2' stackable>
        <Grid.Column>
          <Form.Field
            inline
            error={props.editEntityForm.fields.name.error}
            fluid
          >
            <label>{props.editEntityForm.fields.name.label}</label>
            <input
              id={props.editEntityForm.fields.name.id}
              name={props.editEntityForm.fields.name.id}
              value={props.editEntityForm.fields.name.value as any}
              placeholder={props.editEntityForm.fields.name.placeholder}
              onChange={(e) => props.onFormChange(e, null)}
              style={{ width: '80%', float: 'right' }}
            />
          </Form.Field>
          <Form.Field
            inline
            error={props.editEntityForm.fields.branch.error}
          >
            <label>{props.editEntityForm.fields.branch.label}</label>
            <Dropdown
              id={props.editEntityForm.fields.branch.id}
              name={props.editEntityForm.fields.branch.id}
              placeholder={props.editEntityForm.fields.branch.placeholder}
              value={props.editEntityForm.fields.branch.value as any}
              options={props.editEntityForm.fields.branch.options}
              onChange={props.onFormChange}
              selection
              style={{ width: '80%', float: 'right' }}
            />
          </Form.Field>
          <Form.Field
            inline
            fluid
            onClick={() => props.onFormChange(
              {
                target: {
                  name: props.editEntityForm.fields.active.id,
                  value: !(props.editEntityForm.fields.active.value as any)
                }
              }, null
            )}
            style={{
              cursor: 'pointer'
            }}
          >
            <label
              style={{
                cursor: 'pointer'
              }}
            >{
                props.editEntityForm.fields.active.label}
            </label>
            <span style={{ float: 'right' }}>
              {props.editEntityForm.fields.active.value ? 'No' : 'Yes'}
              <Icon
                name='archive'
                circular
                color={props.editEntityForm.fields.active.value ? 'grey' : 'olive'}
                style={{
                  marginLeft: '1em'
                }}
              />
            </span>
          </Form.Field>
          <Form.Dropdown
            id={props.editEntityForm.fields.members.id}
            name={props.editEntityForm.fields.members.id}
            label={props.editEntityForm.fields.members.label}
            error={props.editEntityForm.fields.members.error}
            placeholder={props.editEntityForm.fields.members.placeholder}
            value={props.editEntityForm.fields.members.value as any}
            onChange={props.onFormChange}
            options={props.editEntityForm.fields.members.options}
            selection
            multiple
            fluid
            search
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Field
            inline
            error={props.editEntityForm.fields.establishedAt.error}
            fluid
          >
            <label>{props.editEntityForm.fields.establishedAt.label}</label>
            <input
              id={props.editEntityForm.fields.establishedAt.id}
              name={props.editEntityForm.fields.establishedAt.id}
              value={props.editEntityForm.fields.establishedAt.value as any}
              placeholder={props.editEntityForm.fields.establishedAt.placeholder}
              onChange={(e) => props.onFormChange(e, null)}
              style={{ width: '60%', float: 'right' }}
              type='date'
            />
          </Form.Field>

          <Form.Field
            inline
            error={props.editEntityForm.fields.ratePlan.error}
          >
            <label>{props.editEntityForm.fields.ratePlan.label}</label>
            <Dropdown
              id={props.editEntityForm.fields.ratePlan.id}
              name={props.editEntityForm.fields.ratePlan.id}
              placeholder={props.editEntityForm.fields.ratePlan.placeholder}
              value={props.editEntityForm.fields.ratePlan.value as any}
              options={props.editEntityForm.fields.ratePlan.options}
              onChange={props.onFormChange}
              selection
              style={{ width: '60%', float: 'right' }}
            />
          </Form.Field>

          <Form.Input
            icon
            inline
            iconPosition='left'
            error={props.editEntityForm.fields.balanceForwardPrincipal.error}
            label={props.editEntityForm.fields.balanceForwardPrincipal.label}
            className='entity-form-balance-field'
          >
            <Icon name='dollar sign' />
            <MaskedInput
              id={props.editEntityForm.fields.balanceForwardPrincipal.id}
              name={props.editEntityForm.fields.balanceForwardPrincipal.id}
              placeholder={props.editEntityForm.fields.balanceForwardPrincipal.placeholder}
              value={props.editEntityForm.fields.balanceForwardPrincipal.value as number}
              mask={createNumberMask({
                prefix: '',
                suffix: '',
                allowDecimal: true,
                decimalLimit: 2,
                allowNegative: true
              })}
              onChange={(e) => props.onFormChange(e, null)}
              guide={false}
            />

          </Form.Input>
          <Form.Input
            icon
            iconPosition='left'
            error={props.editEntityForm.fields.balanceForwardInterest.error}
            label={props.editEntityForm.fields.balanceForwardInterest.label}
            inline
            className='entity-form-balance-field'
          >
            <Icon name='dollar sign' />
            <MaskedInput
              id={props.editEntityForm.fields.balanceForwardInterest.id}
              name={props.editEntityForm.fields.balanceForwardInterest.id}
              placeholder={props.editEntityForm.fields.balanceForwardInterest.placeholder}
              value={props.editEntityForm.fields.balanceForwardInterest.value as number}
              mask={createNumberMask({
                prefix: '',
                suffix: '',
                allowDecimal: true,
                decimalLimit: 2,
                allowNegative: true
              })}
              onChange={(e) => props.onFormChange(e, null)}
              guide={false}
            />
          </Form.Input>
          <Form.Field
            inline
            fluid
          >
            <Button
              color='red'
              size='tiny'
              inverted
              compact
              labelPosition='right'
              icon='x'
              content='Delete'
              floated='right'
              onClick={props.onDeleteConfirm}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    </Form>
    <Modal
      basic
      size='small'
      closeIcon
      onClose={props.onDeleteCancel}
      open={props.deleteEntityForm.open}
    >
      <Modal.Header>Confirm Entity Deletion</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          Are you sure you wish to DELETE this entity?
        </Modal.Description>
      </Modal.Content>
      <Modal.Content>
        <Modal.Description>
          All record of this entity will be DELETED!
        </Modal.Description>
        <Modal.Description>
          <strong>This action is <i>not</i> reversible.</strong>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Input
          id={props.deleteEntityForm.fields.confirmEntityName.id}
          name={props.deleteEntityForm.fields.confirmEntityName.id}
          label={{ color: 'red', content: props.deleteEntityForm.fields.confirmEntityName.label }}
          error={props.deleteEntityForm.fields.confirmEntityName.error}
          placeholder={props.deleteEntityForm.fields.confirmEntityName.placeholder}
          value={props.deleteEntityForm.fields.confirmEntityName.value as any}
          onChange={props.onDeleteFormChange}
          fluid
        />
      </Modal.Actions>
      {props.deleteEntityForm.error &&
        <Modal.Content>
          <Message
            error
            size='tiny'
            icon='exclamation'
            header='Oops.'
            list={props.deleteEntityForm.errorMessages}
            style={{
              marginTop: '1em'
            }}
          />
        </Modal.Content>
      }
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='right'
          icon='undo'
          content='Close'
          inverted
          size='tiny'
          onClick={props.onDeleteCancel}
          disabled={props.deleteEntityForm.loading}
        />
        <Button
          color='red'
          labelPosition='right'
          icon='x'
          content='Delete Entity'
          inverted
          size='tiny'
          onClick={props.onDelete}
          loading={props.deleteEntityForm.loading}
          disabled={props.deleteEntityForm.loading}
        />
      </Modal.Actions>
    </Modal>
  </Segment>
)

export default EntityEditView