import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import * as ScottlandApi from "../../../lib/ScottlandApi";
import * as TransactionCreators from "../../../redux/creators/TransactionCreators";
import StoreState from "../../../redux/interfaces/StoreState";
import { FormState } from "../../../redux/interfaces/common/FormState";
import { TransactionFormFields } from "../../../redux/interfaces/TransactionState";
import { FormField } from "../../../redux/interfaces/common/FormField";
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import { ScottlandTransactionNoteView } from "../../../lib/interfaces/ScottlandTransactionNoteView";
import moment from "moment";
import { ScottlandTransactionFileView } from "../../../lib/interfaces/ScottlandTransactionFileView";
import { ScottlandTransactionView } from "../../../lib/interfaces/ScottlandTransactionView";
import { ScottlandCreateTransactionRequest } from "../../../lib/interfaces/ScottlandCreateTransactionRequest";
import { push } from "connected-react-router";
import { ScottlandTransactionActionResponse } from "../../../lib/interfaces/ScottlandTransactionActionResponse";
import { ScottlandTransactionNoteRequest } from "../../../lib/interfaces/ScottlandNoteRequest";
import { ScottlandTransactionFileUploadResponse } from "../../../lib/interfaces/ScottlandTransactionFileUploadResponse";
import Config from "../../../config/Config";
import * as AuthenticationService from '../../../lib/Authentication/AuthenticationService'
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";

export function onLoad(transactionId: string | undefined, entityId: string | undefined): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(TransactionCreators.ResetToInitialState());

    dispatch(TransactionCreators.SetLoading(true));
    dispatch(TransactionCreators.SetError(false));

    try {


      if (transactionId !== undefined) {
        const transaction = await ScottlandApi.GetTransaction(transactionId);

        if (transaction === null) {
          dispatch(TransactionCreators.SetError(true));
          dispatch(TransactionCreators.SetErrorMessages([`Sorry. The transaction with id ${transactionId} does not exist.`]));

          return;
        }
        else {
          dispatch(TransactionCreators.SetTransaction(transaction));
          dispatch(TransactionCreators.SetNotes(transaction.notes));
          dispatch(TransactionCreators.SetFiles(
            transaction.files.map(transactionFile => (
              {
                ...transactionFile,
                url: ScottlandApi.BuildTransactionFileUrl(transactionId, transactionFile.id.toString())
              }
            ))
          ));
        }
      }

      const dashboardData = await ScottlandApi.GetDashboardView();

      dispatch(TransactionCreators.SetDashboardData(dashboardData));

      if (transactionId === undefined && entityId !== undefined) {

        const requestedEntity = dashboardData.entities.find(entity => entity.id.toString() === entityId);

        if (requestedEntity !== undefined) {

          const transactionForm = getState().Transaction.transactionForm;

          dispatch(TransactionCreators.FormChange(
            transactionForm.id, transactionForm.fields.forEntity.id, requestedEntity.id, false));
        }
      }
    }
    catch {
      dispatch(TransactionCreators.SetError(true));
    }
    finally {
      dispatch(TransactionCreators.SetLoading(false));
    }
  }
}

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    let targetField: (string | null | undefined) = e.target.name;
    let fieldValue: (string | null | undefined) = e.target.type !== 'checkbox' ? e.target.value : e.target.checked;

    if (targetField === null || targetField === undefined || targetField === '') {
      targetField = data.name;
      fieldValue = data.value;
    }

    if (targetField === null ||
      targetField === undefined) {
      throw new TypeError(`No target field sent to change handler: ${targetField}`);
    }

    const formState = (getState().Transaction as any)[formId as any] as FormState<TransactionFormFields>;

    if (formState === null ||
      formState === undefined) {
      throw new TypeError(`Unknown form ${formId}`);
    }

    const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

    if (formFieldState === null ||
      formFieldState === undefined) {
      throw new TypeError(`Unknown form field ${targetField}`);
    }

    const fieldIsValid = Validate(fieldValue,
      formFieldState.validation.required,
      formFieldState.validation.validators);

    dispatch(TransactionCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

  }
}

export function onCancelFormEdits(formId: string): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(TransactionCreators.CancelFormEdits(formId));
  }
}

export function onClearFormError(formId: string): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(TransactionCreators.ClearFormError(formId));
  }
}

export function onSetFormOpen(formId: string, open: boolean): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(TransactionCreators.SetFormOpen(formId, open));
  }
}

export function onAddNote(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const addNoteForm = transactionState.addNoteForm;

    dispatch(TransactionCreators.SetFormError(addNoteForm.id, false));

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(TransactionCreators.FormChange(addNoteForm.id, targetField, fieldValue, !fieldIsValid));
    }

    const formIsValid: boolean = ValidateValidationForm(addNoteForm.fields, formChangeCallback);

    if (formIsValid === false) {
      dispatch(TransactionCreators.SetFormError(addNoteForm.id, true));
    }
    else {
      if (transactionState.transaction === null) {

        const { name, email } = getState().App.authenticationToken!.claims;

        const noteToAdd: ScottlandTransactionNoteView = {
          id: moment().unix(),
          transactionId: null,
          createdAt: moment().unix(),
          createdBy: name,
          createdByEmail: email,
          note: addNoteForm.fields.note.value as string,
          isAdministrative: addNoteForm.fields.isAdministrative.value as unknown as boolean,
          isEphemeral: true
        }

        dispatch(TransactionCreators.AddNote(noteToAdd));
        dispatch(TransactionCreators.CancelFormEdits(addNoteForm.id));
      }
      else {

        const noteToAdd: ScottlandTransactionNoteRequest = {
          note: addNoteForm.fields.note.value as string,
          isAdministrative: addNoteForm.fields.isAdministrative.value as unknown as boolean,
        }

        dispatch(TransactionCreators.SetFormLoading(addNoteForm.id, true));

        try {
          const addedNote = await ScottlandApi.AddTransactionNote(transactionState.transaction.id.toString(), noteToAdd);

          dispatch(TransactionCreators.AddNote(addedNote));
          dispatch(TransactionCreators.CancelFormEdits(addNoteForm.id));
          dispatch(TransactionCreators.SetFormOpen(addNoteForm.id, false));


        }
        catch {
          dispatch(TransactionCreators.SetFormError(addNoteForm.id, true));
        }
        finally {
          dispatch(TransactionCreators.SetFormLoading(addNoteForm.id, false));
        }
      }
    }
  }
}

export function onSetDeleteNoteId(noteId: number | null): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(TransactionCreators.SetDeleteNoteId(noteId));
  }
}

export function onSetEditNoteId(noteId: number | null): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionNotes = getState().Transaction.transactionNotes;
    const editNoteForm = getState().Transaction.editNoteForm;

    if (noteId == null) {
      dispatch(TransactionCreators.CancelFormEdits(editNoteForm.id));
    }
    else {
      const noteToEdit = transactionNotes.find(note => note.id === noteId)

      if (noteToEdit === undefined) {
        return
      }

      dispatch(TransactionCreators.FormChange(
        editNoteForm.id,
        editNoteForm.fields.isAdministrative.id,
        noteToEdit.isAdministrative,
        false
      ));

      dispatch(TransactionCreators.FormChange(
        editNoteForm.id,
        editNoteForm.fields.note.id,
        noteToEdit.note,
        false
      ));
    }

    dispatch(TransactionCreators.SetEditNoteId(noteId));
  }
}

export function onDeleteNote(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const deleteNoteId = transactionState.deleteNoteId;
    const transaction = transactionState.transaction;
    const deleteNoteForm = transactionState.deleteNoteForm;

    dispatch(TransactionCreators.SetFormError(deleteNoteForm.id, false));

    if (deleteNoteId === null) {
      throw TypeError('We do not have a note id to delete');
    }

    if (transaction === null) {

      dispatch(TransactionCreators.DeleteNote(transactionState.deleteNoteId));
      dispatch(TransactionCreators.SetDeleteNoteId(null));
    }
    else {

      dispatch(TransactionCreators.SetFormLoading(deleteNoteForm.id, true));

      try {
        await ScottlandApi.DeleteTransactionNote(transaction.id.toString(), deleteNoteId.toString());

        dispatch(TransactionCreators.DeleteNote(deleteNoteId));
        dispatch(TransactionCreators.SetDeleteNoteId(null));
      }
      catch {
        dispatch(TransactionCreators.SetFormError(deleteNoteForm.id, true));
      }
      finally {
        dispatch(TransactionCreators.SetFormLoading(deleteNoteForm.id, false));
      }
    }
  }
}

export function onEditNote(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transaction = getState().Transaction.transaction;
    const transactionNotes = transactionState.transactionNotes;
    const editNoteId = transactionState.editNoteId;
    const editNoteForm = transactionState.editNoteForm;

    if (editNoteId === null || editNoteId === undefined) {
      throw new TypeError('We dont have a note id to edit.')
    }

    dispatch(TransactionCreators.SetFormError(editNoteForm.id, false));

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(TransactionCreators.FormChange(editNoteForm.id, targetField, fieldValue, !fieldIsValid));
    }

    const formIsValid: boolean = ValidateValidationForm(editNoteForm.fields, formChangeCallback);

    if (formIsValid === false) {
      dispatch(TransactionCreators.SetFormError(editNoteForm.id, true));
    }
    else {
      if (transaction === null) {

        const noteToEdit = transactionNotes.find(note => note.id === editNoteId)

        if (noteToEdit === undefined) {
          return
        }

        const editedNote: ScottlandTransactionNoteView = { ...noteToEdit }

        editedNote.isAdministrative = editNoteForm.fields.isAdministrative.value as unknown as boolean;
        editedNote.note = editNoteForm.fields.note.value as string;

        dispatch(TransactionCreators.EditNote(editedNote));
        dispatch(TransactionCreators.SetEditNoteId(null));
        dispatch(TransactionCreators.CancelFormEdits(editNoteForm.id));
      }
      else {

        const noteToEdit: ScottlandTransactionNoteRequest = {
          note: editNoteForm.fields.note.value as string,
          isAdministrative: editNoteForm.fields.isAdministrative.value as unknown as boolean,
        }

        dispatch(TransactionCreators.SetFormLoading(editNoteForm.id, true));

        try {

          const editedNote: ScottlandTransactionNoteView = await ScottlandApi.EditTransactionNote(transaction.id.toString(), editNoteId.toString(), noteToEdit);

          dispatch(TransactionCreators.EditNote(editedNote));
          dispatch(TransactionCreators.CancelFormEdits(editNoteForm.id));
          dispatch(TransactionCreators.SetEditNoteId(null));
        }
        catch {
          dispatch(TransactionCreators.SetFormError(editNoteForm.id, true));
        }
        finally {
          dispatch(TransactionCreators.SetFormLoading(editNoteForm.id, false));
        }

      }
    }
  }
}

export function onSetDeleteFileId(fileId: number | null): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(TransactionCreators.SetDeleteFileId(fileId));
  }
}

export function onDeleteFile(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transaction = transactionState.transaction;
    const deleteFileForm = transactionState.deleteFileForm;
    const deleteFileId = transactionState.deleteFileId

    if (deleteFileId == null) {
      throw new TypeError('We do not have a file id to delete.');
    }

    if (transaction === null) {

      dispatch(TransactionCreators.DeleteFile(transactionState.deleteFileId));
      dispatch(TransactionCreators.SetDeleteFileId(null));
    }
    else {

      dispatch(TransactionCreators.SetFormLoading(deleteFileForm.id, true));

      try {
        await ScottlandApi.DeleteTransactionFile(transaction.id.toString(), deleteFileId.toString());

        dispatch(TransactionCreators.DeleteFile(deleteFileId));
        dispatch(TransactionCreators.SetDeleteFileId(null));
      }
      catch {
        dispatch(TransactionCreators.SetFormError(deleteFileForm.id, true));
      }
      finally {
        dispatch(TransactionCreators.SetFormLoading(deleteFileForm.id, false));
      }
    }
  }
}

export function onFileUpload(e: any, fileInputRef: React.RefObject<HTMLInputElement>): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {


    if (e &&
      e.target &&
      e.target.files &&
      e.target.files.length > 0) {

      const files: FileList = e.target.files;
      const { name, email } = getState().App.authenticationToken!.claims;
      const addFileForm = getState().Transaction.addFileForm;
      const isAdministrative = addFileForm.fields.isAdministrative.value as unknown as boolean;
      const transaction = getState().Transaction.transaction;

      if (transaction === null) {

        for (let i = 0, file; file = files[i]; i++) {

          const uploadedFile: ScottlandTransactionFileView =
          {
            id: moment().unix() + i,
            transactionId: null,
            createdAt: moment().unix(),
            createdBy: name,
            createdByEmail: email,
            name: file.name,
            size: file.size,
            type: file.type,
            isAdministrative: isAdministrative,
            uploadedFile: file
          }

          dispatch(TransactionCreators.AddFile(uploadedFile));

        }
      }
      else {

        dispatch(TransactionCreators.SetFormLoading(addFileForm.id, true));
        dispatch(TransactionCreators.SetFormError(addFileForm.id, false));

        try {

          const filesToUpload: { isAdministrative: boolean, file: File }[] = [];

          for (let i = 0, file; file = files[i]; i++) {
            filesToUpload.push(
              {
                isAdministrative,
                file
              }
            );
          }

          const fileUploadResponse: ScottlandTransactionFileUploadResponse = await ScottlandApi.AddTransactionFiles(transaction.id.toString(), filesToUpload);

          if (fileUploadResponse.error === true) {


              dispatch(TransactionCreators.SetFormError(addFileForm.id, true));
              dispatch(TransactionCreators.SetFormErrorMessages(
                addFileForm.id,
                fileUploadResponse.failedFileReasons.map(failedReason => `${failedReason.key}: ${failedReason.value}`)
              ));
          }

          for (const uploadedFile of fileUploadResponse.successfulFiles) {
            dispatch(TransactionCreators.AddFile(
              { ...uploadedFile, url: ScottlandApi.BuildTransactionFileUrl(transaction.id.toString(), uploadedFile.id.toString()) }
            ));
          }

        }
        catch (error) {

          const e = error as ScottlandApiError;

          dispatch(TransactionCreators.SetFormError(addFileForm.id, true));
          dispatch(TransactionCreators.SetFormErrorMessages(addFileForm.id, error.errors));
        }
        finally {
          dispatch(TransactionCreators.SetFormLoading(addFileForm.id, false));
        }


      }

      fileInputRef.current!.value = '';
    }
  }
}

export function onFileDownload(fileId: number, fileDownloadRef: React.RefObject<HTMLFormElement>): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const fileToDownload = getState().Transaction.files.find(file => file.id === fileId);
    const authToken = await (AuthenticationService.GetAccessToken() as any);

    if (fileToDownload === undefined) {
      return;
    }

    fileDownloadRef!.current!.action = fileToDownload.url as string;
    (fileDownloadRef!.current!.children!.namedItem('clientId')! as any).value = Config.oktaClientId;
    (fileDownloadRef!.current!.children!.namedItem('token')! as any).value = authToken.accessToken;

    fileDownloadRef!.current!.submit();

    fileDownloadRef!.current!.action = '';
    (fileDownloadRef!.current!.children!.namedItem('clientId')! as any).value = '';
    (fileDownloadRef!.current!.children!.namedItem('token')! as any).value = '';

  }
}

export function onConfirmApprove(actionKey: string): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const actionModalForm = transactionState.actionModalForm;

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to approve!')
    }

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(TransactionCreators.FormChange(transactionForm.id, targetField, fieldValue, !fieldIsValid));
    }

    let formIsValid: boolean = false;

    formIsValid = ValidateValidationForm(transactionForm.fields, formChangeCallback);

    if (formIsValid === false) {
      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, ['Please fill out all required fields before approving the transaction.']));
    }
    else {
      dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, true))
      dispatch(TransactionCreators.FormChange(
        actionModalForm.id,
        actionModalForm.fields.actionKey.id,
        actionKey,
        false
      ));
    }
  }
}

export function onConfirmAction(actionKey: string): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const actionModalForm = getState().Transaction.actionModalForm;

    dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, true))
    dispatch(TransactionCreators.FormChange(
      actionModalForm.id,
      actionModalForm.fields.actionKey.id,
      actionKey,
      false
    ));

  }
}

export function onAddTransaction(approved: boolean): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const pendingFieldsToValidate: TransactionFormFields[] = [
      'forEntity',
      'effectiveDate',
      'type',
      'amount',
      'description',
    ];

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const addTransactionFileUploadForm = transactionState.addTransactionFileUploadForm;
    const transactionFiles = transactionState.files;

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    if (transactionState.transaction !== null) {
      throw new TypeError('We should not add adding an existing transaction.')
    }

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(TransactionCreators.FormChange(transactionForm.id, targetField, fieldValue, !fieldIsValid));
    }

    let formIsValid: boolean = false;

    if (approved === true) {
      formIsValid = ValidateValidationForm(transactionForm.fields, formChangeCallback);
    }
    else {

      const pendingFields: { [fieldId: string]: FormField } = {};

      for (const fieldId of pendingFieldsToValidate) {

        pendingFields[fieldId] = transactionForm.fields[fieldId];
      }

      formIsValid = ValidateValidationForm(pendingFields, formChangeCallback);
    }

    if (formIsValid === false) {
      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, ['Please fill out all required fields.']));
    }
    else {

      const newTransaction: ScottlandCreateTransactionRequest = {
        forEntity: transactionForm.fields.forEntity.value as number,
        effectiveAt: moment(transactionForm.fields.effectiveDate.value as string).unix(),
        approved,
        isInterest: (transactionForm.fields.type.value as string).includes('Interest'),
        amount: parseFloat((transactionForm.fields.amount.value as string).split(',').join('')) *
          (((transactionForm.fields.type.value as string) === 'Advance' || (transactionForm.fields.type.value as string) === 'Interest Charge') === true ? -1 : 1),
        description: transactionForm.fields.description.value as string,
        paymentType: transactionForm.fields.paymentType.value as string,
        referenceNumber: transactionForm.fields.referenceNumber.value as string,
        notes: transactionState.transactionNotes.map(note => (
          {
            isAdministrative: note.isAdministrative,
            note: note.note
          }
        ))
      }

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

      try {

        const transactionResponse: ScottlandTransactionView = await ScottlandApi.PostTransaction(newTransaction);

        if (transactionFiles.length > 0) {
          try {

            dispatch(TransactionCreators.SetFormOpen(addTransactionFileUploadForm.id, true));
            dispatch(TransactionCreators.FormChange(addTransactionFileUploadForm.id,
              addTransactionFileUploadForm.fields.transactionId.id,
              transactionResponse.id,
              false
            ));

            const filesToUpload: { isAdministrative: boolean, file: File }[] = [];

            for (const file of transactionFiles) {

              filesToUpload.push(
                {
                  isAdministrative: file.isAdministrative,
                  file: file.uploadedFile!
                }
              )
            }

            const fileUploadResponse: ScottlandTransactionFileUploadResponse = await ScottlandApi.AddTransactionFiles(transactionResponse.id.toString(), filesToUpload);

            if (fileUploadResponse.error === true) {

              dispatch(TransactionCreators.SetFormError(addTransactionFileUploadForm.id, true));
              dispatch(TransactionCreators.SetFormErrorMessages(
                addTransactionFileUploadForm.id,
                fileUploadResponse.failedFileReasons.map(failedReason => `${failedReason.key}: ${failedReason.value}`)
              ));

            }
            else {
              dispatch(push(`/transaction/${transactionResponse.id}`));
            }

          }
          catch (error) {

            const e = error as ScottlandApiError;

            dispatch(TransactionCreators.SetFormError(addTransactionFileUploadForm.id, true));
            dispatch(TransactionCreators.SetFormErrorMessages(addTransactionFileUploadForm.id, e.errors));

          }
        }
        else {
          dispatch(push(`/transaction/${transactionResponse.id}`));
        }

      } catch (error) {

        dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
        dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
      }


    }
  }
}

export function onApproveTransaction(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const actionModalForm = transactionState.actionModalForm;

    dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to approve!')
    }

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    const approvedTransaction: ScottlandTransactionRequest = {
      forEntity: transactionForm.fields.forEntity.value as number,
      effectiveAt: moment(transactionForm.fields.effectiveDate.value as string).unix(),
      isInterest: (transactionForm.fields.type.value as string).includes('Interest'),
      amount: parseFloat((transactionForm.fields.amount.value as string).toString().split(',').join('')) *
        (((transactionForm.fields.type.value as string) === 'Advance' || (transactionForm.fields.type.value as string) === 'Interest Charge') === true ? -1 : 1),
      description: transactionForm.fields.description.value as string,
      paymentType: transactionForm.fields.paymentType.value as string,
      referenceNumber: transactionForm.fields.referenceNumber.value as string
    }

    try {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

      const transactionResponse: ScottlandTransactionActionResponse = await ScottlandApi.ApproveTransaction(
        transactionState.transaction.id.toString(),
        approvedTransaction
      );

      dispatch(TransactionCreators.SetTransactionResponse(transactionResponse));

    } catch (error) {

      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
    }
    finally {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, false));
    }
  }
}

export function onVoidTransaction(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const actionModalForm = transactionState.actionModalForm;

    dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to approve!')
    }

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    try {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

      const transactionResponse: ScottlandTransactionActionResponse = await ScottlandApi.VoidTransaction(
        transactionState.transaction.id.toString()
      );

      dispatch(TransactionCreators.SetTransactionResponse(transactionResponse));

    } catch (error) {

      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
    }
    finally {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, false));
    }
  }
}

export function onInquiryTransaction(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const actionModalForm = transactionState.actionModalForm;

    dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to approve!')
    }

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    try {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

      const transactionResponse: ScottlandTransactionActionResponse = await ScottlandApi.InquiryTransaction(
        transactionState.transaction.id.toString()
      );

      dispatch(TransactionCreators.SetTransactionResponse(transactionResponse));

    } catch (error) {

      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
    }
    finally {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, false));
    }
  }
}

export function onRescindTransaction(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const actionModalForm = transactionState.actionModalForm;

    dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to approve!')
    }

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    try {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

      const transactionResponse: ScottlandTransactionActionResponse = await ScottlandApi.RescindTransaction(
        transactionState.transaction.id.toString()
      );

      dispatch(TransactionCreators.SetTransactionResponse(transactionResponse));

    } catch (error) {

      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
    }
    finally {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, false));
    }
  }
}

export function onEditTransaction(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const pendingFieldsToValidate: TransactionFormFields[] = [
      'forEntity',
      'effectiveDate',
      'type',
      'amount',
      'description',
    ];

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to edit!')
    }

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(TransactionCreators.FormChange(transactionForm.id, targetField, fieldValue, !fieldIsValid));
    }

    let formIsValid: boolean = false;

    if (transactionState.transaction.status === 'Approved') {
      formIsValid = ValidateValidationForm(transactionForm.fields, formChangeCallback);
    }
    else {

      const pendingFields: { [fieldId: string]: FormField } = {};

      for (const fieldId of pendingFieldsToValidate) {

        pendingFields[fieldId] = transactionForm.fields[fieldId];
      }

      formIsValid = ValidateValidationForm(pendingFields, formChangeCallback);
    }

    if (formIsValid === false) {
      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, ['Please fill out all required fields.']));
    }
    else {

      const editedTransaction: ScottlandTransactionRequest = {
        forEntity: transactionForm.fields.forEntity.value as number,
        effectiveAt: moment(transactionForm.fields.effectiveDate.value as string).unix(),
        isInterest: (transactionForm.fields.type.value as string).includes('Interest'),
        amount: parseFloat((transactionForm.fields.amount.value as string).toString().split(',').join('')) *
          (((transactionForm.fields.type.value as string) === 'Advance' || (transactionForm.fields.type.value as string) === 'Interest Charge') === true ? -1 : 1),
        description: transactionForm.fields.description.value as string,
        paymentType: transactionForm.fields.paymentType.value as string,
        referenceNumber: transactionForm.fields.referenceNumber.value as string,
      }

      try {

        dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

        const transactionResponse: ScottlandTransactionActionResponse = await ScottlandApi.EditTransaction(transactionState.transaction.id.toString(), editedTransaction);

        dispatch(TransactionCreators.SetTransactionResponse(transactionResponse));

      } catch (error) {

        dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
        dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
      }
      finally {

        dispatch(TransactionCreators.SetFormLoading(transactionForm.id, false));
      }
    }

  }
}

export function onDeleteTransaction(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionState = getState().Transaction;
    const transactionForm = transactionState.transactionForm;
    const actionModalForm = transactionState.actionModalForm;

    dispatch(TransactionCreators.SetFormOpen(actionModalForm.id, false));

    if (transactionState.transaction === null) {
      throw new TypeError('We do not have a transaction to approve!')
    }

    dispatch(TransactionCreators.SetFormError(transactionForm.id, false));
    dispatch(TransactionCreators.ClearFormFieldErrors(transactionForm.id, false));

    try {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, true));

      await ScottlandApi.DeleteTransaction(
        transactionState.transaction.id.toString()
      );

      dispatch(push('/dashboard'));

    } catch (error) {

      dispatch(TransactionCreators.SetFormError(transactionForm.id, true));
      dispatch(TransactionCreators.SetFormErrorMessages(transactionForm.id, [error]));
    }
    finally {

      dispatch(TransactionCreators.SetFormLoading(transactionForm.id, false));
    }

  }
}

export function onToggleTransactionLogOpen(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const transactionLogOpen = getState().Transaction.transactionLogOpen;

    dispatch(TransactionCreators.SetTransactionLogOpen(!transactionLogOpen));
  }
}








