import * as React from 'react';
import { Segment, Header, Icon, Button, Container, Form, Message, Dropdown, Input, Label, Modal, Image } from 'semantic-ui-react';
import { MembersViewProps } from './interfaces/MembersViewProps';
import Config from '../../../config/Config';

const MembersView = (props: MembersViewProps) => (
  <Container>
    <Header as='h3' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
      <Icon name='users' />
      Members
      {props.loading === false &&
        <Label
          circular
          color='olive'
          content={`${props.filteredMemberCount}/${props.totalMemberCount}`}
        />
      }
    </Header>

    {props.addMemberForm.open === false &&
      <Button
        size='tiny'
        icon='plus'
        color='olive'
        floated='right'
        onClick={props.onAddMember}
        loading={props.addMemberForm.loading}
        disabled={props.loading}
        style={{ float: 'right' }}
      />
    }
    <Image
      as={Button}
      src='/Okta_Logo_BrightBlue_Medium.png'
      href={Config.oktaUrl}
      target='_blank'
      size='tiny'
      floated='right'
      color='blue'
      basic
      compact
      style={{ margin: 0 }}
    />
    <Input
      id={props.memberFilterForm.fields.filter.id}
      name={props.memberFilterForm.fields.filter.id}
      placeholder={props.memberFilterForm.fields.filter.placeholder}
      value={props.memberFilterForm.fields.filter.value}
      onChange={props.onMemberFilterChange}
      loading={props.loading}
      inline
      icon='search'
      style={{ float: 'right', marginRight: '.5em' }}
      size='mini'
    />

    <hr className='scottland-divider' style={{ clear: 'both', marginBottom: '1em', marginTop: '.5em' }} />
    {(props.addMemberForm.open) &&
      <Form
        size='tiny'
        error={props.addMemberForm.error}
        onSubmit={props.onFormSubmit}
        style={{ paddingBottom: '3em' }}
      >
        <Form.Group>
          <Form.Input
            id={props.addMemberForm.fields.firstName.id}
            name={props.addMemberForm.fields.firstName.id}
            value={props.addMemberForm.fields.firstName.value}
            placeholder={props.addMemberForm.fields.firstName.placeholder}
            error={props.addMemberForm.fields.firstName.error}
            onChange={props.onFormChange}
            fluid
            width='4'
          />
          <Form.Input
            id={props.addMemberForm.fields.middleName.id}
            name={props.addMemberForm.fields.middleName.id}
            value={props.addMemberForm.fields.middleName.value}
            placeholder={props.addMemberForm.fields.middleName.placeholder}
            error={props.addMemberForm.fields.middleName.error}
            onChange={props.onFormChange}
            fluid
            width='3'
          />
          <Form.Input
            id={props.addMemberForm.fields.lastName.id}
            name={props.addMemberForm.fields.lastName.id}
            value={props.addMemberForm.fields.lastName.value}
            placeholder={props.addMemberForm.fields.lastName.placeholder}
            error={props.addMemberForm.fields.lastName.error}
            onChange={props.onFormChange}
            fluid
            width='4'
          />
          <Form.Input
            id={props.addMemberForm.fields.email.id}
            name={props.addMemberForm.fields.email.id}
            value={props.addMemberForm.fields.email.value}
            placeholder={props.addMemberForm.fields.email.placeholder}
            error={props.addMemberForm.fields.email.error}
            onChange={props.onFormChange}
            fluid
            width='5'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field width='5'>
            <Dropdown
              id={props.addMemberForm.fields.branch.id}
              name={props.addMemberForm.fields.branch.id}
              value={props.addMemberForm.fields.branch.value as any}
              placeholder={props.addMemberForm.fields.branch.placeholder}
              options={props.addMemberForm.fields.branch.options}
              error={props.addMemberForm.fields.branch.error}
              icon='university'
              selection
              search
              fluid
              inline
              labeled
              onChange={props.onFormChange}
              className='member-edit-branch-dropdown'
            />
          </Form.Field>
          <Form.Field width='11'>
            <Dropdown
              id={props.addMemberForm.fields.entities.id}
              name={props.addMemberForm.fields.entities.id}
              value={props.addMemberForm.fields.entities.value as any}
              placeholder={props.addMemberForm.fields.entities.placeholder}
              error={props.addMemberForm.fields.entities.error}
              options={props.addMemberForm.fields.entities.options}
              multiple
              selection
              search
              fluid
              labeled
              style={{ marginBottom: '1em' }}
              onChange={props.onFormChange}
            />
          </Form.Field>
        </Form.Group>

        <Message
          error
          icon='exclamation'
          header='Oops'
          list={props.addMemberForm.errorMessages}
        />
        <Form.Field>
          <Button
            size='mini'
            color='olive'
            icon='plus circle'
            labelPosition='right'
            content='Add Member'
            floated='right'
            type='submit'
            loading={props.addMemberForm.loading}
            disabled={props.addMemberForm.loading}
            onClick={props.onFormSubmit}
          />
          <Button
            size='mini'
            icon='undo'
            labelPosition='right'
            content='Close'
            floated='right'
            onClick={props.onFormCancel}
            disabled={props.addMemberForm.loading}
          />
        </Form.Field>
      </Form>
    }
    {props.children}
    <Modal
      basic
      size='small'
      closeIcon
      onClose={props.onDeleteMemberCancel}
      open={props.deleteMemberForm.fields.id.value !== null}
    >
      <Modal.Header>Confirm Member Deletion</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          Are you sure you wish to DELETE this member?
        </Modal.Description>
      </Modal.Content>
      <Modal.Content>
        <Modal.Description>
          This member will still be able to login unless their account is deactived in Okta!
        </Modal.Description>
      </Modal.Content>
      {props.deleteMemberForm.error &&
        <Modal.Content>
          <Message
            error
            size='tiny'
            icon='exclamation'
            header='Oops.'
            list={props.deleteMemberForm.errorMessages}
            style={{
              marginTop: '1em'
            }}
          />
        </Modal.Content>
      }
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='right'
          icon='undo'
          content='Close'
          inverted
          size='tiny'
          onClick={props.onDeleteMemberCancel}
          disabled={props.deleteMemberForm.loading}
        />
        <Button
          color='red'
          labelPosition='right'
          icon='x'
          content='Delete Member'
          inverted
          size='tiny'
          onClick={props.onDeleteMember}
          loading={props.deleteMemberForm.loading}
          disabled={props.deleteMemberForm.loading}
        />
      </Modal.Actions>
    </Modal>
  </Container>
)

export default MembersView