
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ScottlandBranchDetailsView } from '../../lib/interfaces/ScottlandBranchView';
import { ScottlandEntityDetailsView } from '../../lib/interfaces/ScottlandEntityView';
import StoreState from '../../redux/interfaces/StoreState';
import { DashboardContainerProps } from './interfaces/DashboardContainerProps';
import { onCancelFormEdits, onEditBranch, onFormChange, onAddEntity, onDeleteBranch, onLoadDashboard, onSetFormOpen, onToggleShowAllEntities, onToggleShowInactiveEntities, onToggleTableView, onAddBranch } from './thunks/DashboardThunks';
import AddAccountView from './views/AddAccountView';
import AddBranchView from './views/AddBranchView';
import AddEntityView from './views/AddEntityView';
import BranchCardsView from './views/BranchCardsView';
import BranchTableView from './views/BranchTableVIew';
import DashboardErrorView from './views/DashboardErrorView';
import DashboardLoadingView from './views/DashboardLoadingView';
import DashboardView from './views/DashboardView';
import EntitiesTableView from './views/EntitiesTableView';
import EntityCardsView from './views/EntityCardsView';
import { AddAccountViewProps } from './views/interfaces/AddAccountViewProps';
import { AddBranchViewProps } from './views/interfaces/AddBranchViewProps';
import { AddEntityViewProps } from './views/interfaces/AddEntityViewProps';
import { BranchCardsViewProps } from './views/interfaces/BranchCardsViewProps';
import { BranchTableViewProps } from './views/interfaces/BranchTableViewProps';
import { DashboardViewProps } from './views/interfaces/DashboardViewProps';
import { EntitiesTableViewProps } from './views/interfaces/EntitiesTableViewProps';
import { EntityCardsViewProps } from './views/interfaces/EntityCardsViewProps';
import { PendingTransactionTableProps } from './views/interfaces/PendingTransactionsTableProps';
import PendingTransactionsTable from './views/PendingTransactionsTable';

export class DashboardContainer extends React.Component<DashboardContainerProps> {


	get selectedBranch(): ScottlandBranchDetailsView | undefined {


		return this.props.Dashboard.dashboardData === null || this.props.Dashboard.dashboardData.branches === null ? undefined :
			this.props.Dashboard.dashboardData.branches
				.find(branch => branch.id.toString() === this.props.match.params.branchId);
	}

	get entitiesToRender(): ScottlandEntityDetailsView[] {

		if (this.props.Dashboard.dashboardData === null) {
			return [];
		}

		if (this.props.Dashboard.showAllEntities === true && this.selectedBranch === undefined) {
			return this.props.Dashboard.dashboardData.entities;
		}

		return this.props.Dashboard.dashboardData.entities
			.filter(x => x.branch.key.toString() === this.props.match.params.branchId &&
				(x.active === true || this.props.Dashboard.showInactiveEntities === true));
	}

	private handleBranchSort(branchesToSort: ScottlandBranchDetailsView[], sortColumn: string, sortDirection: string): ScottlandBranchDetailsView[] {

		if (sortColumn === 'name' ||
			sortColumn === 'interest' ||
			sortColumn === 'principal') {

			return branchesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return a[sortColumn] !== b[sortColumn] ?
						a[sortColumn] === null ? -1 :
							b[sortColumn] === null ? 1 :
								(a[sortColumn]! > b[sortColumn]!) ? 1 : -1 : 0;
				}

				return a[sortColumn] !== b[sortColumn] ?
					a[sortColumn] === null ? 1 :
						b[sortColumn] === null ? -1 :
							(a[sortColumn]! > b[sortColumn]!) ? -1 : 1 : 0;

			});
		}
		else if (sortColumn === 'members') {

			return branchesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return Object.keys(a.users).length !== Object.keys(b.users).length ?
						a.users === null ? -1 :
							b.users === null ? 1 :
								(Object.keys(a.users).length > Object.keys(b.users).length) ? 1 : -1 : 0;
				}

				return Object.keys(a.users).length !== Object.keys(b.users).length ?
					a.users === null ? 1 :
						b.users === null ? -1 :
							(Object.keys(a.users).length > Object.keys(b.users).length) ? -1 : 1 : 0;

			});
		}
		else if (sortColumn === 'entities') {

			return branchesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return Object.keys(a.entities).length !== Object.keys(b.entities).length ?
						a.users === null ? -1 :
							b.users === null ? 1 :
								(Object.keys(a.entities).length > Object.keys(b.entities).length) ? 1 : -1 : 0;
				}

				return Object.keys(a.entities).length !== Object.keys(b.entities).length ?
					a.users === null ? 1 :
						b.users === null ? -1 :
							(Object.keys(a.entities).length > Object.keys(b.entities).length) ? -1 : 1 : 0;

			});
		}
		else {
			return branchesToSort;
		}

	}

	private handleEntitySort(entitiesToSort: ScottlandEntityDetailsView[], sortColumn: string, sortDirection: string): ScottlandEntityDetailsView[] {

		if (sortColumn === 'name' ||
			sortColumn === 'establishedAt' ||
			sortColumn === 'interest' ||
			sortColumn === 'principal') {

			return entitiesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return a[sortColumn] !== b[sortColumn] ?
						a[sortColumn] === null ? -1 :
							b[sortColumn] === null ? 1 :
								(a[sortColumn]! > b[sortColumn]!) ? 1 : -1 : 0;
				}

				return a[sortColumn] !== b[sortColumn] ?
					a[sortColumn] === null ? 1 :
						b[sortColumn] === null ? -1 :
							(a[sortColumn]! > b[sortColumn]!) ? -1 : 1 : 0;

			});
		}
		else if (sortColumn === 'members') {

			return entitiesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return Object.keys(a.users).length !== Object.keys(b.users).length ?
						a.users === null ? -1 :
							b.users === null ? 1 :
								(Object.keys(a.users).length > Object.keys(b.users).length) ? 1 : -1 : 0;
				}

				return Object.keys(a.users).length !== Object.keys(b.users).length ?
					a.users === null ? 1 :
						b.users === null ? -1 :
							(Object.keys(a.users).length > Object.keys(b.users).length) ? -1 : 1 : 0;

			});
		}
		else if (sortColumn === 'depositRate') {

			return entitiesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return a.ratePlan.depositRate !== b.ratePlan.depositRate ?
						a.ratePlan.depositRate === null ? -1 :
							b.ratePlan.depositRate === null ? 1 :
								(a.ratePlan.depositRate! > b.ratePlan.depositRate!) ? 1 : -1 : 0;
				}

				return a.ratePlan.depositRate !== b.ratePlan.depositRate ?
					a.ratePlan.depositRate === null ? 1 :
						b.ratePlan.depositRate === null ? -1 :
							(a.ratePlan.depositRate! > b.ratePlan.depositRate!) ? -1 : 1 : 0;

			});
		}
		else if (sortColumn === 'advanceRate') {
			return entitiesToSort.sort((a: any, b: any) => {

				if (sortDirection === 'ascending') {
					return a.ratePlan.advanceRate !== b.ratePlan.advanceRate ?
						a.ratePlan.advanceRate === null ? -1 :
							b.ratePlan.advanceRate === null ? 1 :
								(a.ratePlan.advanceRate! > b.ratePlan.advanceRate!) ? 1 : -1 : 0;
				}

				return a.ratePlan.advanceRate !== b.ratePlan.advanceRate ?
					a.ratePlan.advanceRate === null ? 1 :
						b.ratePlan.advanceRate === null ? -1 :
							(a.ratePlan.advanceRate! > b.ratePlan.advanceRate!) ? -1 : 1 : 0;

			});
		}
		else {
			return entitiesToSort;
		}

	}

	private handleSetBranchEdit(branchIdToEdit: number | null) {

		const branchToEdit = this.props.Dashboard.dashboardData!.branches?.find(branch => branch.id == branchIdToEdit);

		this.props.onFormChange(
			this.props.Dashboard.editBranchForm.id,
			{ target: { name: this.props.Dashboard.editBranchForm.fields.id.id, value: branchIdToEdit } },
			null
		)

		this.props.onFormChange(
			this.props.Dashboard.editBranchForm.id,
			{ target: { name: this.props.Dashboard.editBranchForm.fields.name.id, value: branchToEdit !== undefined ? branchToEdit.name : null } },
			null
		)

	}

	public componentWillMount() {
		this.props.onLoadDashboard();
	}

	public render(): React.ReactNode {

		if (this.props.Dashboard.error) {
			return React.createElement(DashboardErrorView, { errorMessages: this.props.Dashboard.errorMessages })
		}

		let children = null;

		if (this.props.Dashboard.loading) {
			children = React.createElement(DashboardLoadingView);
		}
		else if (this.props.Dashboard.dashboardData != null) {

			if (this.selectedBranch !== undefined) {

				if (this.props.Dashboard.tableView === true) {

					children = this.renderEntitiesTableView();
				}
				else {
					const entityCardsProps: EntityCardsViewProps = {
						entities: this.entitiesToRender
					}

					children = React.createElement(EntityCardsView, entityCardsProps);
				}
			}
			else if (this.props.Dashboard.dashboardData.branches === null) {

				if (this.props.Dashboard.tableView === true) {

					children = this.renderEntitiesTableView();
				}
				else {
					const entityCardsProps: EntityCardsViewProps = {
						entities: this.props.Dashboard.dashboardData.entities
					}

					children = React.createElement(EntityCardsView, entityCardsProps);
				}
			}
			else {

				if (this.props.Dashboard.showAllEntities === true) {

					if (this.props.Dashboard.tableView === true) {

						children = this.renderEntitiesTableView();
					}
					else {
						const entityCardsProps: EntityCardsViewProps = {
							entities: this.props.Dashboard.dashboardData.entities
						}

						children = React.createElement(EntityCardsView, entityCardsProps);
					}
				}
				else if (this.props.Dashboard.tableView === true) {

					children = this.renderBranchTableView();
				}
				else {

					const branchCardsProps: BranchCardsViewProps = {
						onSetBranchEditId: (branchIdToEdit: number) => this.handleSetBranchEdit(branchIdToEdit),
						onSetBranchDeleteId: (branchIdToDelete: number) => this.props.onFormChange(
							this.props.Dashboard.deleteBranchForm.id,
							{ target: { name: this.props.Dashboard.deleteBranchForm.fields.id.id, value: branchIdToDelete } },
							null
						),
						branches: this.props.Dashboard.dashboardData.branches
					}

					children = React.createElement(BranchCardsView, branchCardsProps);
				}

			}
		}

		let addAccountView = null;

		if (this.props.Dashboard.addAccountForm.open === true) {

			if (this.props.Dashboard.addBranchForm.open === true) {

				const addBranchViewProps: AddBranchViewProps = {
					addBranchForm: this.props.Dashboard.addBranchForm,
					onFormChange: (e: any, data: any) => this.props.onFormChange(this.props.Dashboard.addBranchForm.id, e, data),
					onFormCancel: () => this.props.onSetFormOpen(this.props.Dashboard.addBranchForm.id, false),
					onFormSubmit: this.props.onAddBranch
				}

				addAccountView = React.createElement(AddBranchView, addBranchViewProps);
			}
			else if (this.props.Dashboard.addEntityForm.open === true) {

				const addEntityViewProps: AddEntityViewProps = {
					addEntityForm: this.props.Dashboard.addEntityForm,
					onFormChange: (e: any, data: any) => this.props.onFormChange(this.props.Dashboard.addEntityForm.id, e, data),
					onFormCancel: () => this.props.onSetFormOpen(this.props.Dashboard.addEntityForm.id, false),
					onFormSubmit: this.props.onAddEntity
				}

				addAccountView = React.createElement(AddEntityView, addEntityViewProps);
			}
			else {

				const addAccountViewProps: AddAccountViewProps = {
					onAddBranch: () => this.props.onSetFormOpen(this.props.Dashboard.addBranchForm.id, true),
					onAddEntity: () => this.props.onSetFormOpen(this.props.Dashboard.addEntityForm.id, true)
				}

				addAccountView = React.createElement(AddAccountView, addAccountViewProps);
			}

		}

		const pendingTransactionsTable = this.renderPendingTransactionsTable();

		const dashboardViewProps: DashboardViewProps = {
			children: [addAccountView, children, pendingTransactionsTable],
			loading: this.props.Dashboard.loading,
			tableView: this.props.Dashboard.tableView,
			onToggleShowAllEntities: this.props.onToggleShowAllEntities,
			showAllEntities: this.props.Dashboard.showAllEntities,
			onToggleTableView: this.props.onToggleTableView,
			onToggleShowInactiveEntities: this.props.onToggleShowInactiveEntities,
			addAccountForm: this.props.Dashboard.addAccountForm,
			onToggleAddAccount: () => this.props.onSetFormOpen(this.props.Dashboard.addAccountForm.id, !this.props.Dashboard.addAccountForm.open),
			deleteBranchForm: this.props.Dashboard.deleteBranchForm,
			onDeleteBranchCancel: () => this.props.onFormChange(
				this.props.Dashboard.deleteBranchForm.id,
				{ target: { name: this.props.Dashboard.deleteBranchForm.fields.id.id, value: null } },
				null
			),
			onDeleteBranch: this.props.onDeleteBranch,

			editBranchForm: this.props.Dashboard.editBranchForm,
			onEditBranchFormChange: (e: any, data: any) => this.props.onFormChange(
				this.props.Dashboard.editBranchForm.id,
				e,
				data
			),
			onEditBranchCancel: () => this.handleSetBranchEdit(null),
			onEditBranch: this.props.onEditBranch,
			showInactiveEntities: this.props.Dashboard.showInactiveEntities,
			push: this.props.history.push,
			selectedBranch: this.selectedBranch,
			administrativeMode: this.props.Dashboard.dashboardData !== null && this.props.Dashboard.dashboardData.branches !== null,
			entityDropddownOptions: this.props.Dashboard.dashboardData === null ? undefined :
				this.props.Dashboard.dashboardData.entities.map(entity => ({
					key: entity.id,
					text: entity.name,
					value: entity.id,
					href: `/entity/${entity.id}`
				}))
		}

		return React.createElement(DashboardView, dashboardViewProps);
	}

	private renderPendingTransactionsTable(): React.ReactNode {

		const pendingTransactions = this.props.Dashboard.pendingTransactions;

		let currentPage = parseInt(this.props.Dashboard.transactionsTableSettingsForm.fields.activePage.value as string, 10);
		const pageSize = parseInt(this.props.Dashboard.transactionsTableSettingsForm.fields.itemsPerPage.value as string, 10);
		const totalPages = Math.ceil(pendingTransactions.length / pageSize);

		const startIndex = currentPage <= totalPages ?
			(currentPage - 1) * pageSize :
			(totalPages - 1) * pageSize;

		const endIndex = Math.min(startIndex + pageSize - 1, pendingTransactions.length - 1);

		const paginatedTransactions = pendingTransactions.slice(startIndex, endIndex + 1)

		const pendingTransactionTableProps: PendingTransactionTableProps = {
			loading: this.props.Dashboard.loading,
			onTableSettingFormChange: (e: any, data: any) => this.props.onFormChange(
				this.props.Dashboard.transactionsTableSettingsForm.id,
				e,
				data
			),
			tableSettingsForm: this.props.Dashboard.transactionsTableSettingsForm,
			totalPages: totalPages,
			totalTransactionsCount: this.props.Dashboard.pendingTransactions.length,
			transactions: paginatedTransactions
		}

		return React.createElement(PendingTransactionsTable, pendingTransactionTableProps);

	}

	renderBranchTableView(): React.ReactNode {

		const sortedBranches = this.handleBranchSort(
			this.props.Dashboard.dashboardData!.branches!,
			this.props.Dashboard.branchTableSettingsForm.fields.sortColumn.value as any,
			this.props.Dashboard.branchTableSettingsForm.fields.sortDirection.value as any
		);

		const branchTableProps: BranchTableViewProps = {
			onSetBranchDeleteId: (branchIdToDelete: number) => this.props.onFormChange(
				this.props.Dashboard.deleteBranchForm.id,
				{ target: { name: this.props.Dashboard.deleteBranchForm.fields.id.id, value: branchIdToDelete } },
				null
			),
			onSetBranchEditId: (branchIdToEdit: number) => this.handleSetBranchEdit(branchIdToEdit),
			tableSettingsForm: this.props.Dashboard.branchTableSettingsForm,
			onSort: (sortColumn: string) => {

				const sortDirection = sortColumn === this.props.Dashboard.branchTableSettingsForm.fields.sortColumn.value ?
					this.props.Dashboard.branchTableSettingsForm.fields.sortDirection.value === 'ascending' ? 'descending' : 'ascending' :
					'descending';

				this.props.onFormChange(
					this.props.Dashboard.branchTableSettingsForm.id,
					{ target: { name: this.props.Dashboard.branchTableSettingsForm.fields.sortColumn.id, value: sortColumn } },
					null
				);
				this.props.onFormChange(
					this.props.Dashboard.branchTableSettingsForm.id,
					{
						target:
						{
							name: this.props.Dashboard.branchTableSettingsForm.fields.sortDirection.id,
							value: sortDirection
						}
					},
					null
				);
			},
			branches: sortedBranches
		}

		return React.createElement(BranchTableView, branchTableProps);
	}

	private renderEntitiesTableView(): React.ReactNode {

		const sortedEntities = this.handleEntitySort(
			this.entitiesToRender,
			this.props.Dashboard.entitiesTableSettingsForm.fields.sortColumn.value as any,
			this.props.Dashboard.entitiesTableSettingsForm.fields.sortDirection.value as any
		);


		const entitiesTableViewProps: EntitiesTableViewProps = {
			tableSettingsForm: this.props.Dashboard.entitiesTableSettingsForm,
			onSort: (sortColumn: string) => {

				const sortDirection = sortColumn === this.props.Dashboard.entitiesTableSettingsForm.fields.sortColumn.value ?
					this.props.Dashboard.entitiesTableSettingsForm.fields.sortDirection.value === 'ascending' ? 'descending' : 'ascending' :
					'descending';

				this.props.onFormChange(
					this.props.Dashboard.entitiesTableSettingsForm.id,
					{ target: { name: this.props.Dashboard.entitiesTableSettingsForm.fields.sortColumn.id, value: sortColumn } },
					null
				);
				this.props.onFormChange(
					this.props.Dashboard.entitiesTableSettingsForm.id,
					{
						target:
						{
							name: this.props.Dashboard.entitiesTableSettingsForm.fields.sortDirection.id,
							value: sortDirection
						}
					},
					null
				);
			},
			entities: sortedEntities
		}

		return React.createElement(EntitiesTableView, entitiesTableViewProps);
	}
}

const mapStateToProps = (state: StoreState) => ({ Dashboard: state.Dashboard });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
	onLoadDashboard,
	onToggleTableView,
	onToggleShowInactiveEntities,
	onFormChange,
	onCancelFormEdits,
	onSetFormOpen,
	onToggleShowAllEntities,
	onAddEntity,
	onAddBranch,
	onEditBranch,
	onDeleteBranch
}, dispatch);

export default withRouter(<any>connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardContainer));


