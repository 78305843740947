import * as React from 'react';
import { Header, Icon, Segment, Modal, Button } from 'semantic-ui-react';
import { ActionModalContentProps } from '../interfaces/ActionModalProps';

const ApproveModal = (props: ActionModalContentProps) => (
  <Modal
    basic
    size='tiny'
    closeIcon
    onClose={props.onCloseModal}
    open={props.actionModalForm.open}
  >
    <Modal.Header>Confirm Approve Transaction</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        Are you sure you wish to approve this transaction?
      </Modal.Description>
      {props.transactionFormTouched &&
        <Modal.Description>
          <strong>Changes to the transaction will also be saved.</strong>
        </Modal.Description>
      }
    </Modal.Content>
    <Modal.Actions>
      <Button
        color='grey'
        labelPosition='right'
        icon='undo'
        content='Close'
        inverted
        size='tiny'
        onClick={props.onCloseModal}
      />
      <Button
        color='olive'
        labelPosition='right'
        icon='check circle'
        content='Approve'
        inverted
        size='tiny'
        onClick={props.onConfirm}
      />
    </Modal.Actions>
  </Modal>
)

export default ApproveModal