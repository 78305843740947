import * as React from 'react';
import { Form, Button, Dropdown, Label, Icon, Message } from 'semantic-ui-react';
import { MembersListViewProps } from './interfaces/MembersListViewProps';
import { MemberEditViewProps } from './interfaces/MemberEditViewProps';

const MemberEditView = (props: MemberEditViewProps) => (
  <Form
    size='tiny'
    error={props.memberEditForm.error}
    loading={props.memberEditForm.loading}
  >
    <Form.Group>
      <Form.Input
        id={props.memberEditForm.fields.firstName.id}
        name={props.memberEditForm.fields.firstName.id}
        value={props.memberEditForm.fields.firstName.value}
        placeholder={props.memberEditForm.fields.firstName.placeholder}
        error={props.memberEditForm.fields.firstName.error}
        onChange={props.onFormChange}
        fluid
        width='4'
      />
      <Form.Input
        id={props.memberEditForm.fields.middleName.id}
        name={props.memberEditForm.fields.middleName.id}
        value={props.memberEditForm.fields.middleName.value}
        placeholder={props.memberEditForm.fields.middleName.placeholder}
        error={props.memberEditForm.fields.middleName.error}
        onChange={props.onFormChange}
        fluid
        width='3'
      />
      <Form.Input
        id={props.memberEditForm.fields.lastName.id}
        name={props.memberEditForm.fields.lastName.id}
        value={props.memberEditForm.fields.lastName.value}
        placeholder={props.memberEditForm.fields.lastName.placeholder}
        error={props.memberEditForm.fields.lastName.error}
        onChange={props.onFormChange}
        fluid
        width='4'
      />
      <Form.Input
        id={props.memberEditForm.fields.email.id}
        name={props.memberEditForm.fields.email.id}
        value={props.memberEditForm.fields.email.value}
        placeholder={props.memberEditForm.fields.email.placeholder}
        error={props.memberEditForm.fields.email.error}
        onChange={props.onFormChange}
        fluid
        width='5'
      />
    </Form.Group>
    <Form.Group widths='equal'>
      <Form.Field width='5'>
        <Dropdown
          id={props.memberEditForm.fields.branch.id}
          name={props.memberEditForm.fields.branch.id}
          value={props.memberEditForm.fields.branch.value as any}
          placeholder={props.memberEditForm.fields.branch.placeholder}
          options={props.memberEditForm.fields.branch.options}
          error={props.memberEditForm.fields.branch.error}
          icon='university'
          selection
          search
          fluid
          inline
          labeled
          onChange={props.onFormChange}
          className='member-edit-branch-dropdown'
        />
      </Form.Field>
      <Form.Field width='11'>
        <Dropdown
          id={props.memberEditForm.fields.entities.id}
          name={props.memberEditForm.fields.entities.id}
          value={props.memberEditForm.fields.entities.value as any}
          placeholder={props.memberEditForm.fields.entities.placeholder}
          error={props.memberEditForm.fields.entities.error}
          options={props.memberEditForm.fields.entities.options}
          multiple
          selection
          search
          fluid
          labeled
          style={{ marginBottom: '1em' }}
          onChange={props.onFormChange}
        />
      </Form.Field>
    </Form.Group>
    {props.memberEditForm.fields.email.value !== props.memberEditForm.fields.email.originalValue &&
      <Label
        color='orange'
        basic
        style={{ width: '100%', marginBottom: '.5em' }}
      >
        <Icon name='exclamation circle' />
        Changing the email address of a user requires changing their email address in Okta as well!!
      </Label>
    }
    {props.memberEditForm.error &&
      <Message
        error
        size='tiny'
        icon='exclamation'
        header='Oops.'
        list={props.memberEditForm.errorMessages}
        style={{
          marginTop: '1em'
        }}
      />
    }
    {props.memberEditForm.touched === true &&
      <>
        <span style={{ marginRight: '2em' }}>
          You've edited&nbsp;<strong>{props.memberEditForm.touchedCount}</strong>&nbsp;
                  {props.memberEditForm.touchedCount > 1 ? 'fields' : 'field'}.
          </span>
        <Button
          icon='save'
          color='olive'
          type='submit'
          compact
          size='mini'
          floated='right'
          content='Save'
          labelPosition='right'
          loading={props.memberEditForm.loading}
          disabled={props.memberEditForm.loading}
          onClick={props.onSubmit}
          style={{ marginBottom: '.25em' }}
        />
      </>
    }
    <Button
      icon='undo'
      basic
      content='Cancel'
      color='grey'
      compact
      size='mini'
      floated='right'
      labelPosition='right'
      onClick={props.onCancel}
      disabled={props.memberEditForm.loading}
      style={{ marginBottom: '.25em' }}
    />
  </Form >
)

export default MemberEditView