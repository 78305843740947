import * as React from 'react';
import { Header, Icon, Segment, Image, Button } from 'semantic-ui-react';
import Config from '../../../config/Config';
import { LoginImageViewProps } from './interfaces/LoginImageViewProps';

const LoginImageView = (props :LoginImageViewProps) => (
  <Segment
    placeholder
    basic
    loading={props.loading}
  >
    <Image
      src={props.loading ? '' : `${Config.apiUrl}/login-image`}
      size='big'
      centered
      bordered
      rounded
    />
    <input
      id='loginImageUpload'
      name='loginImageUpload'
      type='file'
      style={{ display: 'none' }}
      accept='image/*'
      ref={props.loginImageUploadRef}
      onChange={props.onLoginImageUpload}
    />
    <label
      htmlFor='loginImageUpload'
      className={`ui olive icon right floated right labeled button ${props.loading && 'loading'}`}
    >
      <i aria-hidden='true' className='camera retro icon' />
      Update Login Image
    </label>
  </Segment>
)

export default LoginImageView