import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import { BranchTableViewProps } from './interfaces/BranchTableViewProps';

const BranchTableView = (props: BranchTableViewProps) => (
  <Table
    size='small'
    compact
    striped
    basic
    sortable
    selectable
  >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'name' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('name')}
        >
          Branch
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'members' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('members')}
        >
          Members
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'entities' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('entities')}
        >
          Entities
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'principal' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('principal')}
        >
          Principal
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'interest' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('interest')}
        >
          Interest
        </Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.branches.map(branch => (
        <Table.Row>
          <Table.Cell>
            <Link className='scottland-link' to={`/branches/${branch.id}`}>
              <strong>{branch.name}</strong>
            </Link>
          </Table.Cell>
          <Table.Cell>
            <Icon name='user' color='olive' size='small' />
            {Object.keys(branch.users).length}&nbsp;Members
          </Table.Cell>
          <Table.Cell>
            <Icon name='book' color='olive' size='small' />
            {Object.keys(branch.entities).length}&nbsp;Entities
          </Table.Cell>
          <Table.Cell>
            <span style={{ color: branch.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
              {branch.formattedPrincipal}
            </span>
          </Table.Cell>
          <Table.Cell>
            <span style={{ color: branch.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
              {branch.formattedInterest}
            </span>
          </Table.Cell>
          <Table.Cell textAlign='right'>
            <Icon
              name='pencil'
              color='brown'
              onClick={() => props.onSetBranchEditId(branch.id)}
              style={{
                cursor: 'pointer'
              }}
            />
            {(branch.entities == null || Object.keys(branch.entities).length <= 0) &&
              (branch.users == null || Object.keys(branch.users).length <= 0) &&
              <Icon
                name='delete'
                color='red'
                onClick={() => props.onSetBranchDeleteId(branch.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
            }
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default BranchTableView