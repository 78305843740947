

import * as React from 'react';
import { Form, Header, Icon } from 'semantic-ui-react';
import { TransactionApprovalViewProps } from './interfaces/TransactionApprovalViewProps';

const TransactionApprovalView = (props: TransactionApprovalViewProps) => (
  <>
    <Header as='h4'>
      <Icon name='check circle outline' />
      Approval
    </Header>
    <hr className='scottland-divider-small' />
    <Form.Group inline widths='equal'>
      <Form.Dropdown
        id={props.transactionForm.fields.paymentType.id}
        name={props.transactionForm.fields.paymentType.id}
        label={props.transactionForm.fields.paymentType.label}
        placeholder={props.transactionForm.fields.paymentType.placeholder}
        error={props.transactionForm.fields.paymentType.error}
        value={props.transactionForm.fields.paymentType.value as any}
        options={props.transactionForm.fields.paymentType.options}
        onChange={props.onTransactionFormChange}
        allowAdditions
        selection
        fluid
      />
      <Form.Input
        id={props.transactionForm.fields.referenceNumber.id}
        name={props.transactionForm.fields.referenceNumber.id}
        label={props.transactionForm.fields.referenceNumber.label}
        error={props.transactionForm.fields.referenceNumber.error}
        placeholder={props.transactionForm.fields.referenceNumber.placeholder}
        value={props.transactionForm.fields.referenceNumber.value as number}
        onChange={(e) => props.onTransactionFormChange(e, null)}
        fluid
      />
    </Form.Group>
  </>
)

export default TransactionApprovalView