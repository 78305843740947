import * as React from 'react';
import { Header, Icon, Segment, Grid, Label } from 'semantic-ui-react';
import { ScottlandTransactionView } from '../../../lib/interfaces/ScottlandTransactionView';
import { TransactionReadOnlyViewProps } from './interfaces/TransactionReadOnlyViewProps';
import moment from 'moment';

const TransactionReadOnlyView = (props: TransactionReadOnlyViewProps) => (
  <Grid className='transaction-read-only-view' style={{ margin: 0, padding: 0 }}>
    <Grid.Row columns='2'>
      <Grid.Column>
        <label>For Entity</label>
      </Grid.Column>
      <Grid.Column textAlign='right'>
        {props.transaction.forEntity.value}
        <Label size='small'>
          <Icon name='book' />
          Principal&nbsp;
                    {
            <span
              style={{
                color: props.entity.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
              }}>
              {props.entity.formattedPrincipal}
            </span>
          }
          &nbsp;
          Interest&nbsp;
                    {
            <span
              style={{
                color: props.entity.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
              }}>
              {props.entity.formattedInterest}
            </span>
          }
        </Label>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns='2'>
      <Grid.Column>
        <label>Effective Date</label>
      </Grid.Column>
      <Grid.Column textAlign='right'>
        {moment.unix(props.transaction.effectiveAt).format("l")}
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns='2'>
      <Grid.Column>
      <label>Type</label>
      </Grid.Column>
      <Grid.Column textAlign='right'>
      {props.transaction.type}
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns='2'>
      <Grid.Column>
      <label>Amount</label>
      </Grid.Column>
      <Grid.Column textAlign='right'>
      {props.transaction.formattedAmount}
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns='1'>
      <Grid.Column>
        <label>Description</label>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns='1'>
      <Grid.Column>
        {props.transaction.description}
      </Grid.Column>

    </Grid.Row>
  </Grid >
)

export default TransactionReadOnlyView