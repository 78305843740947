import moment from 'moment';
import * as React from 'react';
import { Button, Dropdown, Form, Grid, Header, Icon, Label, Pagination, Segment, Statistic, Table, Message, Container, Modal } from 'semantic-ui-react';
import { ScottlandRateChangeView } from '../../../lib/interfaces/ScottlandRateChangeView';
import { ScottlandRatePlanDetails } from '../../../lib/interfaces/ScottlandRatePlanDetails';
import { RatePlanDetailsViewProps } from './interfaces/RatePlanDetailsViewProps';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { FormState } from '../../../redux/interfaces/common/FormState';
import { RatePlanFormFields } from '../../../redux/interfaces/RatePlanState';
import { Link } from 'react-router-dom';

export const RatePlanDetailsView = (props: RatePlanDetailsViewProps) => (

  <Segment basic>
    {props.ratePlans.map((ratePlan: ScottlandRatePlanDetails) => (
      <>
        <Segment basic className='rate-plan-segment'>
          <Header
            as='h3'
            style={{ display: 'inline' }}
          >
            <Icon
              name={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).open as any === false ? 'angle right' : 'angle down'}
              style={{ cursor: 'pointer' }}
              onClick={() => props.onSetFormOpen(
                (props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).id,
                !(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).open
              )}
            />
            <span style={{
              position: 'relative',
              top: '-.3em'
            }}>
              {ratePlan.name}
            </span>
          </Header>
          <div className='rate-plan-current-rates'>
            <span>
              <Icon name='book' size='small' />
              {Object.keys(ratePlan.entities).length}&nbsp;Entities
            </span>
            <span>
              <Icon

                name='money bill alternate outline'
                color='olive'
              />
              {ratePlan.formattedDepositRate}&nbsp;
              <i>{moment.unix(ratePlan.depositRateEffectiveAt).format("MMMM Do, YYYY")}</i>
            </span>
            <span>
              <Icon
                name='money bill alternate outline'
                color='red'
              />
              {ratePlan.formattedAdvanceRate}&nbsp;
              <i>{moment.unix(ratePlan.advanceRateEffectiveAt).format("MMMM Do, YYYY")}</i>
            </span>
          </div>
          <hr className='scottland-divider-small' style={{ clear: 'both' }} />
          {(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).open as any === false &&
              <Container>{ratePlan.description}</Container>
          }
          {(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).open as any === true &&
            <>
              {Object.keys(ratePlan.entities).length === 0 &&
                <Container fluid>
                  <Button
                    color='red'
                    size='mini'
                    inverted
                    compact
                    labelPosition='right'
                    icon='x'
                    content='Delete'
                    floated='right'
                    onClick={() => props.onSetDeleteRatePlanId(ratePlan.id)}
                  />
                  <div style={{ clear: 'both' }} />
                </Container>
              }
              <Form
                size='tiny'
                error={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).error}
                onSubmit={() => props.onEditRatePlan(ratePlan.id)}
                style={{ paddingLeft: '3em', paddingRight: '3em' }}
              >
                <Form.Group>
                  <Form.Input
                    id={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.name.id}
                    name={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.name.id}
                    label={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.name.label}
                    error={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.name.error}
                    placeholder={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.name.placeholder}
                    value={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.name.value as any}
                    onChange={(e) => props.onFormChange(
                      (props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).id,
                      e,
                      null
                    )}
                    width='5'
                  />
                  <Form.Field width='11' style={{ marginTop: '2em' }}>
                    <Statistic.Group
                      size='mini'
                      widths='2'
                    >
                      <Statistic>
                        <Statistic.Value>
                          <Form.Input
                            icon
                            error={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialDepositRate.error}
                            transparent
                            className='rate-plan-percent-input'
                          >
                            <MaskedInput
                              id={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialDepositRate.id}
                              name={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialDepositRate.id}
                              value={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialDepositRate.value as number}
                              mask={createNumberMask({
                                prefix: '',
                                suffix: '',
                                allowDecimal: true,
                                decimalLimit: 2,
                                integerLimit: 2
                              })}
                              pipe={(conformedValue: string, config: any) => (conformedValue === '0' ? '' : conformedValue)}
                              onChange={(e) => props.onFormChange(
                                (props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).id,
                                e,
                                null
                              )}
                              guide={false}
                            />
                            <Icon name='percent' size='small' />
                          </Form.Input>
                        </Statistic.Value>
                        <Statistic.Label style={{ fontWeight: 'normal' }}>
                          <Icon
                            name='money bill alternate outline'
                            color='olive'
                          />
                          <i>Initial Deposit Rate</i>
                        </Statistic.Label>
                      </Statistic>
                      <Statistic>
                        <Statistic.Value>
                          <Form.Input
                            icon
                            error={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialAdvanceRate.error}
                            transparent
                            className='rate-plan-percent-input'
                          >
                            <MaskedInput
                              id={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialAdvanceRate.id}
                              name={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialAdvanceRate.id}
                              value={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.initialAdvanceRate.value as number}
                              mask={createNumberMask({
                                prefix: '',
                                suffix: '',
                                allowDecimal: true,
                                decimalLimit: 2,
                                integerLimit: 2
                              })}
                              pipe={(conformedValue: string, config: any) => (conformedValue === '0' ? '' : conformedValue)}
                              onChange={(e) => props.onFormChange(
                                (props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).id,
                                e,
                                null
                              )}
                              guide={false}
                            />
                            <Icon name='percent' size='small' />
                          </Form.Input>
                        </Statistic.Value>
                        <Statistic.Label style={{ fontWeight: 'normal' }}>
                          <Icon
                            name='money bill alternate outline'
                            color='red'
                          />
                          <i>Initial Advance Rate</i>
                        </Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </Form.Field>
                </Form.Group>
                <Form.TextArea
                  id={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.description.id}
                  name={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.description.id}
                  label={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.description.label}
                  error={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.description.error}
                  placeholder={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.description.placeholder}
                  value={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).fields.description.value as any}
                  onChange={(e) => props.onFormChange(
                    (props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).id,
                    e,
                    null
                  )}
                />

                <Message
                  error
                  icon='exclamation'
                  header='Oops'
                  list={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).errorMessages}
                />
                {(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).touched === true &&
                  <Container
                    fluid
                    size='small'
                    style={{
                      margin: '0 0 2em',
                    }}
                  >
                    <div>
                      You've edited&nbsp;<strong>{(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).touchedCount}</strong>&nbsp;
                  {(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).touchedCount > 1 ? 'fields' : 'field'}.
                </div>
                    <Button
                      icon='save'
                      labelPosition='right'
                      color='olive'
                      type='submit'
                      size='mini'
                      floated='right'
                      content='Save'
                      compact
                      loading={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).loading}
                      disabled={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).loading}
                    />
                    <Button
                      icon='undo'
                      color='grey'
                      basic
                      content='Cancel'
                      labelPosition='right'
                      size='mini'
                      type='button'
                      floated='right'
                      compact
                      onClick={() => props.onCancelFormEdits((props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).id)}
                      disabled={(props[(ratePlan.id.toString() + 'edit' as any)] as unknown as FormState<RatePlanFormFields>).loading}
                    />
                    <div style={{ clear: 'both' }} />
                  </Container>
                }
              </Form>
              {ratePlan.entities !== null && Object.keys(ratePlan.entities).length > 0 &&
                <Segment basic>
                  <Header as='h5'>Entities</Header>
                  <Label.Group size='tiny'>
                    {Object.keys(ratePlan.entities).map((entityId) => (
                      <Label
                        icon='book'
                        as={Link}
                        to={`/entity/${entityId}`}
                        content={`${ratePlan.entities[entityId as any]}`}
                      />
                    ))}
                  </Label.Group>
                </Segment>
              }
            </>
          }

        </Segment>
        <Segment
          basic
          style={{
            paddingLeft: '3em',
            marginTop: 0
          }}
        >
          <Header as='h4' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
            <Icon
              name={props[ratePlan.id].tableSettingsForm.fields.collapsed.value as any === true ? 'angle right' : 'angle down'}
              style={{ cursor: 'pointer' }}
              onClick={() => props[ratePlan.id].onTableSettingFormChange(
                {
                  target: {
                    name: props[ratePlan.id].tableSettingsForm.fields.collapsed.id,
                    value: !(props[ratePlan.id].tableSettingsForm.fields.collapsed.value as any)
                  }
                }, null
              )}
            />
            <span style={{
              position: 'relative',
              top: '-.3em'
            }}>
              Rate Changes
            <Label
                color='olive'
                circular
                size='tiny'
              >
                {`${props[ratePlan.id].filteredItemsCount}/${props[ratePlan.id].totalItems}`}
              </Label>
            </span>
          </Header>
          {(props.addRateChangeRatePlanId === null || ratePlan.id !== props.addRateChangeRatePlanId) &&
            <Icon
              name='plus'
              color='olive'
              style={{
                float: 'right',
                position: 'relative',
                top: '.5em',
                cursor: 'pointer'
              }}
              onClick={() => props.onSetAddRateChangeRatePlanId(ratePlan.id)}
            />
          }
          {(ratePlan.id === props.addRateChangeRatePlanId || props[ratePlan.id].tableSettingsForm.fields.collapsed.value as any === false) &&
            <hr className='scottland-divider-small' style={{ clear: 'both', marginTop: '.25em' }} />
          }
          {ratePlan.id === props.addRateChangeRatePlanId &&
            <Form
              size='tiny'
              error={props.addRateChangeForm.error}
              onSubmit={props.onAddRateChange}
              inline
              className='rate-change-form'
            >
              <Form.Group widths='equal'>
                <Form.Input
                  id={props.addRateChangeForm.fields.effectiveAt.id}
                  name={props.addRateChangeForm.fields.effectiveAt.id}
                  label={props.addRateChangeForm.fields.effectiveAt.label}
                  error={props.addRateChangeForm.fields.effectiveAt.error}
                  placeholder={props.addRateChangeForm.fields.effectiveAt.placeholder}
                  value={props.addRateChangeForm.fields.effectiveAt.value as any}
                  onChange={(e) => props.onFormChange(props.addRateChangeForm.id, e, null)}
                  fluid
                  type='date'
                />
                <Form.Input
                  error={props.addRateChangeForm.fields.rate.error}
                  label={props.addRateChangeForm.fields.isDepositRate.value as any === true ? 'Deposit Rate' : 'Advance Rate'}
                  labelPosition='right'
                  fluid
                >
                  <Label
                    icon='money bill alternate outline'
                    basic
                    color={props.addRateChangeForm.fields.isDepositRate.value as any === true ? 'olive' : 'red'}
                    onClick={() => props.onFormChange(
                      props.addRateChangeForm.id,
                      {
                        target: {
                          name: props.addRateChangeForm.fields.isDepositRate.id,
                          value: !(props.addRateChangeForm.fields.isDepositRate.value as any)
                        }
                      }, null
                    )}
                    style={{ cursor: 'pointer' }}
                  />
                  <MaskedInput
                    id={props.addRateChangeForm.fields.rate.id}
                    name={props.addRateChangeForm.fields.rate.id}
                    value={props.addRateChangeForm.fields.rate.value as number}
                    mask={createNumberMask({
                      prefix: '',
                      suffix: '',
                      allowDecimal: true,
                      decimalLimit: 2,
                      integerLimit: 2
                    })}
                    pipe={(conformedValue: string, config: any) => (conformedValue === '0' ? '' : conformedValue)}
                    onChange={(e) => props.onFormChange(props.addRateChangeForm.id, e, null)}
                    guide={false}
                    style={{ textAlign: 'right' }}
                  />
                  <Label
                    icon='percent'
                    basic={props.addRateChangeForm.fields.rate.error ? undefined : true}
                    color={props.addRateChangeForm.fields.rate.error ? 'red' : undefined}
                  />
                </Form.Input>
              </Form.Group>
              <Message
                error
                icon='exclamation'
                header='Oops'
                list={props.addRateChangeForm.errorMessages}
              />
              <Button
                size='mini'
                color='olive'
                icon='plus circle'
                labelPosition='right'
                content='Add Rate Change'
                floated='right'
                type='submit'
                loading={props.addRateChangeForm.loading}
                disabled={props.addRateChangeForm.loading}
                onClick={props.onAddRateChange}
              />
              <Button
                size='mini'
                icon='undo'
                labelPosition='right'
                content='Close'
                floated='right'
                onClick={(e: any) => props.onSetAddRateChangeRatePlanId(null)}
                disabled={props.addRateChangeForm.loading}
              />
              <div style={{ clear: 'both' }} />
            </Form>
          }

          {props[ratePlan.id].tableSettingsForm.fields.collapsed.value as any === false &&
            <>
              <Form style={{ paddingTop: '.5em' }}>
                <Form.Group inline widths='equal'>
                  <Form.Field>
                    <Dropdown
                      id={props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.id}
                      name={props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.id}
                      label={props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.label}
                      placeholder={props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.placeholder}
                      value={props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.value as any}
                      options={props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.options}
                      onChange={props[ratePlan.id].onTableSettingFormChange}
                      inline
                    />
                    <label>&nbsp;{props[ratePlan.id].tableSettingsForm.fields.itemsPerPage.label}</label>
                  </Form.Field>
                  <Form.Field
                    style={{
                      textAlign: 'right',
                      padding: 0
                    }}
                  >
                    <Button
                      size='tiny'
                      icon='money bill alternate outline'
                      color='olive'
                      basic={props[ratePlan.id].tableSettingsForm.fields.showDepositRates.value as any == false}
                      onClick={(e, data) => props[ratePlan.id].onTableSettingFormChange(
                        {
                          target:
                          {
                            name: props[ratePlan.id].tableSettingsForm.fields.showDepositRates.id,
                            value: !props[ratePlan.id].tableSettingsForm.fields.showDepositRates.value
                          }
                        }, null)
                      }
                      style={{
                        position: 'relative',
                        left: '.25em'
                      }}
                    />
                    <Button
                      size='tiny'
                      icon='money bill alternate outline'
                      color='red'
                      basic={props[ratePlan.id].tableSettingsForm.fields.showAdvanceRates.value as any == false}
                      onClick={(e, data) => props[ratePlan.id].onTableSettingFormChange(
                        {
                          target:
                          {
                            name: props[ratePlan.id].tableSettingsForm.fields.showAdvanceRates.id,
                            value: !props[ratePlan.id].tableSettingsForm.fields.showAdvanceRates.value
                          }
                        }, null)
                      }
                      style={{
                        position: 'relative',
                        left: '-.25em'
                      }}
                    />
                    {props[ratePlan.id].totalPages > 1 &&
                      <Pagination
                        activePage={(props[ratePlan.id].tableSettingsForm.fields.activePage.value as any) <= props[ratePlan.id].totalPages ?
                          props[ratePlan.id].tableSettingsForm.fields.activePage.value as string :
                          props[ratePlan.id].totalPages}
                        boundaryRange={2}
                        onPageChange={(e, { activePage }) => props[ratePlan.id].onTableSettingFormChange(
                          { target: { name: props[ratePlan.id].tableSettingsForm.fields.activePage.id, value: activePage } }, null)
                        }
                        size='mini'
                        siblingRange={1}
                        totalPages={props[ratePlan.id].totalPages}
                      />
                    }
                  </Form.Field>
                </Form.Group>
              </Form>
              {props[ratePlan.id].filteredPaginatedRateChanges.length <= 0 &&
                <>
                  There are no rate changes to show.
                </>
              }
              <Table
                size='small'
                compact
                basic='very'
              >
                <Table.Body>
                  {props[ratePlan.id].filteredPaginatedRateChanges.map((rateChange: ScottlandRateChangeView) =>
                    <Table.Row>
                      <Table.Cell width='10'>{moment.unix(rateChange.effectiveAt).format("MMMM Do, YYYY")}</Table.Cell>
                      <Table.Cell width='2' textAlign='right'>
                        {rateChange.isDepositRate ?
                          (<>Deposit&nbsp;<Icon name='money bill alternate outline' color='olive' /></>) :
                          (<>Advance&nbsp;<Icon name='money bill alternate outline' color='red' /></>)}
                      </Table.Cell>
                      <Table.Cell width='2' textAlign='right'>
                        {rateChange.formattedRate}&nbsp;
                        <Icon
                          name='delete'
                          color='red'
                          style={{ cursor: 'pointer' }}
                          onClick={() => props.onSetDeleteRateChangeId(rateChange.id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </>
          }
        </Segment>
      </>
    ))}
    <Modal
      basic
      size='tiny'
      closeIcon
      onClose={() => props.onSetDeleteRateChangeId(null)}
      open={props.deleteRateChangeId !== null}
    >
      <Modal.Header className='confirm-modal-header-caution'>
        Delete Rate Change
          </Modal.Header>
      <Modal.Content>
        Are you sure you want to delete this rate change?
						{props.deleteRateChangeForm.error &&

          <Message
            error
            size='tiny'
            icon='exclamation'
            header='Oops. There was an error deleting the rate change.'
            list={props.deleteRateChangeForm.errorMessages}
            style={{
              marginTop: '1em'
            }}
          />
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='right'
          icon='undo'
          content='Close'
          inverted
          size='tiny'
          onClick={() => props.onSetDeleteRateChangeId(null)}
          disabled={props.deleteRateChangeForm.loading}
        />
        <Button
          color='red'
          labelPosition='right'
          icon='x'
          content='Delete'
          inverted
          size='tiny'
          onClick={() => props.onDeleteRateChange()}
          loading={props.deleteRateChangeForm.loading}
          disabled={props.deleteRateChangeForm.loading}
        />
      </Modal.Actions>
    </Modal>
    <Modal
      basic
      size='tiny'
      closeIcon
      onClose={() => props.onSetDeleteRatePlanId(null)}
      open={props.deleteRatePlanId !== null}
    >
      <Modal.Header className='confirm-modal-header-caution'>
        Delete Rate Plan
      </Modal.Header>
      <Modal.Content>
        Are you sure you want to delete this rate plan?
        {props.deleteRatePlanForm.error &&
          <Message
            error
            size='tiny'
            icon='exclamation'
            header='Oops. There was an error deleting the rate plan.'
            list={props.deleteRatePlanForm.errorMessages}
            style={{
              marginTop: '1em'
            }}
          />
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='right'
          icon='undo'
          content='Close'
          inverted
          size='tiny'
          onClick={() => props.onSetDeleteRatePlanId(null)}
          disabled={props.deleteRatePlanForm.loading}
        />
        <Button
          color='red'
          labelPosition='right'
          icon='x'
          content='Delete'
          inverted
          size='tiny'
          onClick={() => props.onDeleteRatePlan()}
          loading={props.deleteRatePlanForm.loading}
          disabled={props.deleteRatePlanForm.loading}
        />
      </Modal.Actions>
    </Modal>
  </Segment>
)

export default RatePlanDetailsView