import moment from 'moment';
import * as React from 'react';
import { Button, Comment, Icon, Message, Modal } from 'semantic-ui-react';
import { TransactionFilesListViewProps } from './interfaces/TransactionFilesListViewProps';


const mimeTypesToIcon = [
  { mimeTypes: ['text/plain'], icon: 'file outline' },
  { mimeTypes: ['application/pdf', 'application/x-pdf'], icon: 'file pdf outline' },
  {
    mimeTypes: ['application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12'], icon: 'file word outline'
  },
  {
    mimeTypes: ['application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12'], icon: 'file excel outline'
  },
  {
    mimeTypes: ['application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'], icon: 'file powerpoint outline'
  },
  {
    mimeTypes: ['image/jpeg',
      'image/gif',
      'image/png'], icon: 'file image outline'
  },
  {
    mimeTypes: ['application/zip',
      'application/x-zip-compressed',
      'application/x-7z-compressed',
      'application/x-tar',
      'application/x-rar-compressed'], icon: 'file archive outline'
  }
]

const defaultFileIcon = 'file outline'

function formatBytes(bytes: number, decimals?: number) {
  if (bytes == 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


const TransactionFilesListView = (props: TransactionFilesListViewProps) => (
  <>
    {props.files.length > 0 &&
      <Comment.Group>
        <form
          hidden={true}
          method='post' 
          target='_blank'
          ref={props.fileDownloadRef}
        >
          <input hidden={true} name='clientId' />
          <input hidden={true} name='token' />
        </form>
        {props.files.map(file => (
          <Comment>
            <div className='avatar'>
              <Icon
                name={
                  mimeTypesToIcon.find(fileType => fileType.mimeTypes.includes(file.type)) !== undefined ?
                    mimeTypesToIcon.find(fileType => fileType.mimeTypes.includes(file.type))!.icon as any :
                    defaultFileIcon
                }
                size='big'
              />
            </div>
            <Comment.Content>
              <Comment.Author
                as={file.url !== undefined ? 'a' : undefined}
                href={file.url !== undefined ? file.url : undefined}
                onClick={file.url !== undefined ? (e :any) => { e.preventDefault(); props.onDownloadFile(file.id) } : undefined}
              >
                {file.name}
              </Comment.Author>
              <Comment.Metadata>
                {`${moment.unix(file.createdAt).format('MM/DD/YYYY hh:mm a')} (${moment.duration(moment.unix(file.createdAt).diff(new Date())).humanize(true)}) `}
              </Comment.Metadata>
              <div>
                {props.administrativeMode === true &&
                  <Comment.Metadata>
                    <div>
                      <Icon
                        name={file.isAdministrative ? 'eye slash' : 'eye'}
                      />
                      {file.isAdministrative ? 'Administrative file' : 'Visible to members'}
                    </div>
                  </Comment.Metadata>
                }
                <Comment.Metadata>
                  <i>Uploaded by</i>{file.createdBy}
                </Comment.Metadata>
                <Comment.Metadata>
                  <i>Size</i>{formatBytes(file.size)}
                </Comment.Metadata>
              </div>
              <Comment.Actions>
                <a onClick={() => props.onSetDeleteFileId(file.id)}><Icon name='delete' />Delete</a>
              </Comment.Actions>
            </Comment.Content>
          </Comment>
        ))}
      </Comment.Group>
    }
    <Modal
      basic
      size='tiny'
      closeIcon
      onClose={() => props.onSetDeleteFileId(null)}
      open={props.deleteFileId !== null}
    >
      <Modal.Header>
        Delete File
      </Modal.Header>
      <Modal.Content>
        Are you sure you want to delete this file?
        {props.deleteFileForm.error &&
          <Message
            error
            icon='exclamation'
            header='Oops. There was an error deleting the file.'
            list={props.deleteFileForm.errorMessages}
            style={{
              marginTop: '1em'
            }}
          />
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='right'
          icon='undo'
          content='Close'
          inverted
          size='tiny'
          onClick={() => props.onSetDeleteFileId(null)}
          disabled={props.deleteFileForm.loading}
        />
        <Button
          color='red'
          labelPosition='right'
          icon='x'
          content='Delete'
          inverted
          size='tiny'
          onClick={() => props.onDeleteFile()}
          loading={props.deleteFileForm.loading}
          disabled={props.deleteFileForm.loading}
        />
      </Modal.Actions>
    </Modal>
  </>
)

export default TransactionFilesListView 