import * as React from 'react';
import { Header, Segment, Icon, Form, Message, Button, List, Container } from 'semantic-ui-react';
import { TransactionFilesViewProps } from './interfaces/TransactionFilesViewProps';
import TransactionFilesEmptyView from './TransactionFilesEmptyView';

const TransactionFilesView = (props: TransactionFilesViewProps) => (
  <>
    <Header as='h4'>
      <Icon name='file alternate outline' />
      Files
    </Header>

    <hr className='scottland-divider-small' />
    <Container fluid>
      <input
        id='transactionFileUpload'
        name='transactionFileUpload'
        type='file'
        multiple
        style={{ display: 'none' }}
        ref={props.fileUploadRef}
        onChange={props.onFileUpload}
      />
      <label
        htmlFor='transactionFileUpload'
        className={`ui olive mini icon right floated right labeled button ${props.addFileForm.loading && 'loading'}`}
      >
        <i aria-hidden='true' className='plus circle icon' />
        Add file
      </label>
      <span
        style={{
          cursor: 'pointer',
          position: 'relative',
          top: '.3em'
        }}
        onClick={() => props.onFormChange(
          {
            target: {
              name: props.addFileForm.fields.isAdministrative.id,
              value: !(props.addFileForm.fields.isAdministrative.value as any)
            }
          }, null
        )}
      >
        {props.administrativeMode === true &&
          <>
            <Icon
              size='large'
              name={props.addFileForm.fields.isAdministrative.value as any === true ?
                'check circle outline' : 'circle outline'
              }
              color={props.addFileForm.fields.isAdministrative.value as any === true ?
                'olive' : 'grey'
              }
            />
            {props.addFileForm.fields.isAdministrative.label}
          </>
        }
      </span>
      {props.addFileForm.error === true &&
        <Message 
          error
          visible 
          icon='exclamation'
          header='One or more of your files failed to upload.'
          list={props.addFileForm.errorMessages}
          onDismiss={props.onClearFormError}
        />
      }
    </Container>

    {props.filesListView}
  </>
)

export default TransactionFilesView