import * as React from 'react';
import { Header, Icon, Segment, Form, Button, Grid, Message } from 'semantic-ui-react';
import { AddBranchViewProps } from './interfaces/AddBranchViewProps';

const AddBranchView = (props: AddBranchViewProps) => (
  <Form
    size='tiny'
    className='entity-edit-form'
    error={props.addBranchForm.error}
    loading={props.addBranchForm.loading}
  >
    <Grid
      columns='1'
      style={{ marginBottom: '.5em' }}
    >
      <Grid.Column>
        <Form.Field
          inline
          error={props.addBranchForm.fields.name.error}
          fluid
        >
          <label>{props.addBranchForm.fields.name.label}</label>
          <input
            id={props.addBranchForm.fields.name.id}
            name={props.addBranchForm.fields.name.id}
            value={props.addBranchForm.fields.name.value as any}
            placeholder={props.addBranchForm.fields.name.placeholder}
            onChange={(e) => props.onFormChange(e, null)}
            style={{ width: '80%', float: 'right' }}
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
    <Message
      error
      icon='exclamation'
      header='Oops.'
      list={props.addBranchForm.errorMessages}
    />
    <Button
      size='mini'
      color='olive'
      icon='plus circle'
      labelPosition='right'
      content='Add Branch'
      floated='right'
      type='submit'
      loading={props.addBranchForm.loading}
      disabled={props.addBranchForm.loading}
      onClick={props.onFormSubmit}
    />
    <Button
      size='mini'
      icon='undo'
      labelPosition='right'
      content='Close'
      floated='right'
      onClick={props.onFormCancel}
      disabled={props.addBranchForm.loading}
    />
    <div style={{ clear: 'both' }} />
  </Form>
)

export default AddBranchView