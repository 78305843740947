

import { Reducer } from 'redux';
import { LoginImageAction } from './creators/interfaces/LoginImageCreators';
import { LoginImageActionTypes } from './creators/LoginImageCreators';
import { LoginImageState } from './interfaces/LoginImageState';
import * as LoginImageActions from './creators/interfaces/LoginImageCreators';

const initialState: LoginImageState = {
  loading :false,
  error :false,
  errorMessages :[]
};

const handleResetToInitialState = (state: LoginImageState, action: LoginImageAction) :LoginImageState => {
	return {
		...initialState,
	};
}

const handleSetLoading = (state: LoginImageState, action: LoginImageActions.SetLoadingAction): LoginImageState => {
  return {
    ...state,
    loading: action.loading
  };
}

const handleSetError = (state: LoginImageState, action: LoginImageActions.SetErrorAction): LoginImageState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: LoginImageState, action: LoginImageActions.SetErrorMessagesAction): LoginImageState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handlers: { [x: string]: (state: LoginImageState, action: LoginImageAction) => LoginImageState } =
{
  [LoginImageActionTypes.ResetToInitialState]: handleResetToInitialState,
  [LoginImageActionTypes.SetLoading]: handleSetLoading,
  [LoginImageActionTypes.SetError]: handleSetError,
  [LoginImageActionTypes.SetErrorMessages]: handleSetErrorMessages
} as any

const LoginImageReducer: Reducer<LoginImageState, LoginImageAction> = (state: LoginImageState | undefined, action: LoginImageAction): LoginImageState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default LoginImageReducer