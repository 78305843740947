
import * as React from 'react';
import { Route, Switch } from 'react-router';
import DashboardContainer from '../../Dashboard/DashboardContainer';
import EntityContainer from '../../Entity/EntityContainer';
import LoginContainer from '../../Login/LoginContainer';
import { ImplicitCallback, SecureRoute } from '@okta/okta-react';
import NotFoundView from './NotFoundVIew';
import RatePlanContainer from '../../RatePlans/RatePlanContainer';
import TransactionContainer from '../../Transaction/TransactionContainer';


const MemberAppView: React.StatelessComponent =
  (props) =>
    (
      <Switch>
        <Route path='/' exact={true} component={LoginContainer} />
        <Route path='/implicit/callback' exact={true} component={ImplicitCallback} />
        <SecureRoute path='/dashboard' exact={true} component={DashboardContainer} />
        <SecureRoute path='/entity/:entityId/' exact={true} component={EntityContainer} />
        <SecureRoute path='/add-transaction/' exact={true} key='add-transaction' component={TransactionContainer} />
        <SecureRoute path='/transaction/:transactionId' exact={true} component={TransactionContainer} />
        <Route component={NotFoundView} />
      </Switch>
    )

export default MemberAppView
