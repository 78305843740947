
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ScottlandTransactionLedgerEntry } from '../../lib/interfaces/ScottlandTransactionLedgerEntry';
import StoreState from '../../redux/interfaces/StoreState';
import EntityErrorView from '../Transaction/views/TransactionErrorView';
import EntityLoadingView from '../Transaction/views/TransactionLoadingView';
import { EntityContainerProps } from './interfaces/EntityContainerProps';
import { onCancelFormEdits, onDeleteEntity, onEditEntity, onFormChange, onLoadEntity, onSetFormOpen, onDownloadLedgerFile } from './thunks/EntityThunks';
import EntityDeletedView from './views/EntityDeletedView';
import EntityDetailsView from './views/EntityDetailsView';
import EntityEditView from './views/EntityEditView';
import EntityTransactionTable from './views/EntityTransactionTableView';
import EntityView from './views/EntityView';
import { EntityDetailsViewProps } from './views/interfaces/EntityDetailsViewProps';
import { EntityEditViewProps } from './views/interfaces/EntityEditViewProps';
import { EntityTransactionTableViewProps } from './views/interfaces/EntityTransactionTableViewProps';
import { EntityViewProps } from './views/interfaces/EntityViewProps';


export class EntityContainer extends React.Component<EntityContainerProps> {

	private ledgerFileDownloadRef: React.RefObject<HTMLFormElement>;

	constructor(props: EntityContainerProps) {
		super(props);
		this.ledgerFileDownloadRef = React.createRef();
	}

	get urlEntityId(): string {

		return this.props.match.params.entityId
	}

	public componentWillMount() {

		this.props.onLoadEntity(this.urlEntityId);
	}

	public render(): React.ReactNode {

		if (this.props.Entity.entityDeleted === true) {
			return React.createElement(EntityDeletedView);
		}

		if (this.props.Entity.loading) {
			return React.createElement(EntityLoadingView);
		}

		if (this.props.Entity.error) {
			return React.createElement(EntityErrorView, { errorMessages: this.props.Entity.errorMessages })
		}

		if (this.props.Entity.entityData != null) {

			let children: React.ReactNode[] = [];

			if (this.props.Entity.entityEditForm.open === true) {

				const editEntityViewProps: EntityEditViewProps = {
					entity: this.props.Entity.entityData.entity,
					editEntityForm: this.props.Entity.entityEditForm,
					onCancel: () => this.props.onSetFormOpen(this.props.Entity.entityEditForm.id, false),
					onSumbit: this.props.onEditEntity,
					onFormChange: (e: any, data: any) => this.props.onFormChange(this.props.Entity.entityEditForm.id, e, data),
					deleteEntityForm: this.props.Entity.deleteEntityForm,
					onDeleteFormChange: (e: any, data: any) => this.props.onFormChange(this.props.Entity.deleteEntityForm.id, e, data),
					onDeleteConfirm: () => this.props.onSetFormOpen(this.props.Entity.deleteEntityForm.id, true),
					onDeleteCancel: () => this.props.onSetFormOpen(this.props.Entity.deleteEntityForm.id, false),
					onDelete: this.props.onDeleteEntity
				}


				children.push(React.createElement(EntityEditView, editEntityViewProps));
			}
			else {
				const entityDetailsViewProps: EntityDetailsViewProps = {
					entity: this.props.Entity.entityData.entity,
					editEntityForm: this.props.Entity.entityEditForm,
					onSetFormOpen: (open: boolean) => this.props.onSetFormOpen(this.props.Entity.entityEditForm.id, open),
					allowEdit: this.props.App.authenticationToken !== null && this.props.App.authenticationToken.claims.is_administrator == true
				}

				children.push(React.createElement(EntityDetailsView, entityDetailsViewProps));
			}


			children.push(this.renderTransactionTable());

			const entityViewProps: EntityViewProps = {
				children,
				loading: this.props.Entity.error,
				entity: this.props.Entity.entityData.entity,
				administrativeMode: this.props.App.authenticationToken !== null && this.props.App.authenticationToken.claims.is_administrator == true
			}

			return React.createElement(EntityView, entityViewProps);
		}

		return null;

	}

	private renderTransactionTable(): React.ReactNode {

		if (this.props.Entity.entityData == null) {
			return null;
		}

		const filteredTransactions: ScottlandTransactionLedgerEntry[] = this.filterTransactions(
			this.props.Entity.entityData.transactions
		);

		let currentPage = parseInt(this.props.Entity.transactionsTableSettingsForm.fields.activePage.value as string, 10);
		const pageSize = parseInt(this.props.Entity.transactionsTableSettingsForm.fields.itemsPerPage.value as string, 10);
		const totalPages = Math.ceil(filteredTransactions.length / pageSize);

		const startIndex = currentPage <= totalPages ?
			(currentPage - 1) * pageSize :
			(totalPages - 1) * pageSize;

		const endIndex = Math.min(startIndex + pageSize - 1, filteredTransactions.length - 1);

		const paginatedTransactions = filteredTransactions.slice(startIndex, endIndex + 1)

		const transactionTableProps: EntityTransactionTableViewProps = {
			entity: this.props.Entity.entityData.entity,
			onTableSettingFormChange: (e: any, data: any) => this.props.onFormChange(
				this.props.Entity.transactionsTableSettingsForm.id,
				e,
				data
			),
			tableSettingsForm: this.props.Entity.transactionsTableSettingsForm,
			totalPages: totalPages,
			totalTransactionsCount: this.props.Entity.entityData.transactions.length,
			filteredTransactionsCount: filteredTransactions.length,
			typeFilterOptions: this.props.Entity.entityData.transactions.map(item => item.type)
				.filter((value, index, self) => self.indexOf(value) === index)
				.map(type => ({
					key: type,
					value: type,
					text: type
				})),
			statusFilterOptions: this.props.Entity.entityData.transactions.map(item => item.status)
				.filter((value, index, self) => self.indexOf(value) === index)
				.map(type => ({
					key: type,
					value: type,
					text: type
				})),
			transactions: paginatedTransactions,
			ledgerFileDownloadRef: this.ledgerFileDownloadRef,
			onDownloadLedgerFile: () => this.props.onDownloadLedgerFile(this.ledgerFileDownloadRef)
		}

		return React.createElement(EntityTransactionTable, transactionTableProps)
	}

	private filterTransactions(transactions: ScottlandTransactionLedgerEntry[]): ScottlandTransactionLedgerEntry[] {

		if (!transactions || transactions.length <= 0) {
			return [];
		}
		const startDate = moment(this.props.Entity.transactionsTableSettingsForm.fields.startDate.value as string);
		const endDate = moment(this.props.Entity.transactionsTableSettingsForm.fields.endDate.value as string);

		return transactions.filter(
			(transaction) => {

				let includeTransaction = true;

				if (this.props.Entity.transactionsTableSettingsForm.fields.showEphermalTransactions.value as any === false &&
					transaction.isEphemeral === true && transaction.isBalanceForward === false && transaction.isAnnualCompound === false) {
					includeTransaction = false;
				}

				if (this.props.Entity.transactionsTableSettingsForm.fields.typeFilter.value != '') {
					if (transaction.type !== this.props.Entity.transactionsTableSettingsForm.fields.typeFilter.value) {
						includeTransaction = false;
					}
				}

				if (this.props.Entity.transactionsTableSettingsForm.fields.statusFilter.value != '') {
					if (transaction.status !== this.props.Entity.transactionsTableSettingsForm.fields.statusFilter.value) {
						includeTransaction = false;
					}
				}

				if (startDate &&
					startDate.isValid() &&
					moment.unix(transaction.effectiveAt).isSameOrAfter(startDate, 'date') === false) {
					includeTransaction = false;
				}
				if (endDate &&
					endDate.isValid() &&
					moment.unix(transaction.effectiveAt).isSameOrBefore(endDate, 'date') === false) {
					includeTransaction = false;
				}

				if (this.props.Entity.transactionsTableSettingsForm.fields.showYearEndOnly.value === true as any) {

					if (moment.unix(transaction.effectiveAt).dayOfYear() !== 1 &&
						moment.unix(transaction.effectiveAt).dayOfYear() !== 365 &&
						moment.unix(transaction.effectiveAt).dayOfYear() !== 366) {
						includeTransaction = false;
					}

				}

				if (includeTransaction) {
					return includeTransaction;
				}

				return null;
			});
	}
}

const mapStateToProps = (state: StoreState) => ({ App: state.App, Entity: state.Entity });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
	onLoadEntity,
	onFormChange,
	onCancelFormEdits,
	onSetFormOpen,
	onEditEntity,
	onDeleteEntity,
	onDownloadLedgerFile
}, dispatch);

export default withRouter(<any>connect(
	mapStateToProps,
	mapDispatchToProps
)(EntityContainer));


