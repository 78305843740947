
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ScottlandEntityDetailsView } from '../../lib/interfaces/ScottlandEntityView';
import { ScottlandTransactionActions } from '../../lib/interfaces/ScottlandTransactionView';
import StoreState from '../../redux/interfaces/StoreState';
import { TransactionContainerProps } from './interfaces/TransactionContainerProps';
import { onAddNote, onAddTransaction, onApproveTransaction, onCancelFormEdits, onClearFormError, onConfirmAction, onConfirmApprove, onDeleteFile, onDeleteNote, onDeleteTransaction, onEditNote, onEditTransaction, onFileDownload, onFileUpload, onFormChange, onInquiryTransaction, onLoad, onRescindTransaction, onSetDeleteFileId, onSetDeleteNoteId, onSetEditNoteId, onSetFormOpen, onToggleTransactionLogOpen, onVoidTransaction } from './thunks/TransactionThunks';
import { ActionModalContentProps as ActionModalProps } from './views/interfaces/ActionModalProps';
import { TransactionAddButtonsProps as TransactionActionButtonsProps } from './views/interfaces/TransactionAddButtonsProps';
import { TransactionApprovalViewProps } from './views/interfaces/TransactionApprovalViewProps';
import { TransactionDetailsViewProps } from './views/interfaces/TransactionDetailsViewProps';
import { TransactionFilesListViewProps } from './views/interfaces/TransactionFilesListViewProps';
import { TransactionFilesViewProps } from './views/interfaces/TransactionFilesViewProps';
import { TransactionLayoutViewProps } from './views/interfaces/TransactionLayoutViewProps';
import { TransactionLogViewProps } from './views/interfaces/TransactionLogViewProps';
import { TransactionNotesLayoutViewProps } from './views/interfaces/TransactionNotesLayoutViewProps';
import { TransactionNotesListViewProps } from './views/interfaces/TransactionNotesListViewProps';
import { TransactionReadOnlyViewProps } from './views/interfaces/TransactionReadOnlyViewProps';
import TransactionActionButtons from './views/TransactionActionButtons';
import ApproveModalContent from './views/TransactionActionsModals/ApproveModal';
import DeleteModal from './views/TransactionActionsModals/DeleteModal';
import InquiryModal from './views/TransactionActionsModals/InquiryModal';
import RescindModal from './views/TransactionActionsModals/RescindModal';
import VoidModal from './views/TransactionActionsModals/VoidModal';
import TransactionApprovalView from './views/TransactionApprovalView';
import TransactionDetailsView from './views/TransactionDetailsView';
import TransactionErrorView from './views/TransactionErrorView';
import TransactionFilesListView from './views/TransactionFileListView';
import TransactionFilesEmptyView from './views/TransactionFilesEmptyView';
import TransactionFilesView from './views/TransactionFilesView';
import TransactionView from './views/TransactionLayoutView';
import TransactionLoadingView from './views/TransactionLoadingView';
import TransactionLogView from './views/TransactionLogView';
import TransactionNotesEmptyView from './views/TransactionNotesEmptyView';
import TransactionNotesLayoutView from './views/TransactionNotesLayoutView';
import TransactionNotesListView from './views/TransactionNotesListView';
import TransactionReadOnlyView from './views/TransactionReadOnlyView';

class TransactionContainer extends React.Component<TransactionContainerProps> {

  private fileUploadRef: React.RefObject<HTMLInputElement>;
  private fileDownloadRef: React.RefObject<HTMLFormElement>;


  private transactionActionButton = {
    create: {
      key: 'create',
      icon: 'hourglass',
      text: 'Add as Pending',
      value: 'create',
      onClick: () => this.props.onAddTransaction(false),
      ordinal: 0
    },
    createAndApprove: {
      key: 'createAndApprove',
      icon: 'checkmark',
      text: 'Add & Approve',
      value: 'createAndApprove',
      onClick: () => this.props.onAddTransaction(true),
      ordinal: 1
    },
    approve: {
      key: 'approve',
      icon: 'check circle outline',
      text: 'Approve',
      value: 'approve',
      onClick: () => this.props.onConfirmApprove('approve'),
      ordinal: 1
    },
    inquiry: {
      key: 'inquiry',
      icon: 'question circle',
      text: 'Request contect',
      value: 'inquiry',
      onClick: () => this.props.onConfirmAction('inquiry'),
      ordinal: 2
    },
    void: {
      key: 'void',
      icon: 'thumbs down',
      text: 'Decline',
      value: 'void',
      onClick: () => this.props.onConfirmAction('void'),
      ordinal: 3
    },
    delete: {
      key: 'delete',
      icon: 'x',
      text: 'Delete',
      value: 'delete',
      onClick: () => this.props.onConfirmAction('delete'),
      ordinal: 5
    },
    rescind: {
      key: 'rescind',
      icon: 'backward',
      text: 'Rescind',
      value: 'rescind',
      onClick: () => this.props.onConfirmAction('rescind'),
      ordinal: 4
    }
  }

  constructor(props: TransactionContainerProps) {
    super(props);
    this.fileUploadRef = React.createRef();
    this.fileDownloadRef = React.createRef();
  }

  get urlTransactionId(): string | undefined {
    return (this.props.match.params as any).transactionId;
  }

  private entityQueryStringKey = 'entity'

  get entityQueryString(): string | undefined {

    var query = this.props.location.search.substring(1);

    var vars = query.split('&');

    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === this.entityQueryStringKey) {
            return decodeURIComponent(pair[1]);
        }
    }
    
    return undefined;
}

  public componentWillMount() {

    this.props.onLoad(this.urlTransactionId, this.entityQueryString)
  }

  public render(): React.ReactNode {

    if (this.props.Transaction.loading) {
      return React.createElement(TransactionLoadingView)
    }

    if (this.props.Transaction.error) {
      return React.createElement(
        TransactionErrorView, { errorMessages: this.props.Transaction.errorMessages }
      );
    }

    if (this.props.Transaction.dashboardData !== null) {

      let transactionFormComponents: React.ReactNode[] = [];

      transactionFormComponents.push(this.renderTransactionLog());
      transactionFormComponents.push(this.renderTransactionDetails());
      transactionFormComponents.push(this.renderApprovalDetails());
      transactionFormComponents.push(this.renderNotes());
      transactionFormComponents.push(this.renderFiles());

      const transactionActionButtons = this.renderTransactionActionButtons();

      const transactionViewProps: TransactionLayoutViewProps = {
        transaction: this.props.Transaction.transaction,
        transactionFormComponents,
        transactionActionButtons,
        transactionForm: this.props.Transaction.transactionForm,
        addTransactionFileUploadForm: this.props.Transaction.addTransactionFileUploadForm,
        onCancelFormEdits: () => this.props.onCancelFormEdits(this.props.Transaction.transactionForm.id),
        onEditTransaction: this.props.onEditTransaction
      }

      return React.createElement(TransactionView, transactionViewProps);
    }

    return null;
  }

  private renderFiles(): React.ReactNode {

    let filesListView = null;

    if (this.props.Transaction.transaction !== null &&
      this.props.Transaction.files.length === 0) {
      filesListView = React.createElement(TransactionFilesEmptyView);
    }
    else {

      const fileListViewProps: TransactionFilesListViewProps = {
        files: this.props.Transaction.files,
        deleteFileId: this.props.Transaction.deleteFileId,
        deleteFileForm: this.props.Transaction.deleteFileForm,
        onSetDeleteFileId: this.props.onSetDeleteFileId,
        onDeleteFile: this.props.onDeleteFile,
        administrativeMode: (this.props.App.authenticationToken !== null &&
          this.props.App.authenticationToken.claims.is_administrator === true),
        fileDownloadRef: this.fileDownloadRef,
        onDownloadFile: (fileId :number) => this.props.onFileDownload(fileId, this.fileDownloadRef )
      }

      filesListView = React.createElement(TransactionFilesListView, fileListViewProps);
    }

    const filesViewProps: TransactionFilesViewProps = {
      onFormChange: (e: any, data: any) => this.props.onFormChange(
        this.props.Transaction.addFileForm.id, e, data
      ),
      onClearFormError: () => this.props.onClearFormError(this.props.Transaction.addFileForm.id),
      addFileForm: this.props.Transaction.addFileForm,
      administrativeMode: (this.props.App.authenticationToken !== null &&
        this.props.App.authenticationToken.claims.is_administrator === true),
      onFileUpload: (e: any) => this.props.onFileUpload(e, this.fileUploadRef),
      fileUploadRef: this.fileUploadRef,
      filesListView
    }

    return React.createElement(TransactionFilesView, filesViewProps)
  }

  private renderNotes(): React.ReactNode {

    if (this.props.Transaction.dashboardData === null) {
      return null;
    }

    let notesListView = null;

    if (this.props.Transaction.transaction !== null &&
      this.props.Transaction.transactionNotes.length === 0 &&
      this.props.Transaction.addNoteForm.open === false) {
      notesListView = React.createElement(TransactionNotesEmptyView);
    }
    else {
      const notesListViewProps: TransactionNotesListViewProps = {
        notes: this.props.Transaction.transactionNotes,
        editNoteForm: this.props.Transaction.editNoteForm,
        deleteNoteForm: this.props.Transaction.deleteNoteForm,
        deleteNoteId: this.props.Transaction.deleteNoteId,
        editNoteId: this.props.Transaction.editNoteId,
        onSetDeleteNoteId: this.props.onSetDeleteNoteId,
        onSetEditNoteId: this.props.onSetEditNoteId,
        onDeleteNote: this.props.onDeleteNote,
        onEditNote: this.props.onEditNote,
        allowAdministrativeNotes: (this.props.App.authenticationToken !== null &&
          this.props.App.authenticationToken.claims.is_administrator === true),
        onEditFormChange: (e: any, data: any) => this.props.onFormChange(
          this.props.Transaction.editNoteForm.id, e, data
        )
      }

      notesListView = React.createElement(TransactionNotesListView, notesListViewProps);
    }


    const notesViewProps: TransactionNotesLayoutViewProps = {
      transactionId: this.props.Transaction.transaction !== null ? this.props.Transaction.transaction.id : null,
      allowAdministrativeNotes: (this.props.App.authenticationToken !== null &&
        this.props.App.authenticationToken.claims.is_administrator === true),
      addNoteForm: this.props.Transaction.addNoteForm,
      notesListView,
      onFormChange: (e: any, data: any) => this.props.onFormChange(
        this.props.Transaction.addNoteForm.id, e, data
      ),
      onSetFormOpen: (open: boolean) => this.props.onSetFormOpen(this.props.Transaction.addNoteForm.id, open),
      onFormSubmit: this.props.onAddNote,
    }

    return React.createElement(TransactionNotesLayoutView, notesViewProps)
  }

  private renderTransactionDetails(): React.ReactNode {

    if (this.props.Transaction.dashboardData === null) {
      return null;
    }

    if (this.props.Transaction.transaction !== null &&
      this.props.Transaction.transaction.allowedActions.includes('edit') === false) {

      const transactionReadOnlyViewProps: TransactionReadOnlyViewProps = {
        transaction: this.props.Transaction.transaction,
        entity: this.props.Transaction.dashboardData!.entities
          .find((entity: ScottlandEntityDetailsView) => (entity.id == this.props.Transaction.transaction!.forEntity.key))!
      }

      return React.createElement(TransactionReadOnlyView, transactionReadOnlyViewProps)
    }


    const transactionDetailsViewProps: TransactionDetailsViewProps = {
      dashboardData: this.props.Transaction.dashboardData,
      transactionForm: this.props.Transaction.transactionForm,
      onTransactionFormChange: (e: any, data: any) => this.props.onFormChange(
        this.props.Transaction.transactionForm.id, e, data
      )
    }

    return React.createElement(TransactionDetailsView, transactionDetailsViewProps)
  }

  private renderTransactionActionButtons(): React.ReactNode {

    let actionButtonProps: TransactionActionButtonsProps;

    if (this.props.Transaction.transaction === null) {

      const actions = [this.transactionActionButton.create];

      if (this.props.App.authenticationToken !== null &&
        this.props.App.authenticationToken.claims.is_administrator === true) {
        actions.push(this.transactionActionButton.createAndApprove);
      }

      actionButtonProps = {
        actions,
        loading: this.props.Transaction.transactionForm.loading,
        buttonLabel: 'Add Transaction',
        actionModal: null
      };
    }
    else {

      const actionModal = this.renderActionModal();

      actionButtonProps = {
        actions: this.props.Transaction.transaction.allowedActions
          .map((action: ScottlandTransactionActions) => {

            if (this.transactionActionButton.hasOwnProperty(action) === false) {
              return;
            }

            return (this.transactionActionButton as any)[action];
          })
          .filter(action => action)
          .sort((a, b) => (a.ordinal > b.ordinal) ? 1 : -1),
        loading: this.props.Transaction.transactionForm.loading,
        buttonLabel: 'Actions',
        actionModal
      };

    }

    return React.createElement(TransactionActionButtons, actionButtonProps);
  }

  private renderActionModal(): React.ReactNode {

    if (this.props.Transaction.actionModalForm.fields.actionKey.value == this.transactionActionButton.approve.key) {

      const modalProps: ActionModalProps = {
        transaction: this.props.Transaction.transaction!,
        transactionFormTouched: this.props.Transaction.transactionForm.touched,
        actionModalForm: this.props.Transaction.actionModalForm,
        onCloseModal: () => this.props.onSetFormOpen(this.props.Transaction.actionModalForm.id, false),
        onFormChange: (e: any, data: any) => this.props.onFormChange(
          this.props.Transaction.transactionForm.id, e, data
        ),
        onConfirm: this.props.onApproveTransaction
      }

      return React.createElement(ApproveModalContent, modalProps)
    }

    if (this.props.Transaction.actionModalForm.fields.actionKey.value == this.transactionActionButton.delete.key) {

      const modalProps: ActionModalProps = {
        transaction: this.props.Transaction.transaction!,
        transactionFormTouched: this.props.Transaction.transactionForm.touched,
        actionModalForm: this.props.Transaction.actionModalForm,
        onCloseModal: () => this.props.onSetFormOpen(this.props.Transaction.actionModalForm.id, false),
        onFormChange: (e: any, data: any) => this.props.onFormChange(
          this.props.Transaction.transactionForm.id, e, data
        ),
        onConfirm: this.props.onDeleteTransaction
      }

      return React.createElement(DeleteModal, modalProps)
    }

    if (this.props.Transaction.actionModalForm.fields.actionKey.value == this.transactionActionButton.inquiry.key) {

      const modalProps: ActionModalProps = {
        transaction: this.props.Transaction.transaction!,
        transactionFormTouched: this.props.Transaction.transactionForm.touched,
        actionModalForm: this.props.Transaction.actionModalForm,
        onCloseModal: () => this.props.onSetFormOpen(this.props.Transaction.actionModalForm.id, false),
        onFormChange: (e: any, data: any) => this.props.onFormChange(
          this.props.Transaction.transactionForm.id, e, data
        ),
        onConfirm: this.props.onInquiryTransaction
      }

      return React.createElement(InquiryModal, modalProps)
    }

    if (this.props.Transaction.actionModalForm.fields.actionKey.value == this.transactionActionButton.rescind.key) {


      const modalProps: ActionModalProps = {
        transaction: this.props.Transaction.transaction!,
        transactionFormTouched: this.props.Transaction.transactionForm.touched,
        actionModalForm: this.props.Transaction.actionModalForm,
        onCloseModal: () => this.props.onSetFormOpen(this.props.Transaction.actionModalForm.id, false),
        onFormChange: (e: any, data: any) => this.props.onFormChange(
          this.props.Transaction.transactionForm.id, e, data
        ),
        onConfirm: this.props.onRescindTransaction
      }

      return React.createElement(RescindModal, modalProps)
    }

    if (this.props.Transaction.actionModalForm.fields.actionKey.value == this.transactionActionButton.void.key) {


      const modalProps: ActionModalProps = {
        transaction: this.props.Transaction.transaction!,
        transactionFormTouched: this.props.Transaction.transactionForm.touched,
        actionModalForm: this.props.Transaction.actionModalForm,
        onCloseModal: () => this.props.onSetFormOpen(this.props.Transaction.actionModalForm.id, false),
        onFormChange: (e: any, data: any) => this.props.onFormChange(
          this.props.Transaction.transactionForm.id, e, data
        ),
        onConfirm: this.props.onVoidTransaction
      }

      return React.createElement(VoidModal, modalProps)
    }

    return null;
  }

  private renderApprovalDetails(): React.ReactNode {

    if (this.props.Transaction.dashboardData === null ||
      this.props.App.authenticationToken === null ||
      this.props.App.authenticationToken.claims.is_administrator === false) {
      return null;
    }

    const approvalViewProps: TransactionApprovalViewProps = {
      transactionForm: this.props.Transaction.transactionForm,
      onTransactionFormChange: (e: any, data: any) => this.props.onFormChange(
        this.props.Transaction.transactionForm.id, e, data
      )
    }

    return React.createElement(TransactionApprovalView, approvalViewProps)
  }

  private renderTransactionLog(): React.ReactNode {

    const transactionForm = this.props.Transaction.transactionForm;

    const amount = transactionForm.fields.amount.value !== null &&
      transactionForm.fields.amount.value !== undefined &&
      transactionForm.fields.amount.value !== '' ?
        parseFloat((transactionForm.fields.amount.value as string).toString().split(',').join('')) : null;

    const transactionType = transactionForm.fields.type.value as string;

    const branchBalance = transactionForm.fields.forEntity.value !== null &&
      transactionForm.fields.forEntity.value !== undefined &&
      this.props.Transaction.dashboardData !== null &&
      this.props.Transaction.dashboardData.branches !== null ? this.props.Transaction.dashboardData.branches
        .find(branch => (
          branch.id == this.props.Transaction.dashboardData!.entities
            .find(entity => (entity.id == transactionForm.fields.forEntity.value))!.branch.key
        ))!.principal : null;

    

    const showTransactionBranchWarning =
          (amount !== null && branchBalance !== null && transactionType === 'Advance' && this.props.Transaction.transaction?.status !== 'Approved') &&
          ( amount > branchBalance)

    const transactionLogViewProps: TransactionLogViewProps = {
      transaction: this.props.Transaction.transaction,
      open: this.props.Transaction.transactionLogOpen,
      showTransactionBranchWarning,
      onToggleOpen: this.props.onToggleTransactionLogOpen
    }

    return React.createElement(TransactionLogView, transactionLogViewProps);
  }
}


const mapStateToProps = (state: StoreState) => ({ Transaction: state.Transaction, App: state.App });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  onLoad,
  onFormChange,
  onSetFormOpen,
  onAddNote,
  onSetDeleteNoteId,
  onDeleteNote,
  onSetEditNoteId,
  onEditNote,
  onSetDeleteFileId,
  onDeleteFile,
  onFileUpload,
  onAddTransaction,
  onCancelFormEdits,
  onConfirmApprove,
  onConfirmAction,
  onDeleteTransaction,
  onEditTransaction,
  onRescindTransaction,
  onInquiryTransaction,
  onVoidTransaction,
  onApproveTransaction,
  onToggleTransactionLogOpen,
  onFileDownload,
  onClearFormError
}, dispatch);

export default withRouter(<any>connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionContainer));


