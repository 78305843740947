

import { Reducer } from 'redux';
import { AppState } from './interfaces/AppState';
import { AppAction, SetAuthenticationTokenAction, SetAuthenticationLoadingAction } from './creators/interfaces/AppCreators';
import { AppActionTypes } from './creators/AppCreators';

const initialState: AppState = {
  authenticationLoading :true,
  authenticationToken: null
};

const handleResetToInitialState = (state: AppState, action: AppAction) :AppState => {
	return {
		...initialState,
	};
}


const handleSetAuthenticationToken = (state: AppState, action: SetAuthenticationTokenAction) :AppState  => {
	return {
    ...state,
    authenticationToken: action.authenticationToken
	};
}


const handleSetAuthenticationLoading = (state: AppState, action: SetAuthenticationLoadingAction) :AppState  => {
	return {
    ...state,
    authenticationLoading: action.loading
	};
}





const handlers: { [x: string]: (state: AppState, action: AppAction) => AppState } =
{
  [AppActionTypes.ResetToInitialState]: handleResetToInitialState,
  [AppActionTypes.SetAuthenticationToken]: handleSetAuthenticationToken,
  [AppActionTypes.SetAuthenticationLoading]: handleSetAuthenticationLoading
} as any

const AppReducer: Reducer<AppState, AppAction> = (state: AppState | undefined, action: AppAction): AppState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default AppReducer