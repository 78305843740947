import * as React from 'react';
import { Header, Icon, Segment, Grid, Divider, Button } from 'semantic-ui-react';
import { AddBranchViewProps } from './interfaces/AddBranchViewProps';
import { AddAccountViewProps } from './interfaces/AddAccountViewProps';

const AddAccountView = (props: AddAccountViewProps) => (
  <Segment basic>
    <Grid columns={2} stackable textAlign='center'>
      <Divider vertical>Or</Divider>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column>
          <Header
            style={{
              cursor: 'pointer'
            }}
            onClick={props.onAddBranch}
          >
            <Icon name='university' />
            Add Branch
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header
            style={{
              cursor: 'pointer'
            }}
            onClick={props.onAddEntity}
          >
            <Icon name='book' />
            Add Entity
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

export default AddAccountView