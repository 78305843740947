import * as React from 'react';
import { Link } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';

const NotFoundView = () => (
  <>
    <Segment
      placeholder
      basic
      style={{
        backgroundImage: 'url("/black-angus-cow.png")',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        backgroundPosition: 'center bottom',
        padding: 0
      }}
    >
      <Segment placeholder basic>
        <Header icon inverted>
          Oops! You seem to have lost your way...
        <Header.Subheader>
            We can't find the page you requested.
        </Header.Subheader>
          <Header.Subheader>
            <Link to ='/' style={{color:'white'}}>
              <strong>Click <u>here</u> to mosey on home...</strong>
            </Link>
          </Header.Subheader>
        </Header>
      </Segment>
    </Segment>
  </>
)

export default NotFoundView