import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const EntityDeletedView = () => (
  <Segment placeholder basic>
    <Header icon>
      <Icon.Group>
        <Icon name='book' />
        <Icon corner name='x' color='red' />
      </Icon.Group>
      <span style={{position:'relative', top: '-.75em', left:'1em'}}>Entity Deleted</span>
      <Header.Subheader>
        <Link to='/dashboard' style={{ color: 'rgba(0,0,0,.87)' }}>
          Click <u>here</u> to return to the dashboard
        </Link>
      </Header.Subheader>
    </Header>
  </Segment>
)

export default EntityDeletedView