import * as React from 'react';
import { Form, Grid, Header, Icon, Label, Container, Message } from 'semantic-ui-react';
import { ScottlandEntityDetailsView } from '../../../lib/interfaces/ScottlandEntityView';
import { TransactionDetailsViewProps } from './interfaces/TransactionDetailsViewProps';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask'
import { Link } from 'react-router-dom'


const TransactionDetailsView = (props: TransactionDetailsViewProps) => (
  <>
    <Form.Field className='entity-selection-field'>
      <Form.Dropdown
        inline
        fluid
        selection
        search
        id={props.transactionForm.fields.forEntity.id}
        name={props.transactionForm.fields.forEntity.id}
        label={props.transactionForm.fields.forEntity.label}
        placeholder={props.transactionForm.fields.forEntity.placeholder}
        value={props.transactionForm.fields.forEntity.value as any}
        error={props.transactionForm.fields.forEntity.error}
        options={props.dashboardData.entities.map((entity: ScottlandEntityDetailsView) => (
          { key: entity.id, value: entity.id, text: entity.name }
        ))}
        onChange={props.onTransactionFormChange}
      />
      <div
        style={{
          float: 'right',
          marginTop: '.25em'
        }}
      >
        {props.transactionForm.fields.forEntity.value !== null &&
          props.transactionForm.fields.forEntity.value !== undefined &&
          props.dashboardData.branches !== null &&
          <Label
            size='small'
            as={Link}
            to={`/branches/${props.dashboardData.branches
              .find(branch => (
                branch.id == props.dashboardData.entities
                  .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.branch.key
              ))!.id
              }`
            }
          >
            <Icon name='university' />
            {props.dashboardData.branches
              .find(branch => (
                branch.id == props.dashboardData.entities
                  .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.branch.key
              ))!.name
            }&nbsp;
                    <span
              style={{
                color: props.dashboardData.branches
                  .find(branch => (
                    branch.id == props.dashboardData.entities
                      .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.branch.key
                  ))!.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
              }}>
              {props.dashboardData.branches
                .find(branch => (
                  branch.id == props.dashboardData.entities
                    .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.branch.key
                ))!.formattedPrincipal
              }
            </span>
          </Label>
        }
        {props.transactionForm.fields.forEntity.value !== null &&
          props.transactionForm.fields.forEntity.value !== undefined &&
          <Label size='small' as={Link} to={`/entity/${props.transactionForm.fields.forEntity.value}`}>
            <Icon name='book' />
            Principal&nbsp;
                    {
              <span
                style={{
                  color: props.dashboardData.entities
                    .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
                }}>
                {
                  props.dashboardData.entities
                    .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.formattedPrincipal
                }
              </span>
            }
            &nbsp;
            Interest&nbsp;
                    {
              <span
                style={{
                  color: props.dashboardData.entities
                    .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
                }}>
                {
                  props.dashboardData.entities
                    .find(entity => (entity.id == props.transactionForm.fields.forEntity.value))!.formattedInterest
                }
              </span>
            }
          </Label>
        }
      </div>
      <div style={{ clear: 'both' }} />
    </Form.Field>
    <Form.Group widths='equal'>
      <Form.Field
        error={props.transactionForm.fields.effectiveDate.error}
        fluid
      >
        <label htmlFor={props.transactionForm.fields.effectiveDate.id}>
          {props.transactionForm.fields.effectiveDate.label}
        </label>
        <input
          id={props.transactionForm.fields.effectiveDate.id}
          name={props.transactionForm.fields.effectiveDate.id}
          type='date'
          value={props.transactionForm.fields.effectiveDate.value as number}
          onChange={(e) => props.onTransactionFormChange(e, null)}
        />
      </Form.Field>
      <Form.Dropdown
        id={props.transactionForm.fields.type.id}
        name={props.transactionForm.fields.type.id}
        placeholder={props.transactionForm.fields.type.placeholder}
        label={props.transactionForm.fields.type.label}
        error={props.transactionForm.fields.type.error}
        value={props.transactionForm.fields.type.value as any}
        options={props.transactionForm.fields.type.options}
        onChange={props.onTransactionFormChange}
        selection
        fluid
      />
      <Form.Input
        icon
        iconPosition='left'
        error={props.transactionForm.fields.amount.error}
        label={props.transactionForm.fields.amount.label}
      >
        <Icon name='dollar sign' />
        <MaskedInput
          id={props.transactionForm.fields.amount.id}
          name={props.transactionForm.fields.amount.id}
          placeholder={props.transactionForm.fields.amount.placeholder}
          value={props.transactionForm.fields.amount.value as number}
          mask={createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true,
            decimalLimit: 2
          })}
          pipe={(conformedValue: string, config: any) => (conformedValue === '0' ? '' : conformedValue)}
          onChange={(e) => props.onTransactionFormChange(e, null)}
          guide={false}
        />

      </Form.Input>
    </Form.Group>
    <Form.TextArea
      id={props.transactionForm.fields.description.id}
      label={props.transactionForm.fields.description.label}
      name={props.transactionForm.fields.description.id}
      error={props.transactionForm.fields.description.error}
      placeholder={props.transactionForm.fields.description.placeholder}
      value={props.transactionForm.fields.description.value as any}
      onChange={(e) => props.onTransactionFormChange(e, null)}
    />
  </>
)

export default TransactionDetailsView