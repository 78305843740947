import { DashboardView } from "../../lib/interfaces/DashboardView";
import * as DashboardCreators from "./interfaces/DashboardCreators";
import * as FormCreators from '../creators/FormCreators';
import { ScottlandBranchView, ScottlandBranchDetailsView } from "../../lib/interfaces/ScottlandBranchView";
import { ScottlandTransactionSummaryView } from "../../lib/interfaces/ScottlandTransactionView";

export const DashboardActionTypes = {
  ResetToInitialState: 'DASHBOARD_ACTIONS_ResetToInitialState',
  SetLoading: 'DASHBOARD_ACTIONS_SetLoading',
  SetError: 'DASHBOARD_ACTIONS_SetError',
  SetErrorMessages: 'DASHBOARD_ACTIONS_SetErrorMessages',
  SetDashboardData: 'DASHBOARD_ACTIONS_SetDashboardData',
  SetPendingTransactions: 'DASHBOARD_ACTIONS_SetPendingTransactions',
  ToggleTableView: 'DASHBOARD_ACTIONS_ToggleTableView',
  ToggleShowInactiveEntities: 'DASHBOARD_ACTIONS_ToggleShowInactiveEntities',
  SetFormLoading: 'DASHBOARD_ACTIONS_SetFormLoading',
  SetFormError: 'DASHBOARD_ACTIONS_SetFormError',
  SetFormErrorMessages: 'DASHBOARD_ACTIONS_SetFormErrorMessages',
  ClearFormError: 'DASHBOARD_ACTIONS_ClearFormError',
  FormChange: 'DASHBOARD_ACTIONS_FormChange',
  SetFormOpen: 'DASHBOARD_ACTIONS_SetFormOpen',
  CancelFormEdits: 'DASHBOARD_ACTIONS_CancelFormEdits',
  ToggleShowAllEntities: 'DASHBOARD_ACTIONS_ToggleShowAllEntities',
  AddBranch: 'DASHBOARD_ACTIONS_AddBranch',
  RemoveBranch: 'DASHBOARD_ACTIONS_RemoveBranch',
  EditBranch: 'DASHBOARD_ACTIONS_EditBranch'
}

export const ResetToInitialState: DashboardCreators.DashboardCreator = (): DashboardCreators.DashboardAction => {
  return { type: DashboardActionTypes.ResetToInitialState };
}

export const SetLoading: DashboardCreators.SetLoadingCreator = (loading: boolean): DashboardCreators.SetLoadingAction => {
  return {
    type: DashboardActionTypes.SetLoading,
    loading
  };
}

export const SetError: DashboardCreators.SetErrorCreator = (error: boolean): DashboardCreators.SetErrorAction => {
  return {
    type: DashboardActionTypes.SetError,
    error
  };
}


export const SetErrorMessages: DashboardCreators.SetErrorMessagesCreator = (errorMessages: string[]): DashboardCreators.SetErrorMessagesAction => {
  return {
    type: DashboardActionTypes.SetErrorMessages,
    errorMessages
  };
}

export const SetFormLoading: DashboardCreators.DashboardCreator = (formId: string, loading: boolean): DashboardCreators.DashboardAction => (
  FormCreators.SetFormLoading(
    DashboardActionTypes.SetFormLoading,
    formId,
    loading
  )
);


export const SetFormError: DashboardCreators.DashboardCreator = (formId: string, error: boolean): DashboardCreators.DashboardAction => (
  FormCreators.SetFormError(
    DashboardActionTypes.SetFormError,
    formId,
    error
  )
);

export const SetFormErrorMessages: DashboardCreators.DashboardCreator = (formId: string, errorMessages: string[]): DashboardCreators.DashboardAction => (
  FormCreators.SetFormErrorMessages(
    DashboardActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
);

export const ClearFormError: DashboardCreators.DashboardCreator = (formId: string): DashboardCreators.DashboardAction => (
  FormCreators.ClearFormError(
    DashboardActionTypes.ClearFormError,
    formId
  )
);


export const FormChange: DashboardCreators.DashboardCreator =
  (formId: string, field: string, value: string, error: boolean): DashboardCreators.DashboardAction => (
    FormCreators.FormChange(
      DashboardActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );

export const SetFormOpen: DashboardCreators.DashboardCreator = (formId: string, loading: boolean): DashboardCreators.DashboardAction => (
  FormCreators.SetFormOpen(
    DashboardActionTypes.SetFormOpen,
    formId,
    loading
  )
);

export const CancelFormEdits: DashboardCreators.DashboardCreator =
  (formId: string): DashboardCreators.DashboardAction => (
    FormCreators.CancelFormEdits(
      DashboardActionTypes.CancelFormEdits,
      formId
    )
  );


export const SetDashboardData: DashboardCreators.SetDataboardDataCreator = (dashboardData: DashboardView): DashboardCreators.SetDashboardDataAction => (
  {
    type: DashboardActionTypes.SetDashboardData,
    dashboardData
  }
);

export const SetPendingTransactions: DashboardCreators.SetPendingTransactionsCreator =
  (pendingTransactions: ScottlandTransactionSummaryView[]): DashboardCreators.SetPendingTransactionsAction => (
    {
      type: DashboardActionTypes.SetPendingTransactions,
      pendingTransactions
    }
  );

export const AddBranch: DashboardCreators.AddBranchCreator =
  (branchToAdd: ScottlandBranchDetailsView): DashboardCreators.AddBranchAction => (
    {
      type: DashboardActionTypes.AddBranch,
      branchToAdd
    }
  );

export const RemoveBranch: DashboardCreators.RemoveBranchCreator =
  (branchIdToRemove: number): DashboardCreators.RemoveBranchAction => (
    {
      type: DashboardActionTypes.RemoveBranch,
      branchIdToRemove
    }
  );

export const EditBranch: DashboardCreators.EditBranchCreator =
  (branchIdToEdit: number, branch: ScottlandBranchView): DashboardCreators.EditBranchAction => (
    {
      type: DashboardActionTypes.EditBranch,
      branchIdToEdit,
      branch
    }
  );


export const ToggleTableView: DashboardCreators.DashboardCreator = (): DashboardCreators.DashboardAction => {
  return { type: DashboardActionTypes.ToggleTableView };
}

export const ToggleShowInactiveEntities: DashboardCreators.DashboardCreator = (): DashboardCreators.DashboardAction => {
  return { type: DashboardActionTypes.ToggleShowInactiveEntities };
}

export const ToggleShowAllEntities: DashboardCreators.DashboardCreator = (): DashboardCreators.DashboardAction => {
  return { type: DashboardActionTypes.ToggleShowAllEntities };
}



