import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import { EntitiesTableViewProps } from './interfaces/EntitiesTableViewProps';
import moment from 'moment';

const EntitiesTableView = (props: EntitiesTableViewProps) => (
  <Table
    size='small'
    compact
    striped
    basic
    sortable
  >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'name' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('name')}
        >
          Entity
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'members' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('members')}
        >
          Members
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'establishedAt' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('establishedAt')}
        >
          Established
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'depositRate' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('depositRate')}
        >
          Deposit Rate
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'advanceRate' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('advanceRate')}
        >
          Advance Rate
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'interest' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('interest')}
        >
          Interest
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={props.tableSettingsForm.fields.sortColumn.value === 'principal' ? props.tableSettingsForm.fields.sortDirection.value as any : null}
          onClick={() => props.onSort('principal')}
        >
          Principal
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.entities.map(entity => (
        <Table.Row>
          <Table.Cell>
            <Link className='scottland-link' to={`/entity/${entity.id}`}>
              <strong>{entity.name}</strong>
              {entity.active === false &&
                <Icon
                  name='archive'
                  color='olive'
                />
              }
            </Link>
          </Table.Cell>
          <Table.Cell>
            <Icon name='user' color='olive' size='small' />
            {Object.keys(entity.users).length}&nbsp;Members
          </Table.Cell>
          <Table.Cell>
            {moment.unix(entity.establishedAt).format("l")}
          </Table.Cell>
          <Table.Cell>
            <Icon name='money bill alternate outline' color='olive' size='small' />
            {entity.ratePlan.formattedDepositRate}
          </Table.Cell>
          <Table.Cell>
            <Icon name='money bill alternate outline' color='red' size='small' />
            {entity.ratePlan.formattedAdvanceRate}
          </Table.Cell>
          <Table.Cell>
            <span style={{ color: entity.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
              {entity.formattedInterest}
            </span>
          </Table.Cell>
          <Table.Cell>
            <span style={{ color: entity.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
              {entity.formattedPrincipal}
            </span>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default EntitiesTableView
