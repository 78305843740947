import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid, Icon, List, Popup, Label } from 'semantic-ui-react';
import { EntityCardsViewProps } from './interfaces/EntityCardsViewProps';

const EntityCardStyles = {
  backgroundColor: '#F8FAED'
}

const EntityCardsView = (props: EntityCardsViewProps) =>
  (
    <Card.Group>
      {props.entities.map(entity =>
        <Card key={entity.id} style={EntityCardStyles} as={Link} color='olive' to={`/entity/${entity.id}`}>
          <Card.Content>

            <Card.Header style={{ fontSize: '1em' }}>
              {entity.name}
              {entity.active === false &&
                <Icon
                  name='archive'
                  color='olive'
                  style={{float:'right'}}
                />
              }
            </Card.Header>
            <Card.Meta style={{ fontSize: 'smaller' }}>
              <i>Established</i> {moment.unix(entity.establishedAt).format("MMMM Do, YYYY")}
            </Card.Meta>
            <Card.Meta style={{ fontSize: 'smaller' }}>
              <div>
                <i>Principal</i>&nbsp;
                <span style={{ color: entity.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {entity.formattedPrincipal}
                </span>
              </div>
              <div>
                <i>Interest</i>&nbsp;
                <span style={{ color: entity.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {entity.formattedInterest}
                </span>
              </div>
            </Card.Meta>
          </Card.Content>
          <Card.Content extra style={{ backgroundColor: '#563922', fontSize: 'smaller', color: '#dddddd' }} >
            <Grid columns={2}>
              <Grid.Column textAlign='left'>
                <Popup
                  header='Members'
                  position='bottom left'
                  content={
                    <List size='small'
                      items={
                        Object.keys(entity.users).map((user) => entity.users[user as any])
                      }
                    />
                  }
                  trigger={
                    <Icon
                      name='user'
                      color='olive'
                      size='small'
                      style={{ cursor: 'pointer' }}
                    />
                  }
                />
                {Object.keys(entity.users).length}&nbsp;Members
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Popup
                  header='Members'
                  position='bottom right'
                  content={
                    <List size='small'
                      items={
                        Object.keys(entity.users).map((user) => entity.users[user as any])
                      }
                    />
                  }
                  trigger={
                    <div>
                      <Icon name='money bill alternate outline' color='olive' size='small' />
                      {entity.ratePlan.formattedDepositRate}
                      &nbsp;
                      <Icon name='money bill alternate outline' color='red' size='small' />
                      {entity.ratePlan.formattedAdvanceRate}
                    </div>
                  }
                />

              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
      )}
    </Card.Group>
  )

export default EntityCardsView