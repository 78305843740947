
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import StoreState from '../../redux/interfaces/StoreState';
import { LoginImageContainerProps } from './interfaces/LoginImageContainerProps';
import { onLoginImageUpload } from './thunks/LoginImageThunks';
import { LoginImageViewProps } from './views/interfaces/LoginImageViewProps';
import LoginImageErrorView from './views/LoginImageErrorView';
import LoginImageLoadingView from './views/LoginImageLoadingView';
import LoginImageView from './views/LoginImageView';

class LoginImageContainer extends React.Component<LoginImageContainerProps> {

  private loginImageUploadRef: React.RefObject<HTMLInputElement>;

  constructor(props: LoginImageContainerProps) {
    super(props);
    this.loginImageUploadRef = React.createRef();
  }

  public componentWillMount() {

  }

  public render(): React.ReactNode {

    const loginimageViewProps: LoginImageViewProps = {
      loading :this.props.LoginImage.loading,
      error: this.props.LoginImage.error,
      errorMessages :this.props.LoginImage.errorMessages,
      onLoginImageUpload:(e: any) => this.props.onLoginImageUpload(e, this.loginImageUploadRef),
      loginImageUploadRef: this.loginImageUploadRef
    }

    return React.createElement(LoginImageView, loginimageViewProps);

  }
}


const mapStateToProps = (state: StoreState) => ({ App: state.App, LoginImage: state.LoginImage });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  onLoginImageUpload
}, dispatch);

export default withRouter(<any>connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginImageContainer));


