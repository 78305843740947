import * as React from 'react';
import { Header, Icon, Segment, Table, Dropdown, Pagination, Label, Form, Button, Grid, Input } from 'semantic-ui-react';
import { EntityTransactionTableViewProps } from './interfaces/EntityTransactionTableViewProps';
import moment from 'moment';
import { Link } from 'react-router-dom';

const EntityTransactionTable = (props: EntityTransactionTableViewProps) => (
	<Segment basic>
		<Grid columns='2' style={{ paddingBottom: '.75em' }}>
			<Grid.Column width='6'>
				<Header as='h3'>
					Transactions
          <Label
						color='olive'
						circular
						size='tiny'
					>
						{`${props.filteredTransactionsCount}/${props.totalTransactionsCount}`}
					</Label>
				</Header>
			</Grid.Column>
			<Grid.Column textAlign='right' className='transaction-filter-row' width='10' >
				<Button
					basic
					size='small'
					color={props.tableSettingsForm.fields.showYearEndOnly.value as any === true ? 'olive' : undefined}
					icon='calendar'
					style={{ margin: 0 }}
					onClick={() => props.onTableSettingFormChange(
						{
							target: {
								name: props.tableSettingsForm.fields.showYearEndOnly.id,
								value: !(props.tableSettingsForm.fields.showYearEndOnly.value as any)
							}
						}, null
					)}
				/>
				<Input
					size='mini'
					id={props.tableSettingsForm.fields.startDate.id}
					name={props.tableSettingsForm.fields.startDate.id}
					placeholder={props.tableSettingsForm.fields.startDate.placeholder}
					value={props.tableSettingsForm.fields.startDate.value as any}
					onChange={props.onTableSettingFormChange}
					type='date'
				/>
				<Input
					size='mini'
					id={props.tableSettingsForm.fields.endDate.id}
					name={props.tableSettingsForm.fields.endDate.id}
					placeholder={props.tableSettingsForm.fields.endDate.placeholder}
					value={props.tableSettingsForm.fields.endDate.value as any}
					onChange={props.onTableSettingFormChange}
					type='date'
				/>
				<Dropdown
					id={props.tableSettingsForm.fields.typeFilter.id}
					name={props.tableSettingsForm.fields.typeFilter.id}
					placeholder={props.tableSettingsForm.fields.typeFilter.placeholder}
					value={props.tableSettingsForm.fields.typeFilter.value as any}
					options={props.typeFilterOptions}
					onChange={props.onTableSettingFormChange}
					selection
					clearable
					style={{
						fontSize: '11px',
						lineHeight: '1.4285em'
					}}
				/>
				<Dropdown
					id={props.tableSettingsForm.fields.statusFilter.id}
					name={props.tableSettingsForm.fields.statusFilter.id}
					placeholder={props.tableSettingsForm.fields.statusFilter.placeholder}
					value={props.tableSettingsForm.fields.statusFilter.value as any}
					options={props.statusFilterOptions}
					onChange={props.onTableSettingFormChange}
					selection
					clearable
					style={{
						fontSize: '11px',
						lineHeight: '1.4285em'
					}}
				/>
				<Button
					basic
					size='small'
					color={props.tableSettingsForm.fields.showEphermalTransactions.value as any === true ? 'olive' : undefined}
					icon={props.tableSettingsForm.fields.showEphermalTransactions.value as any === true ? 'eye' : 'eye slash outline'}
					style={{ margin: 0 }}
					onClick={() => props.onTableSettingFormChange(
						{
							target: {
								name: props.tableSettingsForm.fields.showEphermalTransactions.id,
								value: !(props.tableSettingsForm.fields.showEphermalTransactions.value as any)
							}
						}, null
					)}
				/>
				<Button
					as={Link}
					to={`/add-transaction?entity=${props.entity.id}`}
					color='olive'
					icon='plus'
					size='small'
					style={{ margin: 0 }}
				/>
			</Grid.Column>
		</Grid>
		<hr className='scottland-divider-small' />
		<Form style={{ paddingTop: '.5em' }}>
			<Form.Group inline widths='equal'>
				<Form.Field>
					<Dropdown
						id={props.tableSettingsForm.fields.itemsPerPage.id}
						name={props.tableSettingsForm.fields.itemsPerPage.id}
						label={props.tableSettingsForm.fields.itemsPerPage.label}
						placeholder={props.tableSettingsForm.fields.itemsPerPage.placeholder}
						value={props.tableSettingsForm.fields.itemsPerPage.value as any}
						options={props.tableSettingsForm.fields.itemsPerPage.options}
						onChange={props.onTableSettingFormChange}
						inline
					/>
					<label>&nbsp;{props.tableSettingsForm.fields.itemsPerPage.label}</label>
				</Form.Field>
				<Form.Field
					style={{
						textAlign: 'right',
						padding: 0
					}}
				>
					{props.totalPages > 1 &&
						<Pagination
							activePage={(props.tableSettingsForm.fields.activePage.value as any) <= props.totalPages ?
								props.tableSettingsForm.fields.activePage.value as string :
								props.totalPages}
							boundaryRange={2}
							onPageChange={(e, { activePage }) => props.onTableSettingFormChange(
								{ target: { name: props.tableSettingsForm.fields.activePage.id, value: activePage } }, null)
							}
							size='mini'
							siblingRange={1}
							totalPages={props.totalPages}
						/>
					}
					<form
						hidden={true}
						method='post'
						target='_blank'
						ref={props.ledgerFileDownloadRef}
					>
						<input hidden={true} name='clientId' />
						<input hidden={true} name='token' />
					</form>
					<Button
						size='small'
						color='olive'
						icon='download'
						as='a'
						href='ledger'
						onClick={(e :any) => { e.preventDefault(); props.onDownloadLedgerFile(); }}
					/>
				</Form.Field>
			</Form.Group>
		</Form>
		<Table
			compact
			striped
			size='small'
		>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>Status</Table.HeaderCell>
					<Table.HeaderCell>Effective Date</Table.HeaderCell>
					<Table.HeaderCell>Type</Table.HeaderCell>
					<Table.HeaderCell>Description</Table.HeaderCell>
					<Table.HeaderCell textAlign='right'>Amount</Table.HeaderCell>
					<Table.HeaderCell textAlign='right'>Interest</Table.HeaderCell>
					<Table.HeaderCell textAlign='right'>Principal</Table.HeaderCell>
					<Table.HeaderCell></Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{props.transactions.map(transaction => (
					<Table.Row>

						<Table.Cell collapsing>
							{transaction.status === 'Approved' &&
								<Icon size='small' inverted circular name='checkmark' color='green' />
							}
							{transaction.status === 'Pending' &&
								<Icon size='small' inverted circular name='hourglass one' color='grey' />
							}
							{transaction.status === 'Declined' &&
								<Icon size='small' inverted circular name='x' color='red' />
							}
							{transaction.status === 'Admin Contact' &&
								<Icon size='small' inverted circular name='question' color='yellow' />
							}
						</Table.Cell>
						<Table.Cell collapsing>
							{moment.unix(transaction.effectiveAt).format("l")}
						</Table.Cell>
						<Table.Cell collapsing>
							{transaction.isBalanceForward === true ? 'Balance Forward' : transaction.type}
						</Table.Cell>
						<Table.Cell>
							{transaction.description}
						</Table.Cell>
						<Table.Cell textAlign='right' collapsing >
							{transaction.isBalanceForward === false &&
								<span style={{ color: transaction.amount < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
									{transaction.formattedAmount}
								</span>
							}
						</Table.Cell>
						<Table.Cell textAlign='right' >
							<span
								style={{
									color: transaction.runningInterestBalance !== null && transaction.runningInterestBalance < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
								}}>
								{transaction.formattedRunningInterestBalance}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='right' collapsing >
							<span
								style={{
									color: transaction.runningPrincipalBalance !== null && transaction.runningPrincipalBalance < 0 ? '#AA0000' : '#666666', fontWeight: 'bold'
								}}>
								{transaction.formattedRunningPrincipalBalance}
							</span>
						</Table.Cell>
						<Table.Cell textAlign='right' collapsing={true}>
							{transaction.isEphemeral === false &&
								<Link to={`/transaction/${transaction.id}`}>
									<Icon name='pencil' color='brown' />
								</Link>
							}
						</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	</Segment >
)

export default EntityTransactionTable