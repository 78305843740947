import * as FormCreators from '../creators/FormCreators';
import * as LoginCreators from "./interfaces/LoginCreators";

export const LoginActionTypes = {
  ResetToInitialState: 'LOGIN_ACTIONS_ResetToInitialState',
  SetFormLoading: 'LOGIN_ACTIONS_SetFormLoading',
  SetFormError: 'LOGIN_ACTIONS_SetFormError',
  SetFormErrorMessages: 'LOGIN_ACTIONS_SetErrorMessages',
  ClearFormError: 'LOGIN_ACTIONS_ClearFormError',
  FormChange: 'LOGIN_ACTIONS_FormChange'
}

export const ResetToInitialState: LoginCreators.LoginCreator = (): LoginCreators.LoginAction => {
  return { type: LoginActionTypes.ResetToInitialState };
}

export const SetFormLoading: LoginCreators.LoginCreator = (formId: string, loading: boolean): LoginCreators.LoginAction => {
  return FormCreators.SetFormLoading(
    LoginActionTypes.SetFormLoading,
    formId,
    loading
  )
}

export const SetFormError: LoginCreators.LoginCreator = (formId: string, error: boolean): LoginCreators.LoginAction => {
  return FormCreators.SetFormError(
    LoginActionTypes.SetFormError,
    formId,
    error
  )
}

export const SetFormErrorMessages: LoginCreators.LoginCreator = (formId: string, errorMessages: string[]): LoginCreators.LoginAction => {
  return FormCreators.SetFormErrorMessages(
    LoginActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
}

export const ClearFormError: LoginCreators.LoginCreator = (formId: string): LoginCreators.LoginAction => {
  return FormCreators.ClearFormError(
    LoginActionTypes.ClearFormError,
    formId
  )
}


export const FormChange: LoginCreators.LoginCreator =
  (formId: string, field: string, value: string, error: boolean): LoginCreators.LoginAction => (
    FormCreators.FormChange(
      LoginActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );
