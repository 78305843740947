import { ThunkAction } from "redux-thunk";
import StoreState from "../../../redux/interfaces/StoreState";
import { Dispatch } from "react";
import * as DashboardCreators from "../../../redux/creators/DashboardCreators"
import * as ScottlandApi from "../../../lib/ScottlandApi"
import { FormState } from "../../../redux/interfaces/common/FormState";
import { FormField } from "../../../redux/interfaces/common/FormField";
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import { ScottlandEntityRequest } from "../../../lib/interfaces/ScottlandEntityRequest";
import moment from "moment";
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";
import { push } from "connected-react-router";
import { ScottlandBranchRequest } from "../../../lib/interfaces/ScottlandBranchRequest";

export function onLoadDashboard(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>) => {

		dispatch(DashboardCreators.SetLoading(true));
		dispatch(DashboardCreators.SetError(false));

		try {


			const dashboardDataPromise = ScottlandApi.GetDashboardView();
			const pendingTransactionsPromise = ScottlandApi.GetPendingTransactions();

			await Promise.all([pendingTransactionsPromise, dashboardDataPromise]);

			dispatch(DashboardCreators.SetPendingTransactions(await pendingTransactionsPromise));
			dispatch(DashboardCreators.SetDashboardData(await dashboardDataPromise));

		}
		catch {
			dispatch(DashboardCreators.SetError(true));
		}
		finally {
			dispatch(DashboardCreators.SetLoading(false));
		}
	}
}

export function onToggleTableView(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>) => {

		dispatch(DashboardCreators.ToggleTableView())
	}
}

export function onToggleShowInactiveEntities(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>) => {

		dispatch(DashboardCreators.ToggleShowInactiveEntities())
	}
}

export function onToggleShowAllEntities(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>) => {

		dispatch(DashboardCreators.ToggleShowAllEntities())
	}
}

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		let targetField: (string | null | undefined) = e.target.name;
		let fieldValue: (string | null | undefined) = e.target.value;

		if (targetField === null || targetField === undefined || targetField === '') {
			targetField = data.name;

			if (data.type === 'checkbox') {
				fieldValue = data.checked;
			}
			else {
				fieldValue = data.value;
			}
		}

		if (targetField === null ||
			targetField === undefined) {
			throw new TypeError(`No target field sent to change handler: ${targetField}`);
		}

		const formState = (getState().Dashboard as any)[formId as any] as FormState<any>;

		if (formState === null ||
			formState === undefined) {
			throw new TypeError(`Unknown form ${formId}`);
		}

		const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

		if (formFieldState === null ||
			formFieldState === undefined) {
			throw new TypeError(`Unknown form field ${targetField}`);
		}

		const fieldIsValid = Validate(fieldValue,
			formFieldState.validation.required,
			formFieldState.validation.validators);

		dispatch(DashboardCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

	}
}

export function onCancelFormEdits(formId: string): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(DashboardCreators.CancelFormEdits(formId));
	}
}

export function onSetFormOpen(formId: string, open: boolean): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {


		dispatch(DashboardCreators.CancelFormEdits(formId));
		dispatch(DashboardCreators.SetFormOpen(formId, open));
	}
}

export function onAddEntity(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const dashboardState = getState().Dashboard;

		const addEntityForm = dashboardState.addEntityForm;

		dispatch(DashboardCreators.ClearFormError(addEntityForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(DashboardCreators.FormChange(addEntityForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(addEntityForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(DashboardCreators.SetFormError(addEntityForm.id, true));
			dispatch(DashboardCreators.SetFormErrorMessages(addEntityForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const entityToAdd: ScottlandEntityRequest = {

				name: addEntityForm.fields.name.value as string,
				branchId: addEntityForm.fields.branch.value as number,
				users: addEntityForm.fields.members.value as unknown as number[],
				establishedAt: moment(addEntityForm.fields.establishedAt.value as string).unix(),
				ratePlanId: addEntityForm.fields.ratePlan.value as number,
				balanceForwardPrincipal: addEntityForm.fields.balanceForwardPrincipal.value as number || 0,
				balanceForwardInterest: addEntityForm.fields.balanceForwardInterest.value as number || 0,
				active: addEntityForm.fields.active.value ? true : false as boolean
			}

			dispatch(DashboardCreators.SetFormLoading(addEntityForm.id, true));

			try {

				const createdEntityId = await ScottlandApi.CreateEntity(entityToAdd);
				
				dispatch(DashboardCreators.CancelFormEdits(addEntityForm.id));
				dispatch(DashboardCreators.SetFormOpen(addEntityForm.id, false));
				dispatch(DashboardCreators.SetFormOpen(dashboardState.addAccountForm.id, false));

				dispatch(push(`/entity/${createdEntityId}`));
			
			}
			catch (e) {
				const error = e as ScottlandApiError;

				dispatch(DashboardCreators.SetFormError(addEntityForm.id, true));
				dispatch(DashboardCreators.SetFormErrorMessages(addEntityForm.id, error.errors));

			}
			finally {
				dispatch(DashboardCreators.SetFormLoading(addEntityForm.id, false));
			}
		}
	}
}

export function onAddBranch(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const dashboardState = getState().Dashboard;

		const addBranchForm = dashboardState.addBranchForm;

		dispatch(DashboardCreators.ClearFormError(addBranchForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(DashboardCreators.FormChange(addBranchForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(addBranchForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(DashboardCreators.SetFormError(addBranchForm.id, true));
			dispatch(DashboardCreators.SetFormErrorMessages(addBranchForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const branchToAdd: ScottlandBranchRequest = {

				name: addBranchForm.fields.name.value as string
			}

			dispatch(DashboardCreators.SetFormLoading(addBranchForm.id, true));

			try {

				const createdBranch = await ScottlandApi.CreateBranch(branchToAdd);
				
				dispatch(DashboardCreators.CancelFormEdits(addBranchForm.id));
				dispatch(DashboardCreators.SetFormOpen(addBranchForm.id, false));
				dispatch(DashboardCreators.SetFormOpen(dashboardState.addAccountForm.id, false));
				dispatch(DashboardCreators.AddBranch(createdBranch));

			}
			catch (e) {
				const error = e as ScottlandApiError;

				dispatch(DashboardCreators.SetFormError(addBranchForm.id, true));
				dispatch(DashboardCreators.SetFormErrorMessages(addBranchForm.id, error.errors));

			}
			finally {
				dispatch(DashboardCreators.SetFormLoading(addBranchForm.id, false));
			}
		}
	}
}

export function onDeleteBranch(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const dashboardState = getState().Dashboard;
		const deleteBranchForm = dashboardState.deleteBranchForm;
		const branchIdToDelete = deleteBranchForm.fields.id.value;

		if(branchIdToDelete === null || branchIdToDelete === undefined) {
			throw TypeError('We do not have a branch to delete')
		}

		dispatch(DashboardCreators.ClearFormError(deleteBranchForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(DashboardCreators.FormChange(deleteBranchForm.id, targetField, fieldValue, !fieldIsValid));
		}

		let formIsValid: boolean = ValidateValidationForm(deleteBranchForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(DashboardCreators.SetFormError(deleteBranchForm.id, true));
			dispatch(DashboardCreators.SetFormErrorMessages(deleteBranchForm.id, []));
		}
		else {

			dispatch(DashboardCreators.SetFormLoading(deleteBranchForm.id, true));

			try {

				const updatedEntity = await ScottlandApi.DeleteBranch(branchIdToDelete.toString());
				
				dispatch(DashboardCreators.FormChange(deleteBranchForm.id, deleteBranchForm.fields.id.id, null, false))
				dispatch(DashboardCreators.RemoveBranch(branchIdToDelete));

			}
			catch (e) {
				const error = e as ScottlandApiError;

				dispatch(DashboardCreators.SetFormError(deleteBranchForm.id, true));
				dispatch(DashboardCreators.SetFormErrorMessages(deleteBranchForm.id, error.errors));

			}
			finally {
				dispatch(DashboardCreators.SetFormLoading(deleteBranchForm.id, false));
			}
		}
	}
}

export function onEditBranch(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const dashboardState = getState().Dashboard;

		const editBranchForm = dashboardState.editBranchForm;

		dispatch(DashboardCreators.ClearFormError(editBranchForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(DashboardCreators.FormChange(editBranchForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(editBranchForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(DashboardCreators.SetFormError(editBranchForm.id, true));
			dispatch(DashboardCreators.SetFormErrorMessages(editBranchForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const branchToEdit: ScottlandBranchRequest = {

				name: editBranchForm.fields.name.value as string
			}

			dispatch(DashboardCreators.SetFormLoading(editBranchForm.id, true));

			try {

				const editedBranch = await ScottlandApi.EditBranch(editBranchForm.fields.id.value as string, branchToEdit);
				
				dispatch(DashboardCreators.CancelFormEdits(editBranchForm.id));

				dispatch(DashboardCreators.EditBranch(editBranchForm.fields.id.value as number, editedBranch));

			}
			catch (e) {
				const error = e as ScottlandApiError;

				dispatch(DashboardCreators.SetFormError(editBranchForm.id, true));
				dispatch(DashboardCreators.SetFormErrorMessages(editBranchForm.id, error.errors));

			}
			finally {
				dispatch(DashboardCreators.SetFormLoading(editBranchForm.id, false));
			}
		}
	}
}


