import { FormCreators } from "./interfaces/common/FormCreators";

export const FormChange: FormCreators.FormChangeCreator =
	(type: string, formId: string, field: string, value: string, error: boolean): FormCreators.FormChange => (
		{
			type,
			formId,
			field,
			value,
			error
		}
	);

export const SetFormLoading: FormCreators.SetFormLoadingCreator =
	(type: string, formId: string, loading: boolean): FormCreators.SetFormLoading => (
		{
			type,
			formId,
			loading
		}
	)

export const SetFormError: FormCreators.SetFormErrorCreator =
	(type: string, formId: string, error: boolean): FormCreators.SetFormError => (
		{
			type,
			error,
			formId
		}
	)


export const SetFormErrorMessages: FormCreators.SetFormErrorMessagesCreator =
	(type: string, formId: string, errorMessages: string[]): FormCreators.SetFormErrorMessages => (
		{
			type,
			formId,
			errorMessages

		}
	)

export const ClearFormError: FormCreators.ClearFormErrorCreator =
	(type: string, formId: string): FormCreators.ClearFormError => (
		{
			type,
			formId
		}
	)

export const SyncFormOriginalValues: FormCreators.SyncFormOriginalValuesCreator =
	(type: string, formId: string): FormCreators.SyncFormOriginalValues => (
		{
			type,
			formId
		}
	)


export const CancelFormEdits: FormCreators.CancelFormEditsCreator =
	(type: string, formId: string): FormCreators.CancelFormEdits => (
		{
			type,
			formId
		}
	)

export const SetFormOpen: FormCreators.SetFormOpenCreator =
	(type: string, formId: string, open: boolean): FormCreators.SetFormOpenAction => (
		{
			type,
			formId,
			open
		}
	)


export const ClearFormFieldErrors: FormCreators.ClearFormFieldErrorsCreator =
	(type: string, formId: string): FormCreators.ClearFormFieldErrorsAction => (
		{
			type,
			formId
		}
	)
