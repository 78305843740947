import * as React from 'react';
import { Segment, Header, Icon, Button, Container, Form, Message, Item, Label } from 'semantic-ui-react';
import { MembersViewProps } from './interfaces/MembersViewProps';
import { MembersListViewProps } from './interfaces/MembersListViewProps';
import { Link } from 'react-router-dom';
import MemberEditView from './MemberEditView';

const MembersListView = (props: MembersListViewProps) => (
  <Container>
    <Item.Group>
      {props.members.map(member => (
        <Item>
          <Item.Content>
            <Item.Meta>
              <strong>{member.name}</strong>
              <span>{member.email}</span>
              <span>
                <Icon name='university' />
                {member.branch.value}
              </span>
              {props.editMemberForm.fields.id.value !== member.id &&
                <>
                  <Button
                    icon='delete'
                    size='mini'
                    floated='right'
                    color='red'
                    compact
                    inverted
                    className='member-button'
                    onClick={() => props.onMemberDelete(member.id)}
                  />
                  <Button
                    icon='edit'
                    size='mini'
                    floated='right'
                    color='olive'
                    inverted
                    compact
                    className='member-button'
                    onClick={() => props.onMemberEdit(member.id)}
                  />
                </>
              }
            </Item.Meta>
            <Item.Description>
              {props.editMemberForm.fields.id.value === member.id &&
                <MemberEditView
                  memberEditForm={props.editMemberForm}
                  onSubmit={props.onMemberEditFormSubmit}
                  onCancel={props.onMemberEditFormCancel}
                  onFormChange={props.onMemberEditFormChange}
                />
              }
              {props.editMemberForm.fields.id.value !== member.id &&
                <Label.Group size='tiny'>
                  {member.entities.map((entity) => (
                    <Label
                      as={Link}
                      to={`/entity/${entity.key}`}
                    >
                      <Icon name='book' />
                      {entity.value}
                    </Label>
                  ))}
                </Label.Group>
              }
            </Item.Description>
          </Item.Content>
        </Item>
      ))}
    </Item.Group>
  </Container>
)

export default MembersListView