import * as React from 'react';
import { Button, Header, Icon, Segment, Container, Form, Statistic, Message } from 'semantic-ui-react';
import { RatePlanLayoutViewProps } from './interfaces/RatePlanLayoutViewProps';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const RatePlanLayoutView = (props: RatePlanLayoutViewProps) => (

  <Container basic padded very>
    <Header as='h3' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
      <Icon name='calculator' />
      Rate Plans
    </Header>
    {props.addRatePlanForm.open === false &&
      <Button
        size='mini'
        icon='plus'
        color='olive'
        floated='right'
        onClick={(e: any) => props.onSetFormOpen(true)}
        loading={props.addRatePlanForm.loading}
        disabled={props.addRatePlanForm.loading}
        style={{ float: 'right' }}
      />
    }
    <hr className='scottland-divider' style={{ clear: 'both', marginBottom: '1em', marginTop: '.5em' }} />
    {(props.addRatePlanForm.open) &&
      <Form
        size='tiny'
        error={props.addRatePlanForm.error}
        onSubmit={props.onFormSubmit}
        style={{ paddingLeft: '3em', paddingRight: '3em' }}
      >
        <Form.Group>
          <Form.Input
            id={props.addRatePlanForm.fields.name.id}
            name={props.addRatePlanForm.fields.name.id}
            label={props.addRatePlanForm.fields.name.label}
            error={props.addRatePlanForm.fields.name.error}
            placeholder={props.addRatePlanForm.fields.name.placeholder}
            value={props.addRatePlanForm.fields.name.value as any}
            onChange={(e) => props.onFormChange(e, null)}
            width='5'
          />

          <Form.Field width='11' style={{ marginTop: '2em' }}>
            <Statistic.Group
              size='mini'
              widths='2'
            >
              <Statistic>
                <Statistic.Value>
                  <Form.Input
                    icon
                    error={props.addRatePlanForm.fields.initialDepositRate.error}
                    transparent
                    className='rate-plan-percent-input'
                  >
                    <MaskedInput
                      id={props.addRatePlanForm.fields.initialDepositRate.id}
                      name={props.addRatePlanForm.fields.initialDepositRate.id}
                      value={props.addRatePlanForm.fields.initialDepositRate.value as number}
                      mask={createNumberMask({
                        prefix: '',
                        suffix: '',
                        allowDecimal: true,
                        decimalLimit: 2,
                        integerLimit: 2
                      })}
                      pipe={(conformedValue: string, config: any) => (conformedValue === '0' ? '' : conformedValue)}
                      onChange={(e) => props.onFormChange(e, null)}
                      guide={false}
                    />
                    <Icon name='percent' size='small' />
                  </Form.Input>
                </Statistic.Value>
                <Statistic.Label style={{ fontWeight: 'normal' }}>
                  <Icon
                    name='money bill alternate outline'
                    color='olive'
                  />
                  <i>Initial Deposit Rate</i>
                </Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  <Form.Input
                    icon
                    error={props.addRatePlanForm.fields.initialAdvanceRate.error}
                    transparent
                    className='rate-plan-percent-input'
                  >
                    <MaskedInput
                      id={props.addRatePlanForm.fields.initialAdvanceRate.id}
                      name={props.addRatePlanForm.fields.initialAdvanceRate.id}
                      value={props.addRatePlanForm.fields.initialAdvanceRate.value as number}
                      mask={createNumberMask({
                        prefix: '',
                        suffix: '',
                        allowDecimal: true,
                        decimalLimit: 2,
                        integerLimit: 2
                      })}
                      pipe={(conformedValue: string, config: any) => (conformedValue === '0' ? '' : conformedValue)}
                      onChange={(e) => props.onFormChange(e, null)}
                      guide={false}
                    />
                    <Icon name='percent' size='small' />
                  </Form.Input>
                </Statistic.Value>
                <Statistic.Label style={{ fontWeight: 'normal' }}>
                  <Icon
                    name='money bill alternate outline'
                    color='red'
                  />
                  <i>Initial Advance Rate</i>
                </Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Form.Field>

        </Form.Group>
        <Form.TextArea
          id={props.addRatePlanForm.fields.description.id}
          name={props.addRatePlanForm.fields.description.id}
          label={props.addRatePlanForm.fields.description.label}
          error={props.addRatePlanForm.fields.description.error}
          placeholder={props.addRatePlanForm.fields.description.placeholder}
          value={props.addRatePlanForm.fields.description.value as any}
          onChange={(e) => props.onFormChange(e, null)}
        />
        <Message
          error
          icon='exclamation'
          header='Oops'
          list={props.addRatePlanForm.errorMessages}
        />
        <Form.Field>
          <Button
            size='mini'
            color='olive'
            icon='plus circle'
            labelPosition='right'
            content='Add Rate Plan'
            floated='right'
            type='submit'
            loading={props.addRatePlanForm.loading}
            disabled={props.addRatePlanForm.loading}
            onClick={props.onFormSubmit}
          />
          <Button
            size='mini'
            icon='undo'
            labelPosition='right'
            content='Close'
            floated='right'
            onClick={(e: any) => props.onSetFormOpen(false)}
            disabled={props.addRatePlanForm.loading}
          />
        </Form.Field>
      </Form>
    }
    <div style={{ clear: 'both' }} />
    {props.children}
  </Container>
)

export default RatePlanLayoutView
