
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import StoreState from '../../redux/interfaces/StoreState';
import { LoginContainerProps } from './interfaces/LoginContainerProps';
import { onCheckAuthentication, onFormChange, onLogin } from './thunks/LoginThunks';
import { LoginViewProps } from './views/interfaces/LoginViewProps';
import LoginView from './views/LoginView';
import LoginRedirectView from './views/LoginRedirect';

export class LoginContainer extends React.Component<LoginContainerProps> {

  public componentWillMount() {
    this.props.onCheckAuthentication();
  }

  public render(): React.ReactNode {

    if(this.props.App.authenticationLoading == true || 
      this.props.App.authenticationToken != null) {
      return React.createElement(LoginRedirectView);
    }

    const loginViewProps: LoginViewProps = {
      form: this.props.Login.loginForm,
      onFormChange: (e :any, data :any) => this.props.onFormChange(
        this.props.Login.loginForm.id, e, data
      ),
      onFormSubmit: this.props.onLogin
    };

    return React.createElement(LoginView, loginViewProps)
  }
}

const mapStateToProps = (state: StoreState) => ({ App: state.App, Login: state.Login });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ onLogin, onFormChange, onCheckAuthentication }, dispatch);

export default withRouter(<any>connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer));


