import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";
import { ScottlandUserRequest } from "../../../lib/interfaces/ScottlandUserRequest";
import * as ScottlandApi from '../../../lib/ScottlandApi';
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import * as MembersCreators from '../../../redux/creators/MembersCreators';
import { FormField } from "../../../redux/interfaces/common/FormField";
import { FormState } from "../../../redux/interfaces/common/FormState";
import StoreState from "../../../redux/interfaces/StoreState";


export function onLoad(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>) => {

    dispatch(MembersCreators.ResetToInitialState())
    dispatch(MembersCreators.SetLoading(true));
    dispatch(MembersCreators.SetError(false));

    try {

      const membersPromise = ScottlandApi.GetUserDetails();
      const entitiesPromise = ScottlandApi.GetEntities();
      const branchesPromise = ScottlandApi.GetBranches();

      await Promise.all([membersPromise, entitiesPromise, branchesPromise]);

      dispatch(MembersCreators.SetMembers(await membersPromise));
      dispatch(MembersCreators.SetEntities(await entitiesPromise));
      dispatch(MembersCreators.SetBranches(await branchesPromise));

    }
    catch {
      dispatch(MembersCreators.SetError(true));
    }
    finally {
      dispatch(MembersCreators.SetLoading(false));
    }
  }
}

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    let targetField: (string | null | undefined) = e.target.name;
    let fieldValue: (string | null | undefined) = e.target.value;

    if (targetField === null || targetField === undefined || targetField === '') {
      targetField = data.name;

      if (data.type === 'checkbox') {
        fieldValue = data.checked;
      }
      else {
        fieldValue = data.value;
      }
    }

    if (targetField === null ||
      targetField === undefined) {
      throw new TypeError(`No target field sent to change handler: ${targetField}`);
    }

    const formState = (getState().Members as any)[formId as any] as FormState<any>;

    if (formState === null ||
      formState === undefined) {
      throw new TypeError(`Unknown form ${formId}`);
    }

    const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

    if (formFieldState === null ||
      formFieldState === undefined) {
      throw new TypeError(`Unknown form field ${targetField}`);
    }

    const fieldIsValid = Validate(fieldValue,
      formFieldState.validation.required,
      formFieldState.validation.validators);

    dispatch(MembersCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

  }
}

export function onCancelFormEdits(formId: string): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(MembersCreators.CancelFormEdits(formId));
  }
}

export function onSetFormOpen(formId: string, open: boolean): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {


    dispatch(MembersCreators.CancelFormEdits(formId));
    dispatch(MembersCreators.SetFormOpen(formId, open));
  }
}

export function onSetEditMember(memberId: number | null): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(MembersCreators.SetEditMember(memberId));
  }
}

export function onAddMember(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const membersState = getState().Members;

    const addMemberForm = membersState.addMemberForm;

    dispatch(MembersCreators.ClearFormError(addMemberForm.id, false));

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(MembersCreators.FormChange(addMemberForm.id, targetField, fieldValue, !fieldIsValid));
    }

    const formIsValid: boolean = ValidateValidationForm(addMemberForm.fields, formChangeCallback);

    if (formIsValid === false) {
      dispatch(MembersCreators.SetFormError(addMemberForm.id, true));
      dispatch(MembersCreators.SetFormErrorMessages(addMemberForm.id, ['Please fill out all the required fields.']));
    }
    else {

      const entityToAdd: ScottlandUserRequest = {
        email: addMemberForm.fields.email.value as string,
        firstName: addMemberForm.fields.firstName.value as string,
        lastName: addMemberForm.fields.lastName.value as string,
        middleName: addMemberForm.fields.middleName.value as string,
        branch: addMemberForm.fields.branch.value as number,
        entities: addMemberForm.fields.entities.value !== null &&
          addMemberForm.fields.entities.value !== undefined &&
          (addMemberForm.fields.entities.value as unknown as string[]).length > 0 ?
          (addMemberForm.fields.entities.value as unknown as string[]).map(entityId => parseInt(entityId, 10)) : []
      }

      dispatch(MembersCreators.SetFormLoading(addMemberForm.id, true));

      try {

        const createdUsed = await ScottlandApi.CreateUser(entityToAdd);

        dispatch(MembersCreators.CancelFormEdits(addMemberForm.id));
        dispatch(MembersCreators.SetFormOpen(addMemberForm.id, false));

        dispatch(MembersCreators.AddMember(createdUsed));

      }
      catch (e) {
        const error = e as ScottlandApiError;

        dispatch(MembersCreators.SetFormError(addMemberForm.id, true));
        dispatch(MembersCreators.SetFormErrorMessages(addMemberForm.id, error.errors));

      }
      finally {
        dispatch(MembersCreators.SetFormLoading(addMemberForm.id, false));
      }
    }
  }
}

export function onEditMember(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const membersState = getState().Members;
    const editMemberForm = membersState.editMemberForm;
    const userId = editMemberForm.fields.id.value as number;

    dispatch(MembersCreators.ClearFormError(editMemberForm.id, false));

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(MembersCreators.FormChange(editMemberForm.id, targetField, fieldValue, !fieldIsValid));
    }

    const formIsValid: boolean = ValidateValidationForm(editMemberForm.fields, formChangeCallback);

    if (formIsValid === false) {
      dispatch(MembersCreators.SetFormError(editMemberForm.id, true));
      dispatch(MembersCreators.SetFormErrorMessages(editMemberForm.id, ['Please fill out all the required fields.']));
    }
    else {

      const editRequest: ScottlandUserRequest = {
        email: editMemberForm.fields.email.value as string,
        firstName: editMemberForm.fields.firstName.value as string,
        lastName: editMemberForm.fields.lastName.value as string,
        middleName: editMemberForm.fields.middleName.value as string,
        branch: editMemberForm.fields.branch.value as number,
        entities: editMemberForm.fields.entities.value !== null &&
          editMemberForm.fields.entities.value !== undefined &&
          (editMemberForm.fields.entities.value as unknown as string[]).length > 0 ?
          (editMemberForm.fields.entities.value as unknown as string[]).map(entityId => parseInt(entityId, 10)) : []
      }

      dispatch(MembersCreators.SetFormLoading(editMemberForm.id, true));

      try {

        const updatedMember = await ScottlandApi.UpdateUser(userId.toString(), editRequest);

        dispatch(MembersCreators.UpdateMember(updatedMember))
        dispatch(MembersCreators.SetEditMember(null));

      }
      catch (e) {
        const error = e as ScottlandApiError;

        dispatch(MembersCreators.SetFormError(editMemberForm.id, true));
        dispatch(MembersCreators.SetFormErrorMessages(editMemberForm.id, error.errors));

      }
      finally {
        dispatch(MembersCreators.SetFormLoading(editMemberForm.id, false));
      }
    }
  }
}

export function onDeleteMember(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    const membersState = getState().Members;
    const deleteMemberForm = membersState.deleteMemberForm;
    const memberId = deleteMemberForm.fields.id.value as number;

    dispatch(MembersCreators.ClearFormError(deleteMemberForm.id, false));

    const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
      dispatch(MembersCreators.FormChange(deleteMemberForm.id, targetField, fieldValue, !fieldIsValid));
    }

    let formIsValid: boolean = ValidateValidationForm(deleteMemberForm.fields, formChangeCallback);

    if (formIsValid === false) {
      dispatch(MembersCreators.SetFormError(deleteMemberForm.id, true));
      dispatch(MembersCreators.SetFormErrorMessages(deleteMemberForm.id, []));
    }
    else {

      dispatch(MembersCreators.SetFormLoading(deleteMemberForm.id, true));

      try {

        const deleted = await ScottlandApi.DeleteUser(memberId.toString());

        dispatch(MembersCreators.RemoveMember(memberId));
        dispatch(MembersCreators.CancelFormEdits(deleteMemberForm.id))

      }
      catch (e) {
        const error = e as ScottlandApiError;

        dispatch(MembersCreators.SetFormError(deleteMemberForm.id, true));
        dispatch(MembersCreators.SetFormErrorMessages(deleteMemberForm.id, error.errors));

      }
      finally {
        dispatch(MembersCreators.SetFormLoading(deleteMemberForm.id, false));
      }
    }
  }
}