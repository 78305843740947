

import { Reducer } from 'redux';
import { DashboardState } from './interfaces/DashboardState';
import * as DashboardActions from './creators/interfaces/DashboardCreators';
import { DashboardActionTypes } from './creators/DashboardCreators';
import { handleSetFormLoading, handleSetFormError, handleSetFormErrorMessages, handleClearFormError, handleSetFormOpen, handleCancelFormEdits, handleFormChange } from './FormReducerHandlers';

const initialState: DashboardState = {
  loading: false,
  error: false,
  errorMessages: [],
  tableView: false,
  showInactiveEntities: false,
  showAllEntities: false,
  dashboardData: null,
  pendingTransactions: [],
  entitiesTableSettingsForm: {
    id: 'entitiesTableSettingsForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      activePage: {
        id: 'activePage',
        label: 'Active Page',
        placeholder: 'Active Page',
        value: 1,
        originalValue: 1,
        error: false,
        touched: false,
        validation: { required: true },
      },
      itemsPerPage: {
        id: 'itemsPerPage',
        label: 'Items per page',
        placeholder: 'Items per page',
        value: '25',
        originalValue: '25',
        error: false,
        touched: false,
        validation: { required: true },
        options: [
          { key: '10', value: '10', text: '10' },
          { key: '25', value: '25', text: '25' },
          { key: '50', value: '50', text: '50' },
          { key: '100', value: '100', text: '100' }
        ]
      },
      sortColumn: {
        id: 'sortColumn',
        label: 'Sort Column',
        placeholder: 'Sort Column',
        value: 'name',
        originalValue: 'name',
        error: false,
        touched: false,
        validation: { required: true }
      },
      sortDirection: {
        id: 'sortDirection',
        label: 'Sort Direction',
        placeholder: 'Sort Direction',
        value: 'ascending',
        originalValue: 'ascending',
        error: false,
        touched: false,
        validation: { required: true }
      }
    }
  },
  branchTableSettingsForm: {
    id: 'branchTableSettingsForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      activePage: {
        id: 'activePage',
        label: 'Active Page',
        placeholder: 'Active Page',
        value: 1,
        originalValue: 1,
        error: false,
        touched: false,
        validation: { required: true },
      },
      itemsPerPage: {
        id: 'itemsPerPage',
        label: 'Items per page',
        placeholder: 'Items per page',
        value: '25',
        originalValue: '25',
        error: false,
        touched: false,
        validation: { required: true },
        options: [
          { key: '10', value: '10', text: '10' },
          { key: '25', value: '25', text: '25' },
          { key: '50', value: '50', text: '50' },
          { key: '100', value: '100', text: '100' }
        ]
      },
      sortColumn: {
        id: 'sortColumn',
        label: 'Sort Column',
        placeholder: 'Sort Column',
        value: 'name',
        originalValue: 'name',
        error: false,
        touched: false,
        validation: { required: true }
      },
      sortDirection: {
        id: 'sortDirection',
        label: 'Sort Direction',
        placeholder: 'Sort Direction',
        value: 'ascending',
        originalValue: 'ascending',
        error: false,
        touched: false,
        validation: { required: true }
      }
    }
  },
  transactionsTableSettingsForm: {
    id: 'transactionsTableSettingsForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      activePage: {
        id: 'activePage',
        label: 'Active Page',
        placeholder: 'Active Page',
        value: 1,
        originalValue: 1,
        error: false,
        touched: false,
        validation: { required: true },
      },
      itemsPerPage: {
        id: 'itemsPerPage',
        label: 'Items per page',
        placeholder: 'Items per page',
        value: '25',
        originalValue: '25',
        error: false,
        touched: false,
        validation: { required: true },
        options: [
          { key: '10', value: '10', text: '10' },
          { key: '25', value: '25', text: '25' },
          { key: '50', value: '50', text: '50' },
          { key: '100', value: '100', text: '100' }
        ]
      },
      sortColumn: {
        id: 'sortColumn',
        label: 'Sort Column',
        placeholder: 'Sort Column',
        value: 'name',
        originalValue: 'name',
        error: false,
        touched: false,
        validation: { required: true }
      },
      sortDirection: {
        id: 'sortDirection',
        label: 'Sort Direction',
        placeholder: 'Sort Direction',
        value: 'ascending',
        originalValue: 'ascending',
        error: false,
        touched: false,
        validation: { required: true }
      }
    }
  },
  addAccountForm: {
    id: 'addAccountForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {}
  },
  addBranchForm: {
    id: 'addBranchForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      name: {
        id: 'name',
        label: 'Name',
        placeholder: 'Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  },
  editBranchForm: {
    id: 'editBranchForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      id: {
        id: 'id',
        label: 'id',
        placeholder: 'id',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      name: {
        id: 'name',
        label: 'Branch Name',
        placeholder: 'Branch Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  },
  deleteBranchForm: {
    id: 'deleteBranchForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      id: {
        id: 'id',
        label: 'id',
        placeholder: 'id',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  },
  addEntityForm: {
    id: 'addEntityForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      name: {
        id: 'name',
        label: 'Name',
        placeholder: 'Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      branch: {
        id: 'branch',
        label: 'Branch',
        placeholder: 'Select a branch...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      members: {
        id: 'members',
        label: 'Members',
        placeholder: 'Select one or more members...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      establishedAt: {
        id: 'establishedAt',
        label: 'Established',
        placeholder: 'Established',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      ratePlan: {
        id: 'ratePlan',
        label: 'Rate Plan',
        placeholder: 'Select a rate plan...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      balanceForwardPrincipal: {
        id: 'balanceForwardPrincipal',
        label: 'Balance Forward Principal',
        placeholder: 'Balance Forward Principal',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      balanceForwardInterest: {
        id: 'balanceForwardInterest',
        label: 'Balance Forward Interest',
        placeholder: 'Balance Forward Interest',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      active: {
        id: 'active',
        label: 'Archived',
        placeholder: 'Archived',
        value: true as any,
        originalValue: true as any,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  }
};

const handleResetToInitialState = (state: DashboardState, action: DashboardActions.DashboardAction): DashboardState => {
  return {
    ...initialState,
  };
}

const handleSetLoading = (state: DashboardState, action: DashboardActions.SetLoadingAction): DashboardState => {
  return {
    ...state,
    loading: action.loading
  };
}

const handleSetError = (state: DashboardState, action: DashboardActions.SetErrorAction): DashboardState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: DashboardState, action: DashboardActions.SetErrorMessagesAction): DashboardState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handleAddBranch = (state: DashboardState, action: DashboardActions.AddBranchAction): DashboardState => {

  return {
    ...state,
    dashboardData: {
      ...state.dashboardData!,
      branches: state.dashboardData!.branches!.concat(action.branchToAdd)
    },
    addEntityForm: {
      ...state.addEntityForm,
      fields: {
        ...state.addEntityForm.fields,
        branch: {
          ...state.addEntityForm.fields.branch,
          options: state.addEntityForm.fields.branch.options!.concat(
            {
              key: action.branchToAdd.id as unknown as string,
              value: action.branchToAdd.id as unknown as string,
              text: action.branchToAdd.name
            }
          )
        }
      }
    }
  }
}

const handleRemoveBranch =  (state: DashboardState, action: DashboardActions.RemoveBranchAction): DashboardState => {

  return {
    ...state,
    dashboardData: {
      ...state.dashboardData!,
      branches: state.dashboardData!.branches!.filter(branch => branch.id !== action.branchIdToRemove )
    },
    addEntityForm: {
      ...state.addEntityForm,
      fields: {
        ...state.addEntityForm.fields,
        branch: {
          ...state.addEntityForm.fields.branch,
          options: state.addEntityForm.fields.branch.options!.filter(branch => (branch.value as unknown as number) !== action.branchIdToRemove )
        }
      }
    }
  }
}

const handleEditBranch=  (state: DashboardState, action: DashboardActions.EditBranchAction): DashboardState => {
 
  return {
    ...state,
    dashboardData: {
      ...state.dashboardData!,
      branches: state.dashboardData!.branches!.map( branch => {

        if(branch.id === action.branchIdToEdit) {
          branch.name = action.branch.name;
        }

        return branch;
      })
    }
  }
}

const handleSetDashboardData = (state: DashboardState, action: DashboardActions.SetDashboardDataAction): DashboardState => {
  return {
    ...state,
    dashboardData: action.dashboardData,
    addEntityForm: {
      ...state.addEntityForm,
      fields: {
        ...state.addEntityForm.fields,
        branch: {
          ...state.addEntityForm.fields.branch,
          options: action.dashboardData.branches !== null ?
            action.dashboardData.branches.map(branch => (
              {
                key: branch.id as unknown as string,
                value: branch.id as unknown as string,
                text: branch.name
              }
            )) : []
        },
        ratePlan: {
          ...state.addEntityForm.fields.ratePlan,
          options: action.dashboardData.ratePlans !== null ?
            action.dashboardData.ratePlans.map(ratePlan => (
              {
                key: ratePlan.id as unknown as string,
                value: ratePlan.id as unknown as string,
                text: ratePlan.name
              }
            )) : []
        },
        members: {
          ...state.addEntityForm.fields.members,
          options: action.dashboardData.users.map(user => ({
            key: user.id as unknown as string,
            value: user.id as unknown as string,
            text: user.name
          }))
        }
      }
    }
  };
}

const handleSetPendingTransactions = (state: DashboardState, action: DashboardActions.SetPendingTransactionsAction): DashboardState => {
  return {
    ...state,
    pendingTransactions: action.pendingTransactions
  };
}

const handleToggleTableView = (state: DashboardState, action: DashboardActions.DashboardAction): DashboardState => {
  return {
    ...state,
    tableView: !state.tableView
  };
}

const handleToggleShowInactiveEntities = (state: DashboardState, action: DashboardActions.DashboardAction): DashboardState => {
  return {
    ...state,
    showInactiveEntities: !state.showInactiveEntities
  };
}

const handleToggleShowAllEntities = (state: DashboardState, action: DashboardActions.DashboardAction): DashboardState => {
  return {
    ...state,
    showAllEntities: !state.showAllEntities
  };
}

const handlers: { [x: string]: (state: DashboardState, action: DashboardActions.DashboardAction) => DashboardState } =
  {
    [DashboardActionTypes.ResetToInitialState]: handleResetToInitialState,
    [DashboardActionTypes.SetLoading]: handleSetLoading,
    [DashboardActionTypes.SetError]: handleSetError,
    [DashboardActionTypes.SetErrorMessages]: handleSetErrorMessages,
    [DashboardActionTypes.SetFormLoading]: handleSetFormLoading,
    [DashboardActionTypes.SetFormError]: handleSetFormError,
    [DashboardActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
    [DashboardActionTypes.ClearFormError]: handleClearFormError,
    [DashboardActionTypes.SetFormOpen]: handleSetFormOpen,
    [DashboardActionTypes.CancelFormEdits]: handleCancelFormEdits,
    [DashboardActionTypes.FormChange]: handleFormChange,
    [DashboardActionTypes.SetDashboardData]: handleSetDashboardData,
    [DashboardActionTypes.SetPendingTransactions]: handleSetPendingTransactions,
    [DashboardActionTypes.ToggleTableView]: handleToggleTableView,
    [DashboardActionTypes.ToggleShowInactiveEntities]: handleToggleShowInactiveEntities,
    [DashboardActionTypes.ToggleShowAllEntities]: handleToggleShowAllEntities,
    [DashboardActionTypes.AddBranch]: handleAddBranch,
    [DashboardActionTypes.RemoveBranch]: handleRemoveBranch,
    [DashboardActionTypes.EditBranch]: handleEditBranch
  } as any

const DashboardReducer: Reducer<DashboardState, DashboardActions.DashboardAction> = (state: DashboardState | undefined, action: DashboardActions.DashboardAction): DashboardState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default DashboardReducer