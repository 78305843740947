import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";
import * as ScottlandApi from '../../../lib/ScottlandApi';
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import * as AuthenticationService from '../../../lib/Authentication/AuthenticationService';
import * as EntityReportCreators from '../../../redux/creators/EntityReportCreators';
import { FormField } from "../../../redux/interfaces/common/FormField";
import { FormState } from "../../../redux/interfaces/common/FormState";
import StoreState from "../../../redux/interfaces/StoreState";
import moment from "moment";
import Config from "../../../config/Config";

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		let targetField: (string | null | undefined) = e.target.name;
		let fieldValue: (string | null | undefined) = e.target.value;

		if (targetField === null || targetField === undefined || targetField === '') {
			targetField = data.name;

			if (data.type === 'checkbox') {
				fieldValue = data.checked;
			}
			else {
				fieldValue = data.value;
			}
		}

		if (targetField === null ||
			targetField === undefined) {
			throw new TypeError(`No target field sent to change handler: ${targetField}`);
		}

		const formState = (getState().EntityReport as any)[formId as any] as FormState<any>;

		if (formState === null ||
			formState === undefined) {
			throw new TypeError(`Unknown form ${formId}`);
		}

		const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

		if (formFieldState === null ||
			formFieldState === undefined) {
			throw new TypeError(`Unknown form field ${targetField}`);
		}

		const fieldIsValid = Validate(fieldValue,
			formFieldState.validation.required,
			formFieldState.validation.validators);

		dispatch(EntityReportCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

	}
}

export function onCancelFormEdits(formId: string): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(EntityReportCreators.CancelFormEdits(formId));
	}
}

export function onSetFormOpen(formId: string, open: boolean): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {


		dispatch(EntityReportCreators.CancelFormEdits(formId));
		dispatch(EntityReportCreators.SetFormOpen(formId, open));
	}
}


export function onDownloadReportFile(reportFileDownloadRef: React.RefObject<HTMLFormElement>): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const entityReportState = getState().EntityReport;

		const reportSettingsForm = entityReportState.reportSettingsForm;
		const startDateMoment = moment(reportSettingsForm.fields.startDate.value as string);
		const endDateMoment = moment(reportSettingsForm.fields.endDate.value as string);
		const includeAnnualCompounds = reportSettingsForm.fields.includeAnnualCompounds.value as any === true;
		const includeEphemeral= reportSettingsForm.fields.includeEphemeral.value as any === true;

		dispatch(EntityReportCreators.SetFormError(reportSettingsForm.id, false));

		
		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(EntityReportCreators.FormChange(reportSettingsForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(reportSettingsForm.fields, formChangeCallback);

		if (formIsValid === false || startDateMoment.isValid() === false || endDateMoment.isValid() === false) {
			dispatch(EntityReportCreators.SetFormError(reportSettingsForm.id, true));
			dispatch(EntityReportCreators.SetFormErrorMessages(reportSettingsForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const authToken = await (AuthenticationService.GetAccessToken() as any);

			reportFileDownloadRef!.current!.action = ScottlandApi.BuildBalanceAndTransactionReportFileUrl(
				startDateMoment.unix(),
				endDateMoment.unix(),
				includeEphemeral,
				includeAnnualCompounds
			);

			(reportFileDownloadRef!.current!.children!.namedItem('clientId')! as any).value = Config.oktaClientId;
			(reportFileDownloadRef!.current!.children!.namedItem('token')! as any).value = authToken.accessToken;

			reportFileDownloadRef!.current!.submit();

			reportFileDownloadRef!.current!.action = '';
			(reportFileDownloadRef!.current!.children!.namedItem('clientId')! as any).value = '';
			(reportFileDownloadRef!.current!.children!.namedItem('token')! as any).value = '';
		}
	}
}