import React, { FunctionComponent } from "react";
import { DashboardViewProps } from "./interfaces/DashboardViewProps";
import { Segment, Header, Icon, Dropdown, Grid, Breadcrumb, Button, Modal, Message, Input } from "semantic-ui-react";
import { Link } from 'react-router-dom'

const DashboardView: FunctionComponent<DashboardViewProps> =
  (props: DashboardViewProps) => (
    <>
      <Segment basic>
        <Grid columns='2'>
          <Grid.Column width='6'>
            {props.loading === false &&
              <Header as='h3' style={{ position: 'relative', top: '.75em' }}>
                {props.selectedBranch === undefined ?
                  <>
                    {props.administrativeMode === true ?
                      <>
                        {props.showAllEntities === true ?
                          <>
                            <Icon name='book' style={{ display: 'inline', paddingRight: '.25em' }} />
                            All Entities
                          </>
                          :
                          <>
                            <Icon name='university' style={{ display: 'inline', paddingRight: '.25em' }} />
                            Branches
                          </>
                        }

                      </>
                      :
                      <>
                        <Icon name='book' style={{ display: 'inline', paddingRight: '.25em' }} />
                        My Entities
                      </>
                    }
                  </>
                  :
                  <>
                    <Icon name='book' style={{ display: 'inline', paddingRight: '.25em' }} />
                    {props.selectedBranch.name}
                    <Breadcrumb>
                      <Breadcrumb.Divider icon='right angle' />
                      <Breadcrumb.Section>Entities</Breadcrumb.Section>
                      <Breadcrumb.Divider icon='right angle' />
                      <Breadcrumb.Section>
                        <Link className='scottland-link' to='/dashboard'>
                          Back to branches
                          </Link>
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </>
                }
              </Header>
            }
          </Grid.Column>
          <Grid.Column width='10' textAlign='right'>
            <Dropdown
              inline
              placeholder='Search for an entity....'
              loading={props.entityDropddownOptions === undefined}
              icon='search'
              search
              selection
              selectOnNavigation={false}
              selectOnBlur={false}
              options={props.entityDropddownOptions &&
                props.entityDropddownOptions.map(option => ({
                  key: option.key,
                  value: option.value,
                  text: option.text
                }))}
              onChange={(e, { value }) => props.push(`/entity/${value}`)}
              style={{
                width: '80%'
              }}
            />

            {props.administrativeMode == true &&

              <Button
                floated='right'
                icon={props.addAccountForm.open === true ? 'undo' : 'add'}
                color={props.addAccountForm.open === true ? undefined : 'olive'}
                style={{
                  height: '37px'
                }}
                onClick={props.onToggleAddAccount}
              />
            }
            {props.selectedBranch !== undefined &&
              props.administrativeMode == true &&
              <Button
                floated='right'
                icon='archive'
                basic
                color={props.showInactiveEntities === true ? 'olive' : undefined}
                onClick={props.onToggleShowInactiveEntities}
                style={{
                  height: '38px'
                }}
              />
            }
            <Button
              floated='right'
              icon='table'
              basic
              color={props.tableView === true ? 'olive' : undefined}
              onClick={props.onToggleTableView}
              style={{
                height: '38px'
              }}
            />
            {props.selectedBranch === undefined &&
              props.administrativeMode == true &&
              <Button
                floated='right'
                icon='list'
                basic
                color={props.showAllEntities === true ? 'olive' : undefined}
                onClick={props.onToggleShowAllEntities}
                style={{
                  height: '38px'
                }}
              />
            }
          </Grid.Column>
        </Grid>
        <hr className='scottland-divider' />
        <Segment basic>
          {props.children}
        </Segment>
      </Segment>
      <Modal
        basic
        size='small'
        closeIcon
        onClose={props.onDeleteBranchCancel}
        open={props.deleteBranchForm.fields.id.value !== null}
      >
        <Modal.Header>Confirm Branch Deletion</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Are you sure you wish to DELETE this branch?
        </Modal.Description>
        </Modal.Content>
        <Modal.Content>
          <Modal.Description>
            All record of this branch will be DELETED!
        </Modal.Description>
          <Modal.Description>
            <strong>This action is <i>not</i> reversible.</strong>
          </Modal.Description>
        </Modal.Content>
        {props.deleteBranchForm.error &&
          <Modal.Content>
            <Message
              error
              size='tiny'
              icon='exclamation'
              header='Oops.'
              list={props.deleteBranchForm.errorMessages}
              style={{
                marginTop: '1em'
              }}
            />
          </Modal.Content>
        }
        <Modal.Actions>
          <Button
            color='grey'
            labelPosition='right'
            icon='undo'
            content='Close'
            inverted
            size='tiny'
            onClick={props.onDeleteBranchCancel}
            disabled={props.deleteBranchForm.loading}
          />
          <Button
            color='red'
            labelPosition='right'
            icon='x'
            content='Delete Branch'
            inverted
            size='tiny'
            onClick={props.onDeleteBranch}
            loading={props.deleteBranchForm.loading}
            disabled={props.deleteBranchForm.loading}
          />
        </Modal.Actions>
      </Modal>
      <Modal
        basic
        size='small'
        closeIcon
        onClose={props.onEditBranchCancel}
        open={props.editBranchForm.fields.id.value !== null}
      >
        <Modal.Header>Edit Branch</Modal.Header>
        <Modal.Content>
          <Input
            id={props.editBranchForm.fields.name.id}
            name={props.editBranchForm.fields.name.id}
            value={props.editBranchForm.fields.name.value}
            error={props.editBranchForm.fields.name.error}
            placeholder={props.editBranchForm.fields.name.placeholder}
            label={props.editBranchForm.fields.name.label}
            onChange={ (e) => props.onEditBranchFormChange(e, null)}
            fluid
          />
        </Modal.Content>
        {props.editBranchForm.error &&
          <Modal.Content>
            <Message
              error
              size='tiny'
              icon='exclamation'
              header='Oops.'
              list={props.editBranchForm.errorMessages}
              style={{
                marginTop: '1em'
              }}
            />
          </Modal.Content>
        }
        <Modal.Actions>
          <Button
            color='grey'
            labelPosition='right'
            icon='undo'
            content='Close'
            inverted
            size='tiny'
            onClick={props.onEditBranchCancel}
            disabled={props.editBranchForm.loading}
          />
          <Button
            color='olive'
            labelPosition='right'
            icon='pencil'
            content='Save Edits'
            inverted
            size='tiny'
            onClick={props.onEditBranch}
            loading={props.editBranchForm.loading}
            disabled={props.editBranchForm.loading}
          />
        </Modal.Actions>
      </Modal>

    </>
  )

export default DashboardView