
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ScottlandUserDetailView } from '../../lib/interfaces/ScottlandUserDetailView';
import StoreState from '../../redux/interfaces/StoreState';
import DashboardLoadingView from '../Dashboard/views/DashboardLoadingView';
import { MembersContainerProps } from './interfaces/MembersContainerProps';
import { onAddMember, onCancelFormEdits, onDeleteMember, onEditMember, onFormChange, onLoad, onSetEditMember, onSetFormOpen } from './thunks/MembersThunks';
import { MembersListViewProps } from './views/interfaces/MembersListViewProps';
import { MembersViewProps } from './views/interfaces/MembersViewProps';
import MembersListView from './views/MembersListView';
import MembersView from './views/MembersView';

export class MembersContainer extends React.Component<MembersContainerProps> {


  public componentWillMount() {

    if (this.props.App.authenticationLoading === false &&
      (this.props.App.authenticationToken === null ||
        this.props.App.authenticationToken.claims.is_administrator === false)) {
      this.props.history.push('/404');
      return;
    }

    this.props.onLoad();
  }

  public render(): React.ReactNode {

    const filteredMembers = this.filterMembers();

    const memberListViewProps: MembersListViewProps = {
      members: filteredMembers,
      editMemberForm: this.props.Members.editMemberForm,
      onMemberEdit: (memberId: number) => this.props.onSetEditMember(memberId),
      onMemberEditFormSubmit: this.props.onEditMember,
      onMemberEditFormCancel: () => this.props.onSetEditMember(null),
      onMemberEditFormChange: (e: any, data: any) => this.props.onFormChange(this.props.Members.editMemberForm.id, e, data),
      onMemberDelete: (memberId: number) => this.props.onFormChange(
        this.props.Members.deleteMemberForm.id,
        { target: { name: this.props.Members.deleteMemberForm.fields.id.id, value: memberId } },
        null
      )
    }

    const membersListView = React.createElement(MembersListView, memberListViewProps);

    const memberViewProps: MembersViewProps = {
      loading: this.props.Members.loading,
      totalMemberCount: this.props.Members.members.length,
      filteredMemberCount: filteredMembers.length,
      addMemberForm: this.props.Members.addMemberForm,
      onDeleteMember: this.props.onDeleteMember,
      onDeleteMemberCancel: () => this.props.onFormChange(
        this.props.Members.deleteMemberForm.id,
        { target: { name: this.props.Members.deleteMemberForm.fields.id.id, value: null } },
        null
      ),
      deleteMemberForm :this.props.Members.deleteMemberForm,
      memberFilterForm: this.props.Members.memberFilterForm,
      onMemberFilterChange: (e: any, data: any) => this.props.onFormChange(this.props.Members.memberFilterForm.id, e, data),
      onFormCancel: () => this.props.onSetFormOpen(this.props.Members.addMemberForm.id, false),
      onFormSubmit: this.props.onAddMember,
      onFormChange: (e: any, data: any) => this.props.onFormChange(this.props.Members.addMemberForm.id, e, data),
      onAddMember: () => this.props.onSetFormOpen(this.props.Members.addMemberForm.id, true)
    }

    return React.createElement(
      MembersView,
      memberViewProps,
      this.props.Members.loading === true ? React.createElement(DashboardLoadingView) : membersListView
    );
  }
  private filterMembers(): ScottlandUserDetailView[] {

    const filterValue = this.props.Members.memberFilterForm.fields.filter.value as string;

    return this.props.Members.members.filter(member =>
      filterValue === null ||
      member.firstName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
      member.lastName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
      member.email.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    );
  }
}

const mapStateToProps = (state: StoreState) => ({ App: state.App, Members: state.Members });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  onLoad,
  onFormChange,
  onCancelFormEdits,
  onSetFormOpen,
  onSetEditMember,
  onAddMember,
  onEditMember,
  onDeleteMember
}, dispatch);

export default withRouter(<any>connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersContainer));


