
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import StoreState from '../../redux/interfaces/StoreState';
import { AppContainerProps } from './interfaces/AppContainerProps';
import { onCheckAuthentication, onLogout } from './thunks/AppThunks';
import AppView from './views/AppView';
import { LayoutViewProps } from './views/interfaces/LayoutViewProps';
import { LayoutView } from './views/LayoutView';
import MemberAppView from './views/MemberAppView';
import UnauthenticatedAppView from './views/UnauthenticatedAppView';
import AuthenticationLoadingView from './views/AuthenticationLoadingView';

export class AppContainer extends React.Component<AppContainerProps> {

  public componentWillMount() {
    this.props.onCheckAuthentication();
  }

  public componentDidCatch(error: any, errorInfo: any) {
    window.console.log(error + errorInfo)
  }

  public render(): React.ReactNode {

    if(this.props.App.authenticationLoading) {
      return React.createElement(AuthenticationLoadingView)
    } 

    if (this.props.App.authenticationToken == null ) {
      return React.createElement(UnauthenticatedAppView);;
    }

    const layoutViewProps: LayoutViewProps = {
      loggedInUser: this.props.App.authenticationToken.claims.email,
			onLogout: this.props.onLogout,
			administrativeMode: this.props.App.authenticationToken.claims.is_administrator === true
    }

    const appView = this.props.App.authenticationToken.claims.is_administrator === true ?
      React.createElement(AppView) : React.createElement(MemberAppView)

		return React.createElement(LayoutView, layoutViewProps, appView);
  }

}

const mapStateToProps = (state: StoreState) => ({ App: state.App });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ onCheckAuthentication, onLogout }, dispatch);

export default withRouter(<any>connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer));


