import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const AuthenticationLoadingView = () => (
	<Segment placeholder basic>
		<Header icon>
      <Icon name='sync alternate' color='olive' loading />
      Just a sec. We are checking your authentication...
			<Header.Subheader>
				If you see this message for more than a few seconds, please refresh the page.
			</Header.Subheader>
    </Header>
	</Segment>
)

export default AuthenticationLoadingView