import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const RatePlanLoadingView = () => (
	<Segment placeholder basic>
		<Header icon>
      <Icon name='sync alternate' color='olive' loading />
      Loading...
    </Header>
	</Segment>
)

export default RatePlanLoadingView