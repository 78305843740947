
import moment from "moment";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";
import { ScottlandTransactionFileUploadResponse } from "../../../lib/interfaces/ScottlandTransactionFileUploadResponse";
import * as ScottlandApi from "../../../lib/ScottlandApi";
import * as LoginImageCreators from "../../../redux/creators/LoginImageCreators";
import StoreState from "../../../redux/interfaces/StoreState";

export function onLoginImageUpload(e: any, fileInputRef: React.RefObject<HTMLInputElement>): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    if (e &&
      e.target &&
      e.target.files &&
      e.target.files.length > 0) {

      const files: FileList = e.target.files;

      dispatch(LoginImageCreators.SetLoading(true));
      dispatch(LoginImageCreators.SetError(false));

      try {

        const loginImageFile = files[0];
        fileInputRef.current!.value = '';

        await ScottlandApi.UpdateLoginImage(loginImageFile);

      }
      catch (error) {

        const e = error as ScottlandApiError;

        dispatch(LoginImageCreators.SetError(true));
        dispatch(LoginImageCreators.SetErrorMessages(error.errors));
      }
      finally {
        dispatch(LoginImageCreators.SetLoading(false));
      }


    }
  }
}