

import { Reducer } from 'redux';
import { EntityState } from './interfaces/EntityState';
import * as EntityActions from './creators/interfaces/EntityCreators';
import { EntityActionTypes } from './creators/EntityCreators';
import { handleFormChange, handleSetFormLoading, handleSetFormError, handleSetFormErrorMessages, handleClearFormError, handleSetFormOpen, handleCancelFormEdits } from './FormReducerHandlers';
import { StepTitle } from 'semantic-ui-react';
import moment from 'moment';

const initialState: EntityState = {
  loading: false,
  error: false,
  errorMessages: [],
  entityDeleted: false,
  users: null,
  branches: null,
  ratePlans: null,
  entityData: null,
  transactionsTableSettingsForm: {
    id: 'transactionsTableSettingsForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      activePage: {
        id: 'activePage',
        label: 'Active Page',
        placeholder: 'Active Page',
        value: 1,
        originalValue: 1,
        error: false,
        touched: false,
        validation: { required: true },
      },
      itemsPerPage: {
        id: 'itemsPerPage',
        label: 'Items per page',
        placeholder: 'Items per page',
        value: '25',
        originalValue: '25',
        error: false,
        touched: false,
        validation: { required: true },
        options: [
          { key: '25', value: '25', text: '25' },
          { key: '50', value: '50', text: '50' },
          { key: '100', value: '100', text: '100' },
          { key: '250', value: '250', text: '250' }
        ]
      },
      showEphermalTransactions: {
        id: 'showEphermalTransactions',
        label: 'Show Ephermal Transactions',
        placeholder: 'Show Ephermal Transactions',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: true },
      },
      showYearEndOnly: {
        id: 'showYearEndOnly',
        label: 'showYearEndOnly',
        placeholder: 'showYearEndOnly',
        value: false as any,
        originalValue: false as any,
        error: false,
        touched: false,
        validation: { required: true },
      },
      statusFilter: {
        id: 'statusFilter',
        label: 'Status filter',
        placeholder: 'Filter by status...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      startDate: {
        id: 'startDate',
        label: 'Start Date',
        placeholder: 'Filter by start date...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      endDate: {
        id: 'endDate',
        label: 'End Date',
        placeholder: 'Filter by end date...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      typeFilter: {
        id: 'typeFilter',
        label: 'Type filter',
        placeholder: 'Filter by type...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  },
  editMode: false,
  entityEditForm: {
    id: 'entityEditForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      name: {
        id: 'name',
        label: 'Name',
        placeholder: 'Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      branch: {
        id: 'branch',
        label: 'Branch',
        placeholder: 'Select a branch...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      members: {
        id: 'members',
        label: 'Members',
        placeholder: 'Select one or more members...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      establishedAt: {
        id: 'establishedAt',
        label: 'Established',
        placeholder: 'Established',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      ratePlan: {
        id: 'ratePlan',
        label: 'Rate Plan',
        placeholder: 'Select a rate plan...',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      balanceForwardPrincipal: {
        id: 'balanceForwardPrincipal',
        label: 'Balance Forward Principal',
        placeholder: '',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      balanceForwardInterest: {
        id: 'balanceForwardInterest',
        label: 'Balance Forward Interest',
        placeholder: 'Balance Forward Interest',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      active: {
        id: 'active',
        label: 'Archived',
        placeholder: 'Archived',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  },
  deleteEntityForm: {
    id: 'deleteEntityForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      confirmEntityName: {
        id: 'confirmEntityName',
        label: 'Confirm Entity Name',
        placeholder: 'Type the name of the entity to delete...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      confirmDelete: {
        id: 'confirmDelete',
        label: 'Confirm Entity Delete',
        placeholder: 'Confirm entity eelete...',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  }
}

const handleResetToInitialState = (state: EntityState, action: EntityActions.EntityAction): EntityState => {
  return {
    ...initialState,
  };
}

const handleSetEntityDeleted = (state: EntityState, action: EntityActions.SetLoadingAction): EntityState => {
  return {
    ...state,
    entityDeleted: true
  };
}

const handleSetLoading = (state: EntityState, action: EntityActions.SetLoadingAction): EntityState => {
  return {
    ...state,
    loading: action.loading
  };
}

const handleSetError = (state: EntityState, action: EntityActions.SetErrorAction): EntityState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: EntityState, action: EntityActions.SetErrorMessagesAction): EntityState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handleSetEntityData = (state: EntityState, action: EntityActions.SetEntityDataAction): EntityState => {
  return {
    ...state,
    entityData: action.entityData,
    entityEditForm: {
      ...state.entityEditForm,
      fields: {
        ...state.entityEditForm.fields,
        name: {
          ...state.entityEditForm.fields.name,
          value: action.entityData.entity.name,
          originalValue: action.entityData.entity.name,
          touched: false,
          error: false
        },
        branch: {
          ...state.entityEditForm.fields.branch,
          value: action.entityData.entity.branch.key.toString(),
          originalValue: action.entityData.entity.branch.key.toString(),
          touched: false,
          error: false
        },
        members: {
          ...state.entityEditForm.fields.members,
          value: Object.keys(action.entityData.entity.users).map(userKey => userKey) as any,
          originalValue: Object.keys(action.entityData.entity.users).map(userKey => userKey) as any,
          touched: false,
          error: false
        },
        establishedAt: {
          ...state.entityEditForm.fields.establishedAt,
          value: moment.unix(action.entityData.entity.establishedAt).format("YYYY-MM-DD"),
          originalValue: moment.unix(action.entityData.entity.establishedAt).format("YYYY-MM-DD"),
          touched: false,
          error: false
        },
        ratePlan: {
          ...state.entityEditForm.fields.ratePlan,
          value: action.entityData.entity.ratePlan.id.toString(),
          originalValue: action.entityData.entity.ratePlan.id.toString(),
          touched: false,
          error: false
        },
        balanceForwardPrincipal: {
          ...state.entityEditForm.fields.balanceForwardPrincipal,
          value: action.entityData.entity.balanceForwardPrincipal,
          originalValue: action.entityData.entity.balanceForwardPrincipal,
          touched: false,
          error: false
        },
        balanceForwardInterest: {
          ...state.entityEditForm.fields.balanceForwardInterest,
          value: action.entityData.entity.balanceForwardInterest,
          originalValue: action.entityData.entity.balanceForwardInterest,
          touched: false,
          error: false
        },
        active: {
          ...state.entityEditForm.fields.active,
          value: action.entityData.entity.active as any,
          originalValue: action.entityData.entity.active as any,
          touched: false,
          error: false
        },
      }
    }
  }
}

const handleSetUsers = (state: EntityState, action: EntityActions.SetUsersAction): EntityState => {
  return {
    ...state,
    users: action.users,
    entityEditForm: {
      ...state.entityEditForm,
      fields: {
        ...state.entityEditForm.fields,
        members: {
          ...state.entityEditForm.fields.members,
          options: action.users.map(user => (
            {
              key: user.id.toString(),
              value: user.id.toString(),
              text: user.name
            }
          ))
        }
      }
    }
  };
}

const handleSetBranches = (state: EntityState, action: EntityActions.SetBranchesAction): EntityState => {
  return {
    ...state,
    branches: action.branches,
    entityEditForm: {
      ...state.entityEditForm,
      fields: {
        ...state.entityEditForm.fields,
        branch: {
          ...state.entityEditForm.fields.branch,
          options: action.branches.map(branch => (
            {
              key: branch.id.toString(),
              value: branch.id.toString(),
              text: branch.name
            }
          ))
        }
      }
    }
  };
}

const handleSetRatePlans = (state: EntityState, action: EntityActions.SetRatePlansAction): EntityState => {
  return {
    ...state,
    ratePlans: action.ratePlans,
    entityEditForm: {
      ...state.entityEditForm,
      fields: {
        ...state.entityEditForm.fields,
        ratePlan: {
          ...state.entityEditForm.fields.ratePlan,
          options: action.ratePlans.map(ratePlan => (
            {
              key: ratePlan.id.toString(),
              value: ratePlan.id.toString(),
              text: ratePlan.name
            }
          ))
        }
      }
    }
  };
}

const handleSetEditMode = (state: EntityState, action: EntityActions.SetEditModeAction): EntityState => {
  return {
    ...state,
    editMode: action.editMode
  };
}





const handlers: { [x: string]: (state: EntityState, action: EntityActions.EntityAction) => EntityState } =
  {
    [EntityActionTypes.ResetToInitialState]: handleResetToInitialState,
    [EntityActionTypes.SetLoading]: handleSetLoading,
    [EntityActionTypes.SetError]: handleSetError,
    [EntityActionTypes.SetErrorMessages]: handleSetErrorMessages,
    [EntityActionTypes.SetEntityData]: handleSetEntityData,
    [EntityActionTypes.SetFormLoading]: handleSetFormLoading,
    [EntityActionTypes.SetFormError]: handleSetFormError,
    [EntityActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
    [EntityActionTypes.ClearFormError]: handleClearFormError,
    [EntityActionTypes.SetFormOpen]: handleSetFormOpen,
    [EntityActionTypes.CancelFormEdits]: handleCancelFormEdits,
    [EntityActionTypes.FormChange]: handleFormChange,
    [EntityActionTypes.SetUsers]: handleSetUsers,
    [EntityActionTypes.SetBranches]: handleSetBranches,
    [EntityActionTypes.SetRatePlans]: handleSetRatePlans,
    [EntityActionTypes.SetEditMode]: handleSetEditMode,
    [EntityActionTypes.SetEntityDeleted]: handleSetEntityDeleted
  } as any

const EntityReducer: Reducer<EntityState, EntityActions.EntityAction> = (state: EntityState | undefined, action: EntityActions.EntityAction): EntityState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default EntityReducer