import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Icon, List, Popup } from 'semantic-ui-react';
import { BranchCardsViewProps } from './interfaces/BranchCardsViewProps';

const BranchCardStyles = {
  backgroundColor: '#F8FAED',
  maxWidth: '230px'
}

const BranchCardsView = (props: BranchCardsViewProps) =>
  (
    <Card.Group style={{ margin: 0 }}>
      {props.branches.map(branch =>
        <Card key={branch.id} style={BranchCardStyles} as={Link} color='olive' to={`/branches/${branch.id}`}>
          <Card.Content>
            <Card.Header style={{ fontSize: '1em' }}>
              {branch.name}
              {(branch.entities == null || Object.keys(branch.entities).length <= 0) &&
                (branch.users == null || Object.keys(branch.users).length <= 0) &&
                <Button
                  icon='delete'
                  color='red'
                  inverted
                  compact
                  style={{
                    float: 'right',
                    position: 'relative',
                    left: '.75em',
                    zIndex: '100',
                    fontSize: '.5em'
                  }}
                  onClick={(e: any) => { e.preventDefault(); props.onSetBranchDeleteId(branch.id); }}
                />
              }
              <Icon
                name='pencil'
                color='brown'
                style={{
                  float: 'right',
                  position: 'relative',
                  left: '.75em',
                  zIndex: '100',
                  fontSize: '.75em'
                }}
                onClick={(e: any) => { e.preventDefault(); props.onSetBranchEditId(branch.id); }}
              />
            </Card.Header>
            <Card.Meta style={{ fontSize: 'smaller' }}>
              <div>
                <i>Principal</i>&nbsp;
                <span style={{ color: branch.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {branch.formattedPrincipal}
                </span>
              </div>
              <div>
                <i>Interest</i>&nbsp;
                <span style={{ color: branch.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {branch.formattedInterest}
                </span>
              </div>
            </Card.Meta>
          </Card.Content>
          <Card.Content extra style={{ backgroundColor: '#563922', fontSize: 'smaller', color: '#dddddd' }} >
            <Grid columns={2}>
              <Grid.Column textAlign='left'>
                <Popup
                  header='Members'
                  position='bottom left'
                  content={
                    <List size='small'
                      items={
                        Object.keys(branch.users).map((user) => branch.users[user as any])
                      }
                    />
                  }
                  trigger={
                    <Icon
                      name='user'
                      color='olive'
                      size='small'
                      style={{ cursor: 'pointer' }}
                    />
                  }
                />
                {Object.keys(branch.users).length}&nbsp;Members
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Icon name='book' color='olive' size='small' />
                {Object.keys(branch.entities).length}&nbsp;Entities
              </Grid.Column>
            </Grid>

          </Card.Content>
        </Card>
      )}
    </Card.Group>
  )

export default BranchCardsView