import axios, { AxiosError } from 'axios';
import { DashboardView } from './interfaces/DashboardView';
import { EntityView } from './interfaces/EntityView';
import * as AuthenticationService from './Authentication/AuthenticationService'
import { ScottlandRatePlanDetails } from './interfaces/ScottlandRatePlanDetails';
import { ScottlandTransactionView, ScottlandTransactionSummaryView } from './interfaces/ScottlandTransactionView';
import { ScottlandCreateTransactionRequest } from './interfaces/ScottlandCreateTransactionRequest';
import { ScottlandTransactionActionResponse } from './interfaces/ScottlandTransactionActionResponse';
import { ScottlandTransactionNoteRequest } from './interfaces/ScottlandNoteRequest';
import { ScottlandTransactionNoteView } from './interfaces/ScottlandTransactionNoteView';
import Config from '../config/Config';
import { ScottlandTransactionFileView } from './interfaces/ScottlandTransactionFileView';
import { ScottlandTransactionFileUploadResponse } from './interfaces/ScottlandTransactionFileUploadResponse';
import { ScottlandRatePlanRequest } from './interfaces/ScottlandRatePlanRequest';
import { ScottlandRateChangeRequest } from './interfaces/ScottlandRateChangeRequest';
import { ScottlandApiError } from './interfaces/ScottlandApiError';
import { ScottlandUserView } from './interfaces/ScottlandUserView';
import { ScottlandRatePlanView } from './interfaces/ScottlandRatePlanView';
import { ScottlandBranchView, ScottlandBranchDetailsView } from './interfaces/ScottlandBranchView';
import { ScottlandEntityRequest } from './interfaces/ScottlandEntityRequest';
import { ScottlandEntityDetailsView, ScottlandEntityView } from './interfaces/ScottlandEntityView';
import { ScottlandBranchRequest } from './interfaces/ScottlandBranchRequest';
import { ScottlandUserDetailView } from './interfaces/ScottlandUserDetailView';
import { ScottlandUserRequest } from './interfaces/ScottlandUserRequest';

const baseApiUrl = Config.apiUrl;

const routes = {
	getDashboardView: () => `/dashboard`,
	usersBaseRoute: () => `/user`,
	usersDetails: () => `${routes.usersBaseRoute()}/details`,
	userWithId: (userId: string) => `${routes.usersBaseRoute()}/${userId}`,
	branchesBaseRoute: () => `/branch`,
	branchWithId: (branchId: string) => `${routes.branchesBaseRoute()}/${branchId}`,
	entityBaseRoute: () => `/entity`,
	entityWithId: (entityId: string) => `${routes.entityBaseRoute()}/${entityId}`,
	transactionBaseRoute: () => `/transaction`,
	pendingTransactions: () => `${routes.transactionBaseRoute()}/pending`,
	transactionWithId: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}`,
	approveTransaction: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}/approve`,
	inquiryTransaction: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}/inquiry`,
	voidTransaction: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}/void`,
	addTransactionNote: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}/notes`,
	editTransactionNote: (transactionId: string, noteId: string) => `${routes.transactionBaseRoute()}/${transactionId}/notes/${noteId}`,
	deleteTransactionNote: (transactionId: string, noteId: string) => `${routes.transactionBaseRoute()}/${transactionId}/notes/${noteId}`,
	rescindTransaction: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}/rescind`,
	addTransactionFiles: (transactionId: string) => `${routes.transactionBaseRoute()}/${transactionId}/files`,
	transactionFileWithId: (transactionId: string, fileId: string) => `${routes.transactionBaseRoute()}/${transactionId}/files/${fileId}`,
	ratePlanBaseRoute: () => '/rate-plan',
	ratePlanDetails: () => '/rate-plan/details',
	ratePlanWithId: (ratePlanId: string) => `${routes.ratePlanBaseRoute()}/${ratePlanId}`,
	rateChange: (ratePlanId: string, rateChangeId: string) => `${routes.ratePlanBaseRoute()}/${ratePlanId}/rate-change/${rateChangeId}`,
	updateLoginImage: () => `/login-image`

}

const getAuthorizationHeaderValue = async (): Promise<string> => {

	const token = await AuthenticationService.GetAccessToken();

	if (token === null) {
		window.location.assign('/');
	}

	return `Bearer ${(token as any).accessToken}`;
}

const handleApiError = (error: AxiosError): ScottlandApiError => {

	if (error === null ||
		error === undefined ||
		error.response === null ||
		error.response === undefined ||
		error.response.status === null ||
		error.response.status === undefined) {
		return {
			isError: true,
			type: null,
			errors: ['An unknown error occured.']
		}
	}

	if (error.response.status === 404) {
		window.location.assign('/404');
		return {
			isError: true,
			type: null,
			errors: ['We can not find the page you requested.']
		}
	}

	if (
		error.response.data === null ||
		error.response.data === undefined ||
		error.response.data.errors === null ||
		error.response.data.errors === undefined ||
		error.response.data.errors[0] === null ||
		error.response.data.errors[0] === undefined) {
		return {
			isError: true,
			type: null,
			errors: ['An unknown error occured.']
		}
	}

	return {
		isError: true,
		type: error.response.data.type || null,
		errors: error.response.data.errors
	};
}

export const GetDashboardView = async (): Promise<DashboardView> => {

	try {
		const response = await axios.get<DashboardView>(
			`${baseApiUrl}${routes.getDashboardView()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const CreateBranch = async (branchToAdd: ScottlandBranchRequest): Promise<ScottlandBranchDetailsView> => {

	try {
		const response = await axios.post<ScottlandBranchDetailsView>(
			`${baseApiUrl}${routes.branchesBaseRoute()}`,
			branchToAdd,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const DeleteBranch = async (branchToDeleteId: string): Promise<boolean> => {

	try {
		const response = await axios.delete<boolean>(
			`${baseApiUrl}${routes.branchWithId(branchToDeleteId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const EditBranch = async (branchToEditId: string, branchToEdit: ScottlandBranchRequest): Promise<ScottlandBranchView> => {

	try {
		const response = await axios.post<ScottlandBranchView>(
			`${baseApiUrl}${routes.branchWithId(branchToEditId)}`,
			branchToEdit,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetEntities = async (): Promise<ScottlandEntityView[]> => {

	try {
		const response = await axios.get<ScottlandEntityView[]>(
			`${baseApiUrl}${routes.entityBaseRoute()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetEntityView = async (entityId: string): Promise<EntityView> => {

	try {
		const response = await axios.get<EntityView>(
			`${baseApiUrl}${routes.entityWithId(entityId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const CreateEntity = async (entityToAdd: ScottlandEntityRequest): Promise<number> => {

	try {
		const response = await axios.post<number>(
			`${baseApiUrl}${routes.entityBaseRoute()}`,
			entityToAdd,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const DeleteEntity = async (entityIdToDelete: string): Promise<boolean> => {

	try {
		const response = await axios.delete<boolean>(
			`${baseApiUrl}${routes.entityWithId(entityIdToDelete)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const EditEntity = async (entityId: string, editRequest: ScottlandEntityRequest): Promise<EntityView> => {

	try {
		const response = await axios.put<EntityView>(
			`${baseApiUrl}${routes.entityWithId(entityId)}`,
			editRequest,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetUsers = async (): Promise<ScottlandUserView[]> => {

	try {
		const response = await axios.get<ScottlandUserView[]>(
			`${baseApiUrl}${routes.usersBaseRoute()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetUserDetails = async (): Promise<ScottlandUserDetailView[]> => {

	try {
		const response = await axios.get<ScottlandUserDetailView[]>(
			`${baseApiUrl}${routes.usersDetails()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const CreateUser = async (userToCreate: ScottlandUserRequest): Promise<ScottlandUserDetailView> => {

	try {
		const response = await axios.post<ScottlandUserDetailView>(
			`${baseApiUrl}${routes.usersBaseRoute()}`,
			userToCreate,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const UpdateUser = async (userId: string, editRequest: ScottlandUserRequest): Promise<ScottlandUserDetailView> => {

	try {
		const response = await axios.put<ScottlandUserDetailView>(
			`${baseApiUrl}${routes.userWithId(userId)}`,
			editRequest,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const DeleteUser = async (userIdToDelete: string): Promise<boolean> => {

	try {
		const response = await axios.delete<boolean>(
			`${baseApiUrl}${routes.userWithId(userIdToDelete)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetRatePlans = async (): Promise<ScottlandRatePlanView[]> => {

	try {
		const response = await axios.get<ScottlandRatePlanView[]>(
			`${baseApiUrl}${routes.ratePlanBaseRoute()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetBranches = async (): Promise<ScottlandBranchView[]> => {

	try {
		const response = await axios.get<ScottlandBranchView[]>(
			`${baseApiUrl}${routes.branchesBaseRoute()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetRatePlanView = async (): Promise<ScottlandRatePlanDetails[]> => {

	try {
		const response = await axios.get<ScottlandRatePlanDetails[]>(
			`${baseApiUrl}${routes.ratePlanDetails()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetTransaction = async (transactionId: string): Promise<ScottlandTransactionView> => {

	try {
		const response = await axios.get<ScottlandTransactionView>(
			`${baseApiUrl}${routes.transactionWithId(transactionId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const GetPendingTransactions = async (): Promise<ScottlandTransactionSummaryView[]> => {

	try {
		const response = await axios.get<ScottlandTransactionSummaryView[]>(
			`${baseApiUrl}${routes.pendingTransactions()}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const PostTransaction = async (transaction: ScottlandCreateTransactionRequest): Promise<ScottlandTransactionView> => {

	try {
		const response = await axios.post<ScottlandTransactionView>(
			`${baseApiUrl}${routes.transactionBaseRoute()}`,
			transaction,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error)
	}
}

export const ApproveTransaction = async (transactionId: string, transaction: ScottlandTransactionRequest): Promise<ScottlandTransactionActionResponse> => {

	try {
		const response = await axios.put<ScottlandTransactionActionResponse>(
			`${baseApiUrl}${routes.approveTransaction(transactionId)}`,
			transaction,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const EditTransaction = async (transactionId: string, transaction: ScottlandTransactionRequest): Promise<ScottlandTransactionActionResponse> => {

	try {
		const response = await axios.put<ScottlandTransactionActionResponse>(
			`${baseApiUrl}${routes.transactionWithId(transactionId)}`,
			transaction,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const VoidTransaction = async (transactionId: string): Promise<ScottlandTransactionActionResponse> => {

	try {
		const response = await axios.put<ScottlandTransactionActionResponse>(
			`${baseApiUrl}${routes.voidTransaction(transactionId)}`,
			null,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const InquiryTransaction = async (transactionId: string): Promise<ScottlandTransactionActionResponse> => {

	try {
		const response = await axios.put<ScottlandTransactionActionResponse>(
			`${baseApiUrl}${routes.inquiryTransaction(transactionId)}`,
			null,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const RescindTransaction = async (transactionId: string): Promise<ScottlandTransactionActionResponse> => {

	try {
		const response = await axios.put<ScottlandTransactionActionResponse>(
			`${baseApiUrl}${routes.rescindTransaction(transactionId)}`,
			null,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const DeleteTransaction = async (transactionId: string): Promise<void> => {

	try {
		const response = await axios.delete(
			`${baseApiUrl}${routes.transactionWithId(transactionId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const AddTransactionNote = async (transactionId: string, note: ScottlandTransactionNoteRequest): Promise<ScottlandTransactionNoteView> => {

	try {
		const response = await axios.post<ScottlandTransactionNoteView>(
			`${baseApiUrl}${routes.addTransactionNote(transactionId)}`,
			note,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const EditTransactionNote = async (transactionId: string, noteId: string, note: ScottlandTransactionNoteRequest): Promise<ScottlandTransactionNoteView> => {

	try {
		const response = await axios.put<ScottlandTransactionNoteView>(
			`${baseApiUrl}${routes.editTransactionNote(transactionId, noteId)}`,
			note,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const DeleteTransactionNote = async (transactionId: string, noteId: string): Promise<void> => {

	try {
		const response = await axios.delete<void>(
			`${baseApiUrl}${routes.deleteTransactionNote(transactionId, noteId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const AddTransactionFiles = async (transactionId: string, files: { isAdministrative: boolean, file: File }[]): Promise<ScottlandTransactionFileUploadResponse> => {

	try {

		let formData = new FormData();

		for (let i = 0; i < files.length; i++) {

			const file = files[i];

			if (file !== null && file.file !== null) {

				formData.append(file.isAdministrative.toString(), file.file, file.file.name)
			}
		}

		const response = await axios.post<ScottlandTransactionFileUploadResponse>(
			`${baseApiUrl}${routes.addTransactionFiles(transactionId)}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': await getAuthorizationHeaderValue()
				}
			}
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const DeleteTransactionFile = async (transactionId: string, fileId: string): Promise<void> => {

	try {

		const response = await axios.delete<void>(
			`${baseApiUrl}${routes.transactionFileWithId(transactionId, fileId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const BuildTransactionFileUrl = (transactionId: string, fileId: string) => {
	return `${baseApiUrl}${routes.transactionFileWithId(transactionId, fileId)}`
}


export const BuildTransactionLedgerFileUrl = (entityId: number, startDate: number, endDate: number, includeEphemeral: boolean) => {
	return `${baseApiUrl}${routes.transactionBaseRoute()}/ledger?entityId=${entityId}&startDate=${startDate}&endDate=${endDate}&includeEphemeral=${includeEphemeral}`
}

export const BuildBalanceAndTransactionReportFileUrl = (startDate: number, endDate: number, includeEphemeral: boolean, includeAnnualCompounds: boolean) => {
	return `${baseApiUrl}${routes.transactionBaseRoute()}/balance-transaction-report?startDate=${startDate}&endDate=${endDate}&includeEphemeral=${includeEphemeral}&includeAnnualCompounds=${includeAnnualCompounds}`
}


export const CreateRatePlan = async (newRatePlan: ScottlandRatePlanRequest): Promise<ScottlandRatePlanDetails> => {

	try {

		const response = await axios.post<ScottlandRatePlanDetails>(
			`${baseApiUrl}${routes.ratePlanBaseRoute()}`,
			newRatePlan,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const UpdateRatePlan = async (ratePlanId: string, updatedRatePlan: ScottlandRatePlanRequest): Promise<ScottlandRatePlanDetails> => {

	try {

		const response = await axios.put<ScottlandRatePlanDetails>(
			`${baseApiUrl}${routes.ratePlanWithId(ratePlanId)}`,
			updatedRatePlan,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const DeleteRatePlan = async (ratePlanId: string): Promise<boolean> => {

	try {

		const response = await axios.delete<boolean>(
			`${baseApiUrl}${routes.ratePlanWithId(ratePlanId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}

export const CreateRateChange = async (ratePlanId: string, newRateChange: ScottlandRateChangeRequest): Promise<ScottlandRatePlanDetails> => {

	try {

		const response = await axios.post<ScottlandRatePlanDetails>(
			`${baseApiUrl}${routes.ratePlanWithId(ratePlanId)}`,
			newRateChange,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error as AxiosError);
	}
}

export const DeleteRateChange = async (ratePlanId: string, rateChangeId: string): Promise<ScottlandRatePlanDetails> => {

	try {

		const response = await axios.delete<ScottlandRatePlanDetails>(
			`${baseApiUrl}${routes.rateChange(ratePlanId, rateChangeId)}`,
			{ headers: { 'Authorization': await getAuthorizationHeaderValue() } }
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}


export const UpdateLoginImage = async (loginImageFile: File): Promise<void> => {

	try {

		let formData = new FormData();

		formData.append('loginImageFile', loginImageFile, loginImageFile.name)

		const response = await axios.post<void>(
			`${baseApiUrl}${routes.updateLoginImage()}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': await getAuthorizationHeaderValue()
				}
			}
		);

		return response.data;
	}
	catch (error) {
		throw handleApiError(error);
	}
}


