import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const RatePlanErrorView = (props: { errorMessages? :string[]}) => (
	<Segment placeholder basic>
		<Header
			icon
		>
			<Icon name='exclamation triangle' color='red' />
			Oops!
			{props.errorMessages &&
				props.errorMessages.map( error => (
					<Header.Subheader>
						{error}
					</Header.Subheader>
				))}
		</Header>
	</Segment>
)

export default RatePlanErrorView