import * as React from 'react';
import { Segment, Comment, Modal, Message, Button, Icon } from 'semantic-ui-react';
import { TransactionNotesListViewProps } from './interfaces/TransactionNotesListViewProps';
import TransactionNoteEditView from './TransactionNoteEditView';
import TransactionNoteView from './TransactionNoteView';


const TransactionNotesListView = (props: TransactionNotesListViewProps) => (
  <>
    {props.notes.length > 0 &&
      <Comment.Group>
        {props.notes.map(note => (
          <Comment>
            {/* {note.isAdministrative === true &&
              <Comment.Avatar src='/padlock-icon.png' />
            }
            {note.isAdministrative === false &&
              <div className='avatar'>
                <Icon name='user outline' size='big' />
              </div>
            } */}
            <Comment.Content>
              {props.editNoteId === note.id ?
                (<TransactionNoteEditView
                  note={note}
                  form={props.editNoteForm}
                  allowAdministrativeNotes={props.allowAdministrativeNotes}
                  onFormChange={props.onEditFormChange}
                  onSetEditNoteId={props.onSetEditNoteId}
                  onEditNote={props.onEditNote}
                />) :
                (<TransactionNoteView
                  note={note}
                  allowAdministrativeNotes={props.allowAdministrativeNotes}
                  onSetDeleteNoteId={props.onSetDeleteNoteId}
                  onSetEditNoteId={props.onSetEditNoteId}
                />)
              }
            </Comment.Content>
          </Comment>
        ))}
      </Comment.Group>
    }
    <Modal
      basic
      size='tiny'
      closeIcon
      onClose={() => props.onSetDeleteNoteId(null)}
      open={props.deleteNoteId !== null}
    >
      <Modal.Header className='confirm-modal-header-caution'>
        Delete Note
      </Modal.Header>
      <Modal.Content>
        Are you sure you want to delete this note?
						{props.deleteNoteForm.error &&

          <Message
            error
            icon='exclamation'
            header='Oops. There was an error deleting the note.'
            list={props.deleteNoteForm.errorMessages}
            style={{
              marginTop: '1em'
            }}
          />
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='grey'
          labelPosition='right'
          icon='undo'
          content='Close'
          inverted
          size='tiny'
          onClick={() => props.onSetDeleteNoteId(null)}
          disabled={props.deleteNoteForm.loading}
        />
        <Button
          color='red'
          labelPosition='right'
          icon='x'
          content='Delete'
          inverted
          size='tiny'
          onClick={() => props.onDeleteNote()}
          loading={props.deleteNoteForm.loading}
          disabled={props.deleteNoteForm.loading}
        />
      </Modal.Actions>
    </Modal>
  </>
)

export default TransactionNotesListView 