import * as LoginImageCreators from "./interfaces/LoginImageCreators";
import { LoginImageAction, LoginImageCreator } from "./interfaces/LoginImageCreators";

export const LoginImageActionTypes = {
  ResetToInitialState: 'LOGINIMAGE_ACTIONS_ResetToInitialState',
  SetLoading: 'LOGINIMAGE_ACTIONS_SetLoading',
  SetError: 'LOGINIMAGE_ACTIONS_SetError',
  SetErrorMessages: 'LOGINIMAGE_ACTIONS_SetErrorMessages',
}

export const ResetToInitialState: LoginImageCreator = (): LoginImageAction => (
  { type: LoginImageActionTypes.ResetToInitialState }
);

export const SetLoading: LoginImageCreators.SetLoadingCreator =
  (loading: boolean): LoginImageCreators.SetLoadingAction => (
    {
      type: LoginImageActionTypes.SetLoading,
      loading
    }
  );

export const SetError: LoginImageCreators.SetErrorCreator =
  (error: boolean): LoginImageCreators.SetErrorAction => (
    {
      type: LoginImageActionTypes.SetError,
      error
    }
  );

export const SetErrorMessages: LoginImageCreators.SetErrorMessagesCreator =
  (errorMessages: string[]): LoginImageCreators.SetErrorMessagesAction => (
    {
      type: LoginImageActionTypes.SetErrorMessages,
      errorMessages
    }
  );


