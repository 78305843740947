import { ThunkAction } from "redux-thunk";
import StoreState from "../../../redux/interfaces/StoreState";
import { Dispatch } from "redux";
import { OktaToken } from "../../../lib/Authentication/interfaces/OktaAuth";
import * as AuthenticationService from '../../../lib/Authentication/AuthenticationService'
import * as AppCreators from '../../../redux/creators/AppCreators'
import { push } from "connected-react-router";

export function onCheckAuthentication(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(AppCreators.SetAuthenticationLoading(true));

    const authenticationToken: OktaToken | null = await AuthenticationService.CheckAuthentication();

    dispatch(AppCreators.SetAuthenticationToken(authenticationToken));

    dispatch(AppCreators.SetAuthenticationLoading(false));

  }
}

export function onLogout(): ThunkAction<void, StoreState, void, any> {
  return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

    dispatch(AppCreators.SetAuthenticationToken(null));

    await AuthenticationService.Logout();

    dispatch(push('/'));
  }
}