import * as EntityReportCreators from "./interfaces/EntityReportCreators";
import * as FormCreators from './FormCreators'
import { ScottlandRatePlanDetails } from "../../lib/interfaces/ScottlandRatePlanDetails";
import { ScottlandUserDetailView } from "../../lib/interfaces/ScottlandUserDetailView";
import { ScottlandBranchView } from "../../lib/interfaces/ScottlandBranchView";
import { ScottlandEntityView } from "../../lib/interfaces/ScottlandEntityView";



export const EntityReportActionTypes = {
	
  ResetToInitialState: 'ENTITYREPORT_ACTIONS_ResetToInitialState',
  SetLoading: 'ENTITYREPORT_ACTIONS_SetLoading',
  SetError: 'ENTITYREPORT_ACTIONS_SetError',
  SetErrorMessages: 'ENTITYREPORT_ACTIONS_SetErrorMessages',
  SetFormLoading: 'ENTITYREPORT_ACTIONS_SetFormLoading',
  SetFormError: 'ENTITYREPORT_ACTIONS_SetFormError',
  SetFormErrorMessages: 'ENTITYREPORT_ACTIONS_SetFormErrorMessages',
  ClearFormError: 'ENTITYREPORT_ACTIONS_ClearFormError',
  SetFormOpen: 'ENTITYREPORT_ACTIONS_SetFormOpen',
  CancelFormEdits: 'ENTITYREPORT_ACTIONS_CancelFormEdits',
  FormChange: 'ENTITYREPORT_ACTIONS_FormChange',
  SetEntityReport: 'ENTITYREPORT_ACTIONS_SetEntityReport',
  SetBranches: 'ENTITYREPORT_ACTIONS_SetBranches',
  SetEntities: 'ENTITYREPORT_ACTIONS_SetEntities',
  SetEditMember: 'ENTITYREPORT_ACTIONS_SetEditMember',
  AddMember: 'ENTITYREPORT_ACTIONS_AddMember',
  UpdateMember: 'ENTITYREPORT_ACTIONS_UpdateMember',
  RemoveMember: 'ENTITYREPORT_ACTIONS_RemoveMember'

}

export const ResetToInitialState: EntityReportCreators.EntityReportCreator = (): EntityReportCreators.EntityReportAction => (
  { type: EntityReportActionTypes.ResetToInitialState }
);

export const SetFormLoading: EntityReportCreators.EntityReportCreator = (formId: string, loading: boolean): EntityReportCreators.EntityReportAction => (
  FormCreators.SetFormLoading(
    EntityReportActionTypes.SetFormLoading,
    formId,
    loading
  )
);


export const SetFormError: EntityReportCreators.EntityReportCreator = (formId: string, error: boolean): EntityReportCreators.EntityReportAction => (
  FormCreators.SetFormError(
    EntityReportActionTypes.SetFormError,
    formId,
    error
  )
);

export const SetFormErrorMessages: EntityReportCreators.EntityReportCreator = (formId: string, errorMessages: string[]): EntityReportCreators.EntityReportAction => (
  FormCreators.SetFormErrorMessages(
    EntityReportActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
);

export const ClearFormError: EntityReportCreators.EntityReportCreator = (formId: string): EntityReportCreators.EntityReportAction => (
  FormCreators.ClearFormError(
    EntityReportActionTypes.ClearFormError,
    formId
  )
);


export const FormChange: EntityReportCreators.EntityReportCreator =
  (formId: string, field: string, value: string, error: boolean): EntityReportCreators.EntityReportAction => (
    FormCreators.FormChange(
      EntityReportActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );

export const SetFormOpen: EntityReportCreators.EntityReportCreator = (formId: string, loading: boolean): EntityReportCreators.EntityReportAction => (
  FormCreators.SetFormOpen(
    EntityReportActionTypes.SetFormOpen,
    formId,
    loading
  )
);

export const CancelFormEdits: EntityReportCreators.EntityReportCreator =
  (formId: string): EntityReportCreators.EntityReportAction => (
    FormCreators.CancelFormEdits(
      EntityReportActionTypes.CancelFormEdits,
      formId
    )
  );


export const SetLoading: EntityReportCreators.SetLoadingCreator =
  (loading: boolean): EntityReportCreators.SetLoadingAction => (
    {
      type: EntityReportActionTypes.SetLoading,
      loading
    }
  );

export const SetError: EntityReportCreators.SetErrorCreator =
  (error: boolean): EntityReportCreators.SetErrorAction => (
    {
      type: EntityReportActionTypes.SetError,
      error
    }
  );

export const SetErrorMessages: EntityReportCreators.SetErrorMessagesCreator =
  (errorMessages: string[]): EntityReportCreators.SetErrorMessagesAction => (
    {
      type: EntityReportActionTypes.SetErrorMessages,
      errorMessages
    }
  );













