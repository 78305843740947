import { OktaToken } from "../../lib/Authentication/interfaces/OktaAuth";
import * as AppCreators from "./interfaces/AppCreators";
import { AppAction, AppCreator } from "./interfaces/AppCreators";

export const AppActionTypes = {
  ResetToInitialState: 'APP_ACTIONS_ResetToInitialState',
  SetAuthenticationToken: 'APP_ACTIONS_SetAuthenticationToken',
  SetAuthenticationLoading: 'APP_ACTIONS_SetAuthenticationLoading'
}

export const ResetToInitialState: AppCreator = (): AppAction => (
  { type: AppActionTypes.ResetToInitialState }
);

export const SetAuthenticationToken: AppCreators.SetAuthenticationTokenCreator =
  (authenticationToken: OktaToken | null): AppCreators.SetAuthenticationTokenAction => (
    {
      type: AppActionTypes.SetAuthenticationToken,
      authenticationToken
    }
  );

export const SetAuthenticationLoading: AppCreators.SetAuthenticationLoadingCreator =
  (loading: boolean): AppCreators.SetAuthenticationLoadingAction => (
    {
      type: AppActionTypes.SetAuthenticationLoading,
      loading
    }
  );


