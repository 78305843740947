import * as MembersCreators from "./interfaces/MembersCreators";
import * as FormCreators from '../creators/FormCreators'
import { ScottlandRatePlanDetails } from "../../lib/interfaces/ScottlandRatePlanDetails";
import { ScottlandUserDetailView } from "../../lib/interfaces/ScottlandUserDetailView";
import { ScottlandBranchView } from "../../lib/interfaces/ScottlandBranchView";
import { ScottlandEntityView } from "../../lib/interfaces/ScottlandEntityView";



export const MembersActionTypes = {
  ResetToInitialState: 'MEMBERS_ACTIONS_ResetToInitialState',
  SetLoading: 'MEMBERS_ACTIONS_SetLoading',
  SetError: 'MEMBERS_ACTIONS_SetError',
  SetErrorMessages: 'MEMBERS_ACTIONS_SetErrorMessages',
  SetFormLoading: 'MEMBERS_ACTIONS_SetFormLoading',
  SetFormError: 'MEMBERS_ACTIONS_SetFormError',
  SetFormErrorMessages: 'MEMBERS_ACTIONS_SetFormErrorMessages',
  ClearFormError: 'MEMBERS_ACTIONS_ClearFormError',
  SetFormOpen: 'MEMBERS_ACTIONS_SetFormOpen',
  CancelFormEdits: 'MEMBERS_ACTIONS_CancelFormEdits',
  FormChange: 'MEMBERS_ACTIONS_FormChange',
  SetMembers: 'MEMBERS_ACTIONS_SetMembers',
  SetBranches: 'MEMEBER_ACTIONS_SetBranches',
  SetEntities: 'MEMBER_ACTIONS_SetEntities',
  SetEditMember: 'MEMBER_ACTIONS_SetEditMember',
  AddMember: 'MEMBER_ACTIONS_AddMember',
  UpdateMember: 'MEMBER_ACTIONS_UpdateMember',
  RemoveMember: 'MEMBER_ACTIONS_RemoveMember'

}

export const ResetToInitialState: MembersCreators.MembersCreator = (): MembersCreators.MembersAction => (
  { type: MembersActionTypes.ResetToInitialState }
);

export const SetFormLoading: MembersCreators.MembersCreator = (formId: string, loading: boolean): MembersCreators.MembersAction => (
  FormCreators.SetFormLoading(
    MembersActionTypes.SetFormLoading,
    formId,
    loading
  )
);


export const SetFormError: MembersCreators.MembersCreator = (formId: string, error: boolean): MembersCreators.MembersAction => (
  FormCreators.SetFormError(
    MembersActionTypes.SetFormError,
    formId,
    error
  )
);

export const SetFormErrorMessages: MembersCreators.MembersCreator = (formId: string, errorMessages: string[]): MembersCreators.MembersAction => (
  FormCreators.SetFormErrorMessages(
    MembersActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
);

export const ClearFormError: MembersCreators.MembersCreator = (formId: string): MembersCreators.MembersAction => (
  FormCreators.ClearFormError(
    MembersActionTypes.ClearFormError,
    formId
  )
);


export const FormChange: MembersCreators.MembersCreator =
  (formId: string, field: string, value: string, error: boolean): MembersCreators.MembersAction => (
    FormCreators.FormChange(
      MembersActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );

export const SetFormOpen: MembersCreators.MembersCreator = (formId: string, loading: boolean): MembersCreators.MembersAction => (
  FormCreators.SetFormOpen(
    MembersActionTypes.SetFormOpen,
    formId,
    loading
  )
);

export const CancelFormEdits: MembersCreators.MembersCreator =
  (formId: string): MembersCreators.MembersAction => (
    FormCreators.CancelFormEdits(
      MembersActionTypes.CancelFormEdits,
      formId
    )
  );


export const SetLoading: MembersCreators.SetLoadingCreator =
  (loading: boolean): MembersCreators.SetLoadingAction => (
    {
      type: MembersActionTypes.SetLoading,
      loading
    }
  );

export const SetError: MembersCreators.SetErrorCreator =
  (error: boolean): MembersCreators.SetErrorAction => (
    {
      type: MembersActionTypes.SetError,
      error
    }
  );

export const SetErrorMessages: MembersCreators.SetErrorMessagesCreator =
  (errorMessages: string[]): MembersCreators.SetErrorMessagesAction => (
    {
      type: MembersActionTypes.SetErrorMessages,
      errorMessages
    }
  );


export const SetMembers: MembersCreators.SetMembersCreator =
  (members: ScottlandUserDetailView[]): MembersCreators.SetMembersAction => (
    {
      type: MembersActionTypes.SetMembers,
      members
    }
  );


export const SetBranches: MembersCreators.SetBranchesCreator =
  (branches: ScottlandBranchView[]): MembersCreators.SetBranchesAction => (
    {
      type: MembersActionTypes.SetBranches,
      branches
    }
  );


export const SetEntities: MembersCreators.SetEntitiesCreator =
  (entities: ScottlandEntityView[]): MembersCreators.SetEntitiesAction => (
    {
      type: MembersActionTypes.SetEntities,
      entities
    }
  );


export const AddMember: MembersCreators.AddMemberCreator =
  (member: ScottlandUserDetailView): MembersCreators.AddMemberAction => (
    {
      type: MembersActionTypes.AddMember,
      member
    }
  );


export const UpdateMember: MembersCreators.UpdateMemberCreator =
  (member: ScottlandUserDetailView): MembersCreators.UpdateMemberAction => (
    {
      type: MembersActionTypes.UpdateMember,
      member
    }
  );


export const RemoveMember: MembersCreators.RemoveMemberCreator =
  (memberIdToRemove: number): MembersCreators.RemoveMemberAction => (
    {
      type: MembersActionTypes.RemoveMember,
      memberIdToRemove
    }
  );

export const SetEditMember :MembersCreators.SetEditMemberCreator =
(memberIdToEdit: number): MembersCreators.SetEditMemberAction => (
  {
    type: MembersActionTypes.SetEditMember,
    memberIdToEdit
  }
);











