

import { Reducer } from 'redux';
import { RatePlanActionTypes } from './creators/RatePlanCreators';
import { RatePlanAction } from './creators/interfaces/RatePlanCreators';
import { RatePlanState, RateChangeTableSettingsFormFields, RatePlanFormFields } from './interfaces/RatePlanState';
import { handleSetFormLoading, handleSetFormError, handleSetFormErrorMessages, handleClearFormError, handleFormChange, handleSetFormOpen, handleCancelFormEdits } from './FormReducerHandlers';
import * as RatePlanActions from './creators/interfaces/RatePlanCreators'
import { cloneDeep, omit } from 'lodash'
import { ScottlandRatePlanView } from '../lib/interfaces/ScottlandRatePlanView';
import { FormState } from './interfaces/common/FormState';
import { ScottlandRatePlanDetails } from '../lib/interfaces/ScottlandRatePlanDetails';

const rateChangesTableSettingsInitalState = {
  id: 'rateChangesTableSettings',
  loading: false,
  error: false,
  errorMessages: [],
  open: false,
  touched: false,
  touchedCount: 0,
  fields: {
    activePage: {
      id: 'activePage',
      label: 'Active Page',
      placeholder: 'Active Page',
      value: 1,
      originalValue: 1,
      error: false,
      touched: false,
      validation: { required: true },
    },
    itemsPerPage: {
      id: 'itemsPerPage',
      label: 'Items per page',
      placeholder: 'Items per page',
      value: '10',
      originalValue: '10',
      error: false,
      touched: false,
      validation: { required: true },
      options: [
        { key: '10', value: '10', text: '10' },
        { key: '25', value: '25', text: '25' },
        { key: '50', value: '50', text: '50' },
        { key: '100', value: '100', text: '100' }
      ]
    },
    showDepositRates: {
      id: 'showDepositRates',
      label: 'Show Deposit Rates',
      placeholder: 'Show Deposit Rates',
      value: true as any,
      originalValue: true as any,
      error: false,
      touched: false,
      validation: { required: true },
    },
    showAdvanceRates: {
      id: 'showAdvanceRates',
      label: 'Show Advance Rates',
      placeholder: 'Show Advance Rates',
      value: true as any,
      originalValue: true as any,
      error: false,
      touched: false,
      validation: { required: true },
    },
    collapsed: {
      id: 'collapsed',
      label: 'Collapse',
      placeholder: 'Collapse',
      value: true as any,
      originalValue: true as any,
      error: false,
      touched: false,
      validation: { required: true },
    }
  }
}

const editRatePlanFormInitialState = {
  id: 'editRatePlanForm',
  loading: false,
  error: false,
  errorMessages: [],
  open: false,
  touched: false,
  touchedCount: 0,
  fields: {
    name: {
      id: 'name',
      label: 'Name',
      placeholder: 'Name',
      value: null,
      originalValue: null,
      error: false,
      touched: false,
      validation: { required: true },
    },
    description: {
      id: 'description',
      label: 'Description',
      placeholder: 'Description',
      value: null,
      originalValue: null,
      error: false,
      touched: false,
      validation: { required: false },
    },
    initialDepositRate: {
      id: 'initialDepositRate',
      label: 'Initial Deposit Rate',
      placeholder: 'Initial Deposit Rate',
      value: 0.00,
      originalValue: 0.00,
      error: false,
      touched: false,
      validation: { required: true },
    },
    initialAdvanceRate: {
      id: 'initialAdvanceRate',
      label: 'Initial Advance Rate',
      placeholder: 'Initial Advance Rate',
      value: 0.00,
      originalValue: 0.00,
      error: false,
      touched: false,
      validation: { required: true },
    }
  }
}

const initialState: RatePlanState = {
  loading: false,
  error: false,
  errorMessages: [],
  editRatePlanId: null,
  addRateChangeRatePlanId: null,
  deleteRateChangeId: null,
  deleteRatePlanId: null,
  ratePlanData: null,
  deleteRateChangeForm: {
    id: 'deleteRateChangeForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {}
  },
  deleteRatePlanForm: {
    id: 'deleteRatePlanForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {}
  },
  addRatePlanForm: {
    id: 'addRatePlanForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      name: {
        id: 'name',
        label: 'Name',
        placeholder: 'Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      description: {
        id: 'description',
        label: 'Description',
        placeholder: 'Description',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      initialDepositRate: {
        id: 'initialDepositRate',
        label: 'Initial Deposit Rate',
        placeholder: 'Initial Deposit Rate',
        value: 0.00,
        originalValue: 0.00,
        error: false,
        touched: false,
        validation: { required: true },
      },
      initialAdvanceRate: {
        id: 'initialAdvanceRate',
        label: 'Initial Advance Rate',
        placeholder: 'Initial Advance Rate',
        value: 0.00,
        originalValue: 0.00,
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  },
  addRateChangeForm: {
    id: 'addRateChangeForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      effectiveAt: {
        id: 'effectiveAt',
        label: 'Effective Date',
        placeholder: 'Effective Date',
        value: '',
        originalValue: '',
        error: false,
        touched: false,
        validation: { required: true },
      },
      isDepositRate: {
        id: 'isDepositRate',
        label: 'isDepositRate',
        placeholder: 'isDepositRate',
        value: true as any,
        originalValue: true as any,
        error: false,
        touched: false,
        validation: { required: false },
      },
      rate: {
        id: 'rate',
        label: 'Rate',
        placeholder: 'Rate',
        value: 0.00,
        originalValue: 0.00,
        error: false,
        touched: false,
        validation: { required: true },
      }
    }
  }
};

const handleResetToInitialState = (state: RatePlanState, action: RatePlanAction): RatePlanState => {
  return {
    ...initialState
  };
}

const handleSetLoading = (state: RatePlanState, action: RatePlanActions.SetLoadingAction): RatePlanState => {
  return {
    ...state,
    loading: action.loading
  };
}

const handleSetError = (state: RatePlanState, action: RatePlanActions.SetErrorAction): RatePlanState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: RatePlanState, action: RatePlanActions.SetErrorMessagesAction): RatePlanState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handleSetRatePlanData = (state: RatePlanState, action: RatePlanActions.SetRatePlanDataAction): RatePlanState => {

  let rateChangesForms: { [ratePlanId: string]: FormState<RateChangeTableSettingsFormFields> } = {};

  action.ratePlanData.forEach((ratePlan: ScottlandRatePlanDetails) => {
    rateChangesForms[ratePlan.id] = cloneDeep(rateChangesTableSettingsInitalState);
    rateChangesForms[ratePlan.id].id = ratePlan.id.toString()
  });

  let ratePlanEditForms: { [ratePlanId: string]: FormState<RatePlanFormFields> } = {};

  action.ratePlanData.forEach((ratePlan: ScottlandRatePlanDetails) => {
    ratePlanEditForms[ratePlan.id.toString() + 'edit'] = cloneDeep(editRatePlanFormInitialState);
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].id = ratePlan.id.toString() + 'edit';
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.name.value = ratePlan.name;
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.description.value = ratePlan.description || '';
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.initialDepositRate.value = (ratePlan.initialDepositRate * 100).toFixed(2);
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.initialAdvanceRate.value = (ratePlan.initialAdvanceRate * 100).toFixed(2);

    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.name.originalValue = ratePlan.name;
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.description.originalValue = ratePlan.description || '';
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.initialDepositRate.originalValue = (ratePlan.initialDepositRate * 100).toFixed(2);
    ratePlanEditForms[ratePlan.id.toString() + 'edit'].fields.initialAdvanceRate.originalValue = (ratePlan.initialAdvanceRate * 100).toFixed(2);
  });

  return {
    ...state,
    ratePlanData: action.ratePlanData,
    ...rateChangesForms,
    ...ratePlanEditForms
  };
}

const handleAddRatePlan = (state: RatePlanState, action: RatePlanActions.AddRatePlanAction): RatePlanState => {

  const ratePlanData: ScottlandRatePlanDetails[] = [action.addedRatePlan].concat(state.ratePlanData!);

  let newRateChangeForm = {
    [action.addedRatePlan.id.toString()]: cloneDeep(rateChangesTableSettingsInitalState)
  }

  let newRatePlanEditForm = {
    [action.addedRatePlan.id.toString() + 'edit']: cloneDeep(editRatePlanFormInitialState)
  }


  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].id = action.addedRatePlan.id.toString() + 'edit';
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.name.value = action.addedRatePlan.name as any;
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.description.value = action.addedRatePlan.description || '';
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.initialDepositRate.value = (action.addedRatePlan.initialDepositRate * 100).toFixed(2) as any;
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.initialAdvanceRate.value = (action.addedRatePlan.initialAdvanceRate * 100).toFixed(2) as any;

  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.name.originalValue = action.addedRatePlan.name as any;
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.description.originalValue = action.addedRatePlan.description || '';
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.initialDepositRate.originalValue = (action.addedRatePlan.initialDepositRate * 100).toFixed(2) as any;
  newRatePlanEditForm[action.addedRatePlan.id.toString() + 'edit'].fields.initialAdvanceRate.originalValue = (action.addedRatePlan.initialAdvanceRate * 100).toFixed(2) as any;

  return {
    ...state,
    ratePlanData,
    ...newRateChangeForm,
    ...newRatePlanEditForm
  };
}

const handleSetRatePlan = (state: RatePlanState, action: RatePlanActions.SetRatePlanAction): RatePlanState => {

  const ratePlanToUpdate = state.ratePlanData!.find(ratePlan => ratePlan.id === action.ratePlan.id);

  if (ratePlanToUpdate === undefined) {
    return handleAddRatePlan(state, { type: RatePlanActionTypes.AddRatePlan, addedRatePlan: action.ratePlan });
  }

  const indexOfRatePlanToUpdate = state.ratePlanData!.indexOf(ratePlanToUpdate);

  let newRatePlanData = Object.assign([], state.ratePlanData, { [indexOfRatePlanToUpdate]: action.ratePlan });

  let newRatePlanEditForm = {
    [action.ratePlan.id.toString() + 'edit']: cloneDeep(editRatePlanFormInitialState)
  }


  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].open = (state as any)[action.ratePlan.id.toString() + 'edit'].open;
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].id = action.ratePlan.id.toString() + 'edit';
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.name.value = action.ratePlan.name as any;
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.description.value = action.ratePlan.description || '';
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.initialDepositRate.value = (action.ratePlan.initialDepositRate * 100).toFixed(2) as any;
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.initialAdvanceRate.value = (action.ratePlan.initialAdvanceRate * 100).toFixed(2) as any;
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.name.originalValue = action.ratePlan.name as any;
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.description.originalValue = action.ratePlan.description || '';
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.initialDepositRate.originalValue = (action.ratePlan.initialDepositRate * 100).toFixed(2) as any;
  newRatePlanEditForm[action.ratePlan.id.toString() + 'edit'].fields.initialAdvanceRate.originalValue = (action.ratePlan.initialAdvanceRate * 100).toFixed(2) as any;



  return {
    ...state,
    ratePlanData: newRatePlanData,
    ...newRatePlanEditForm
  };
}

const handleRemoveRatePlan = (state: RatePlanState, action: RatePlanActions.RemoveRatePlanAction): RatePlanState => {

  const mutatedState = omit(state, [action.ratePlanId, action.ratePlanId + 'edit']);

  mutatedState.ratePlanData = mutatedState.ratePlanData!.filter( ratePlan => ratePlan.id !== action.ratePlanId) || [];
  

  return {
    ...mutatedState as any,
  }
}


const handleSetEditRatePlanId = (state: RatePlanState, action: RatePlanActions.SetEditRatePlanIdAction): RatePlanState => {
  return {
    ...state,
    editRatePlanId: action.ratePlanId
  };
}

const handleSetDeleteRateChangeId = (state: RatePlanState, action: RatePlanActions.SetDeleteRateChangeIdAction): RatePlanState => {
  return {
    ...state,
    deleteRateChangeId: action.rateChangeId
  };
}

const handleSetDeleteRatePlanId = (state: RatePlanState, action: RatePlanActions.SetDeleteRatePlanIdAction): RatePlanState => {
  return {
    ...state,
    deleteRatePlanId: action.ratePlanId
  };
}

const handleSetAddRateChangeRatePlanId = (state: RatePlanState, action: RatePlanActions.SetAddRateChangeRatePlanIdAction): RatePlanState => {
  return {
    ...state,
    addRateChangeRatePlanId: action.ratePlanId
  };
}


const handlers: { [x: string]: (state: RatePlanState, action: RatePlanAction) => RatePlanState } =
  {
    [RatePlanActionTypes.ResetToInitialState]: handleResetToInitialState,
    [RatePlanActionTypes.SetFormLoading]: handleSetFormLoading,
    [RatePlanActionTypes.SetFormError]: handleSetFormError,
    [RatePlanActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
    [RatePlanActionTypes.ClearFormError]: handleClearFormError,
    [RatePlanActionTypes.FormChange]: handleFormChange,
    [RatePlanActionTypes.SetLoading]: handleSetLoading,
    [RatePlanActionTypes.SetError]: handleSetError,
    [RatePlanActionTypes.SetErrorMessages]: handleSetErrorMessages,
    [RatePlanActionTypes.SetRatePlanData]: handleSetRatePlanData,
    [RatePlanActionTypes.SetFormOpen]: handleSetFormOpen,
    [RatePlanActionTypes.CancelFormEdits]: handleCancelFormEdits,
    [RatePlanActionTypes.AddRatePlan]: handleAddRatePlan,
    [RatePlanActionTypes.SetEditRatePlanId]: handleSetEditRatePlanId,
    [RatePlanActionTypes.SetDeleteRateChangeId]: handleSetDeleteRateChangeId,
    [RatePlanActionTypes.SetDeleteRatePlanId]: handleSetDeleteRatePlanId,
    [RatePlanActionTypes.SetAddRateChangeRatePlanId]: handleSetAddRateChangeRatePlanId,
    [RatePlanActionTypes.SetRatePlan]: handleSetRatePlan,
    [RatePlanActionTypes.RemoveRatePlan]: handleRemoveRatePlan

  } as any

const RatePlanReducer: Reducer<RatePlanState, RatePlanAction> = (state: RatePlanState | undefined, action: RatePlanAction): RatePlanState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default RatePlanReducer