import * as React from 'react';
import { Header, Segment, Icon, Form, Message, Button, List } from 'semantic-ui-react';
import { TransactionNotesLayoutViewProps } from './interfaces/TransactionNotesLayoutViewProps';

const TransactionNotesLayoutView = (props: TransactionNotesLayoutViewProps) => (
  <>
    <Header as='h4'>
      <Icon name='sticky note outline' />
      Notes
    </Header>
    {props.addNoteForm.open === false && props.transactionId !== null &&
      <Button
        size='mini'
        color='olive'
        basic
        icon='plus'
        floated='right'
        onClick={(e: any) => props.onSetFormOpen(true)}
        loading={props.addNoteForm.loading}
        disabled={props.addNoteForm.loading}
        style={{
          position: 'relative',
          top: '-2.75em'
        }}
      />
    }
    <hr className='scottland-divider-small' />
    {(props.addNoteForm.open || props.transactionId === null) &&
      <Form
        size='small'
        error={props.addNoteForm.error}
        onSubmit={props.onFormSubmit}
      >
        {props.addNoteForm.error &&
          props.addNoteForm.errorMessages &&
          props.addNoteForm.errorMessages.length > 0 &&
          <Segment basic compact style={{ margin: 0, fontSize: 'small' }}>
            <Icon name='exclamation' color='red' />
            Oops.&nbsp;
              {props.addNoteForm.errorMessages
              .map(errorMessage => (<span>{errorMessage}&nbsp;</span>))
            }
          </Segment>
        }
        <Form.TextArea
          id={props.addNoteForm.fields.note.id}
          name={props.addNoteForm.fields.note.id}
          placeholder={props.addNoteForm.fields.note.placeholder}
          value={props.addNoteForm.fields.note.value as any}
          error={props.addNoteForm.fields.note.error}
          onChange={props.onFormChange}
          disabled={props.addNoteForm.loading}
        />
        <Form.Group inline widths='equal'>
          <Form.Field style={{ cursor: 'pointer', position: 'relative', top: '-.3em' }}
            onClick={() => props.onFormChange(
              {
                target: {
                  name: props.addNoteForm.fields.isAdministrative.id,
                  value: !(props.addNoteForm.fields.isAdministrative.value as any)
                }
              }, null
            )}
          >
            {props.allowAdministrativeNotes === true &&
              <>
                <Icon
                  size='large'
                  name={props.addNoteForm.fields.isAdministrative.value as any === true ?
                    'check circle outline' : 'circle outline'
                  }
                  color={props.addNoteForm.fields.isAdministrative.value as any === true ?
                    'olive' : 'grey'
                  }
                />
                {props.addNoteForm.fields.isAdministrative.label}
              </>
            }
          </Form.Field>
          <Form.Field className='add-transaction-note-form-field'>
            <Button
              size='mini'
              color='olive'
              icon='plus circle'
              labelPosition='right'
              content='Add note'
              floated='right'
              type='submit'
              loading={props.addNoteForm.loading}
              disabled={props.addNoteForm.loading}
            />
            {props.transactionId !== null &&
              <Button
                size='mini'
                icon='undo'
                floated='right'
                onClick={(e: any) => props.onSetFormOpen(false)}
                disabled={props.addNoteForm.loading}
              />
            }
          </Form.Field>
        </Form.Group>
      </Form>
    }
    {props.notesListView}
  </>
)

export default TransactionNotesLayoutView