import { FormCreators } from "./creators/interfaces/common/FormCreators";

function isFormTouched(formId: string, state: any, mutatedState: any): boolean {

  const stateFields = state[formId].fields;
  const mutatedFields = mutatedState[formId].fields;

  return (Object.keys(stateFields).some((fieldId: string) => (
    stateFields[fieldId].originalValue !== mutatedFields[fieldId].value && mutatedFields[fieldId].countsAsTouched !== false
  )))

}

function calculateTouchedCount(formId: string, mutatedForm: any): number {

  let touchedCount: number = 0;

  const mutatedFields = mutatedForm[formId].fields;

  Object.keys(mutatedFields).forEach((fieldId: string) => {

    if (mutatedFields[fieldId].value !== mutatedFields[fieldId].originalValue && mutatedFields[fieldId].countsAsTouched !== false) {
      touchedCount = touchedCount + 1;
    }
  });

  return touchedCount;
}

export const handleFormChange =
  (state: any, action: FormCreators.FormChange): any => {

    const mutatedState = {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        fields: {
          ...state[action.formId].fields,
          [action.field]: {
            ...state[action.formId].fields[action.field],
            value: action.value,
            error: action.error,
            touched: true
          }
        }
      }
    }

    const touchedCount = calculateTouchedCount(action.formId, mutatedState);
    const touched = isFormTouched(action.formId, state, mutatedState);

    return {
      ...mutatedState,
      [action.formId]: {
        ...mutatedState[action.formId],
        fields: {
          ...mutatedState[action.formId].fields,
          [action.field]: {
            ...mutatedState[action.formId].fields[action.field]
          }
        },
        error: touched === false ? false : mutatedState[action.formId].error,
        touched,
        touchedCount
      }
    }
  }

export const handleSetFormLoading =
  (state: any, action: FormCreators.SetFormLoading): any => (
    {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        loading: action.loading
      }
    }
  )

export const handleSetFormError =
  (state: any, action: FormCreators.SetFormError): any => (
    {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        error: action.error
      }
    }
  )

export const handleSetFormErrorMessages =
  (state: any, action: FormCreators.SetFormErrorMessages): any => (
    {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        errorMessages: action.errorMessages
      }
    }
  )

export const handleClearFormError =
  (state: any, action: FormCreators.ClearFormError): any => (
    {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        error: false,
        errorMessages: []
      }
    }
  )

export const handleCancelFormEdits =
  (state: any, action: FormCreators.CancelFormEdits): any => {

    const mutatedFields: any = {};

    Object.keys(state[action.formId].fields).forEach(fieldId => {
      mutatedFields[fieldId] = {
        ...state[action.formId].fields[fieldId],
        value: state[action.formId].fields[fieldId].originalValue,
        error: false,
        touched: false
      }
    })

    return {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        fields: mutatedFields,
        touched: false,
        error: false,
        touchedCount: 0
      }
    }
  };


export const handleSyncFormOriginalValues =
  (state: any, action: FormCreators.SyncFormOriginalValues): any => {

    const mutatedFields: any = {};

    Object.keys(state[action.formId].fields).forEach(fieldId => {
      mutatedFields[fieldId] = {
        ...state[action.formId].fields[fieldId],
        originalValue: state[action.formId].fields[fieldId].value,
        error: false,
        touched: false
      }
    });

    return {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        fields: mutatedFields,
        touched: false,
        error: false,
        touchedCount: 0
      }
    }
  };

export const handleSetFormOpen =
  (state: any, action: FormCreators.SetFormOpenAction): any => (
    {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        open: action.open
      }
    }
  )

export const handleClearFormFieldErrors =
  (state: any, action: FormCreators.ClearFormFieldErrorsAction): any => {

    const mutatedFields: any = {};

    Object.keys(state[action.formId].fields).forEach(fieldId => {
      mutatedFields[fieldId] = {
        ...state[action.formId].fields[fieldId],
        error: false,
      }
    });

    return {
      ...state,
      [action.formId]: {
        ...state[action.formId],
        fields: mutatedFields
      }
    }
  };
