import { EntityView } from "../../lib/interfaces/EntityView";
import * as FormCreators from '../creators/FormCreators';
import * as EntityCreators from "./interfaces/EntityCreators";
import { ScottlandUserView } from "../../lib/interfaces/ScottlandUserView";
import { ScottlandBranchView } from "../../lib/interfaces/ScottlandBranchView";
import { ScottlandRatePlanView } from "../../lib/interfaces/ScottlandRatePlanView";

export const EntityActionTypes = {
  ResetToInitialState: 'ENTITY_ACTIONS_ResetToInitialState',
  SetLoading: 'ENTITY_ACTIONS_SetLoading',
  SetError: 'ENTITY_ACTIONS_SetError',
  SetErrorMessages: 'ENTITY_ACTIONS_SetErrorMessages',
  SetEntityData: 'ENTITY_ACTIONS_SetEntityData',
  SetFormLoading: 'ENTITY_ACTIONS_SetFormLoading',
  SetFormError: 'ENTITY_ACTIONS_SetFormError',
  SetFormErrorMessages: 'ENTITY_ACTIONS_SetFormErrorMessages',
  ClearFormError: 'ENTITY_ACTIONS_ClearFormError',
  SetFormOpen: 'ENTITY_ACTIONS_SetFormOpen',
  CancelFormEdits: 'ENTITY_ACTIONS_CancelFormEdits',
  FormChange: 'ENTITY_ACTIONS_FormChange',
  SetUsers: 'ENTITY_ACTIONS_SetUsers',
  SetBranches: 'ENTITY_ACTIONS_SetBranches',
  SetRatePlans: 'ENTITY_ACTIONS_SetRatePlans',
  SetEditMode: 'ENTITY_ACTIONS_SetEditMode',
  SetEntityDeleted: 'ENTITY_ACTIONS_SetEntityDeleted'
}

export const ResetToInitialState: EntityCreators.EntityCreator = (): EntityCreators.EntityAction => {
  return { type: EntityActionTypes.ResetToInitialState };
}

export const SetLoading: EntityCreators.SetLoadingCreator = (loading: boolean): EntityCreators.SetLoadingAction => {
  return {
    type: EntityActionTypes.SetLoading,
    loading
  };
}


export const SetEntityDeleted: EntityCreators.EntityCreator = (loading: boolean): EntityCreators.EntityAction => {
  return {
    type: EntityActionTypes.SetEntityDeleted,
  };
}

export const SetError: EntityCreators.SetErrorCreator = (error: boolean): EntityCreators.SetErrorAction => {
  return {
    type: EntityActionTypes.SetError,
    error
  };
}

export const SetErrorMessages: EntityCreators.SetErrorMessagesCreator = (errorMessages: string[]): EntityCreators.SetErrorMessagesAction => {
  return {
    type: EntityActionTypes.SetErrorMessages,
    errorMessages
  };
}

export const SetFormLoading: EntityCreators.EntityCreator = (formId: string, loading: boolean): EntityCreators.EntityAction => (
  FormCreators.SetFormLoading(
    EntityActionTypes.SetFormLoading,
    formId,
    loading
  )
);


export const SetFormError: EntityCreators.EntityCreator = (formId: string, error: boolean): EntityCreators.EntityAction => (
  FormCreators.SetFormError(
    EntityActionTypes.SetFormError,
    formId,
    error
  )
);

export const SetFormErrorMessages: EntityCreators.EntityCreator = (formId: string, errorMessages: string[]): EntityCreators.EntityAction => (
  FormCreators.SetFormErrorMessages(
    EntityActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
);

export const ClearFormError: EntityCreators.EntityCreator = (formId: string): EntityCreators.EntityAction => (
  FormCreators.ClearFormError(
    EntityActionTypes.ClearFormError,
    formId
  )
);


export const FormChange: EntityCreators.EntityCreator =
  (formId: string, field: string, value: string, error: boolean): EntityCreators.EntityAction => (
    FormCreators.FormChange(
      EntityActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );

export const SetFormOpen: EntityCreators.EntityCreator = (formId: string, loading: boolean): EntityCreators.EntityAction => (
  FormCreators.SetFormOpen(
    EntityActionTypes.SetFormOpen,
    formId,
    loading
  )
);

export const CancelFormEdits: EntityCreators.EntityCreator =
  (formId: string): EntityCreators.EntityAction => (
    FormCreators.CancelFormEdits(
      EntityActionTypes.CancelFormEdits,
      formId
    )
  );


export const SetEntityData: EntityCreators.SetDataboardDataCreator = (entityData: EntityView): EntityCreators.SetEntityDataAction => (
  {
    type: EntityActionTypes.SetEntityData,
    entityData
  }
);

export const SetUsers: EntityCreators.SetUsersCreator = (users: ScottlandUserView[]): EntityCreators.SetUsersAction => (
  {
    type: EntityActionTypes.SetUsers,
    users
  }
);

export interface SetBranchesAction extends EntityCreators.EntityAction {
  branches: ScottlandBranchView[]
}

export const SetBranches: EntityCreators.SetBranchesCreator = (branches: ScottlandBranchView[]): EntityCreators.SetBranchesAction => (
  {
    type: EntityActionTypes.SetBranches,
    branches
  }
);

export const SetRatePlans: EntityCreators.SetRatePlansCreator = (ratePlans: ScottlandRatePlanView[]): EntityCreators.SetRatePlansAction => (
  {
    type: EntityActionTypes.SetRatePlans,
    ratePlans
  }
);

export const SetEditMode: EntityCreators.SetEditModeCreators =
  (editMode: boolean) => (
    {
      type: EntityActionTypes.SetEditMode,
      editMode
    }
  )


