import * as TransactionCreators from "./interfaces/TransactionCreators";
import * as FormCreators from '../creators/FormCreators'
import { DashboardView } from "../../lib/interfaces/DashboardView";
import { ScottlandTransactionNoteView } from "../../lib/interfaces/ScottlandTransactionNoteView";
import { ScottlandTransactionView } from "../../lib/interfaces/ScottlandTransactionView";
import { ScottlandTransactionFileView } from "../../lib/interfaces/ScottlandTransactionFileView";
import { ScottlandTransactionActionResponse } from "../../lib/interfaces/ScottlandTransactionActionResponse";

export const TransactionActionTypes = {
  ResetToInitialState: 'TRANSACTION_ACTIONS_ResetToInitialState',
  SetFormOpen: 'TRANSACTION_ACTIONS_SetFormOpen',
  SetFormLoading: 'TRANSACTION_ACTIONS_SetFormLoading',
  SetFormError: 'TRANSACTION_ACTIONS_SetFormError',
  SetFormErrorMessages: 'TRANSACTION_ACTIONS_SetFormErrorMessages',
  ClearFormError: 'TRANSACTION_ACTIONS_ClearFormError',
  FormChange: 'TRANSACTION_ACTIONS_FormChange',
  CancelFormEdits: 'TRANSACTION_ACTIONS_CancelFormEdits',
  SetLoading: 'TRANSACTION_ACTIONS_SetLoading',
  SetError: 'TRANSACTION_ACTIONS_SetError',
  SetErrorMessages: 'TRANSACTION_ACTIONS_SetErrorMessages',
  SetDashboardData: 'TRANSACTION_ACTIONS_SetDashboardData',
  SetNotes: 'TRANSACTION_ACTIONS_SetNotes',
  AddNote: 'TRANSACTION_ACTIONS_AddNote',
  DeleteNote: 'TRANSACTION_ACTIONS_DeleteNote',
  EditNote: 'TRANSACTION_ACTIONS_EditNote',
  SetEditNoteId: 'TRANSACTION_ACTIONS_SetEditNoteId',
  SetDeleteNoteId: 'TRANSACTION_ACTIONS_SetDeleteNoteId',
  SetDeleteFileId: 'TRANSACTION_ACTIONS_SetDeleteFileId',
  SetFiles: 'TRANSACTION_ACTIONS_SetFiles',
  AddFile: 'TRANSACTION_ACTIONS_AddFile',
  DeleteFile: 'TRANSACTION_ACTIONS_DeleteFile',
  SetTransaction: 'TRANSACTION_ACTIONS_SetTransaction',
  ClearFormFieldErrors:  'TRANSACTION_ACTIONS_ClearFormFieldErrors',
  SetTransactionResponse: 'TRANSACTION_ACTIONS_SetTransactionResponse',
  SetTransactionLogOpen: 'TRANSACTION_ACTIONS_SetTransactionLogOpen'
}


export const ResetToInitialState: TransactionCreators.TransactionCreator = (): TransactionCreators.TransactionAction => (
  { type: TransactionActionTypes.ResetToInitialState }
);

export const SetFormOpen: TransactionCreators.TransactionCreator = (formId: string, loading: boolean): TransactionCreators.TransactionAction => (
  FormCreators.SetFormOpen(
    TransactionActionTypes.SetFormOpen,
    formId,
    loading
  )
);


export const SetFormLoading: TransactionCreators.TransactionCreator = (formId: string, loading: boolean): TransactionCreators.TransactionAction => (
  FormCreators.SetFormLoading(
    TransactionActionTypes.SetFormLoading,
    formId,
    loading
  )
);


export const SetFormError: TransactionCreators.TransactionCreator = (formId: string, error: boolean): TransactionCreators.TransactionAction => (
  FormCreators.SetFormError(
    TransactionActionTypes.SetFormError,
    formId,
    error
  )
);

export const ClearFormFieldErrors: TransactionCreators.TransactionCreator = (formId: string): TransactionCreators.TransactionAction => (
  FormCreators.ClearFormFieldErrors(
    TransactionActionTypes.ClearFormFieldErrors,
    formId
  )
);

export const SetFormErrorMessages: TransactionCreators.TransactionCreator = (formId: string, errorMessages: string[]): TransactionCreators.TransactionAction => (
  FormCreators.SetFormErrorMessages(
    TransactionActionTypes.SetFormErrorMessages,
    formId,
    errorMessages
  )
);

export const ClearFormError: TransactionCreators.TransactionCreator = (formId: string): TransactionCreators.TransactionAction => (
  FormCreators.ClearFormError(
    TransactionActionTypes.ClearFormError,
    formId
  )
);

export const FormChange: TransactionCreators.TransactionCreator =
  (formId: string, field: string, value: string, error: boolean): TransactionCreators.TransactionAction => (
    FormCreators.FormChange(
      TransactionActionTypes.FormChange,
      formId,
      field,
      value,
      error
    )
  );

export const CancelFormEdits: TransactionCreators.TransactionCreator =
  (formId: string): TransactionCreators.TransactionAction => (
    FormCreators.CancelFormEdits(
      TransactionActionTypes.CancelFormEdits,
      formId
    )
  );

export const SetLoading: TransactionCreators.SetLoadingCreator =
  (loading: boolean): TransactionCreators.SetLoadingAction => (
    {
      type: TransactionActionTypes.SetLoading,
      loading
    }
  );

export const SetError: TransactionCreators.SetErrorCreator =
  (error: boolean): TransactionCreators.SetErrorAction => (
    {
      type: TransactionActionTypes.SetError,
      error
    }
  );

export const SetErrorMessages: TransactionCreators.SetErrorMessagesCreator =
  (errorMessages: string[]): TransactionCreators.SetErrorMessagesAction => (
    {
      type: TransactionActionTypes.SetErrorMessages,
      errorMessages
    }
  );

export const SetDashboardData: TransactionCreators.SetDataboardDataCreator = (dashboardData: DashboardView): TransactionCreators.SetDashboardDataAction => (
  {
    type: TransactionActionTypes.SetDashboardData,
    dashboardData
  }
);

export const SetNotes: TransactionCreators.SetNotesCreator =
  (notes: ScottlandTransactionNoteView[]): TransactionCreators.SetNotesAction => (
    {
      type: TransactionActionTypes.SetNotes,
      notes
    }
  )

export const AddNote: TransactionCreators.AddNoteCreator =
  (noteToAdd: ScottlandTransactionNoteView): TransactionCreators.AddNoteAction => (
    {
      type: TransactionActionTypes.AddNote,
      noteToAdd
    }
  )

export const DeleteNote: TransactionCreators.DeleteNoteCreator =
  (noteIdToDelete: number): TransactionCreators.DeleteNoteAction => (
    {
      type: TransactionActionTypes.DeleteNote,
      noteIdToDelete
    }
  )

export const EditNote: TransactionCreators.EditNoteCreator =
  (edittedNote: ScottlandTransactionNoteView): TransactionCreators.EditNoteAction => (
    {
      type: TransactionActionTypes.EditNote,
      edittedNote
    }
  )


export const SetEditNoteId: TransactionCreators.SetEditNoteIdCreator =
  (editNoteId: number | null): TransactionCreators.SetEditNoteIdAction => (
    {
      type: TransactionActionTypes.SetEditNoteId,
      editNoteId
    }
  )

export const SetDeleteNoteId: TransactionCreators.SetDeleteNoteIdCreator =
  (deleteNoteId: number | null): TransactionCreators.SetDeleteNoteIdAction => (
    {
      type: TransactionActionTypes.SetDeleteNoteId,
      deleteNoteId
    }
  )


export const SetDeleteFileId: TransactionCreators.SetDeleteFileIdCreator =
  (deleteFileId: number | null): TransactionCreators.SetDeleteFileIdAction => (
    {
      type: TransactionActionTypes.SetDeleteFileId,
      deleteFileId
    }
  )

  export const SetFiles: TransactionCreators.SetFilesCreator =
  (files: ScottlandTransactionFileView[]): TransactionCreators.SetFilesAction => (
    {
      type: TransactionActionTypes.SetFiles,
      files
    }
  )

export const AddFile: TransactionCreators.AddFileCreator =
  (fileToAdd: ScottlandTransactionFileView): TransactionCreators.AddFileAction => (
    {
      type: TransactionActionTypes.AddFile,
      fileToAdd
    }
  )

export const DeleteFile: TransactionCreators.DeleteFileCreator =
  (fileIdToDelete: number): TransactionCreators.DeleteFileAction => (
    {
      type: TransactionActionTypes.DeleteFile,
      fileIdToDelete
    }
  )


export const SetTransaction: TransactionCreators.SetTransactionCreator =
  (transaction: ScottlandTransactionView): TransactionCreators.SetTransactionAction => (
    {
      type: TransactionActionTypes.SetTransaction,
      transaction
    }
  )

  
export const SetTransactionResponse :TransactionCreators.SetTransactionResponseCreator =
  (actionResponse :ScottlandTransactionActionResponse) :TransactionCreators.SetTransactionResponseAction => (
    {
      type: TransactionActionTypes.SetTransactionResponse,
      actionResponse
    }
  )

  export const  SetTransactionLogOpen :TransactionCreators.SetTransactionLogOpenCreator =
    (open :boolean) :TransactionCreators.SetTransactionLogOpenAction => (
      {
        type: TransactionActionTypes.SetTransactionLogOpen,
        open
      }
    )
  






