import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { Button, Dropdown, Form, Grid, Icon, Message } from 'semantic-ui-react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { AddEntityViewProps } from './interfaces/AddEntityViewProps';

const AddEntityView = (props: AddEntityViewProps) => (

    <Form
      size='tiny'
      className='entity-edit-form'
      error={props.addEntityForm.error}
      loading={props.addEntityForm.loading}
    >
      <Grid columns='2' stackable>
        <Grid.Column>
          <Form.Field
            inline
            error={props.addEntityForm.fields.name.error}
            fluid
          >
            <label>{props.addEntityForm.fields.name.label}</label>
            <input
              id={props.addEntityForm.fields.name.id}
              name={props.addEntityForm.fields.name.id}
              value={props.addEntityForm.fields.name.value as any}
              placeholder={props.addEntityForm.fields.name.placeholder}
              onChange={(e) => props.onFormChange(e, null)}
              style={{ width: '80%', float: 'right' }}
            />
          </Form.Field>
          <Form.Field
            inline
            error={props.addEntityForm.fields.branch.error}
          >
            <label>{props.addEntityForm.fields.branch.label}</label>
            <Dropdown
              id={props.addEntityForm.fields.branch.id}
              name={props.addEntityForm.fields.branch.id}
              placeholder={props.addEntityForm.fields.branch.placeholder}
              value={props.addEntityForm.fields.branch.value as any}
              options={props.addEntityForm.fields.branch.options}
              onChange={props.onFormChange}
              selection
              style={{ width: '80%', float: 'right' }}
            />
          </Form.Field>
          <Form.Field
            inline
            fluid
            onClick={() => props.onFormChange(
              {
                target: {
                  name: props.addEntityForm.fields.active.id,
                  value: !(props.addEntityForm.fields.active.value as any)
                }
              }, null
            )}
            style={{
              cursor: 'pointer'
            }}
          >
            <label
              style={{
                cursor: 'pointer'
              }}
            >{
                props.addEntityForm.fields.active.label}
            </label>
            <span style={{ float: 'right' }}>
              {props.addEntityForm.fields.active.value ? 'No' : 'Yes'}
              <Icon
                name='archive'
                circular
                color={props.addEntityForm.fields.active.value ? 'grey' : 'olive'}
                style={{
                  marginLeft: '1em'
                }}
              />
            </span>
          </Form.Field>
          <Form.Dropdown
            id={props.addEntityForm.fields.members.id}
            name={props.addEntityForm.fields.members.id}
            label={props.addEntityForm.fields.members.label}
            error={props.addEntityForm.fields.members.error}
            placeholder={props.addEntityForm.fields.members.placeholder}
            value={props.addEntityForm.fields.members.value as any}
            onChange={props.onFormChange}
            options={props.addEntityForm.fields.members.options}
            selection
            multiple
            fluid
            search
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Field
            inline
            error={props.addEntityForm.fields.establishedAt.error}
            fluid
          >
            <label>{props.addEntityForm.fields.establishedAt.label}</label>
            <input
              id={props.addEntityForm.fields.establishedAt.id}
              name={props.addEntityForm.fields.establishedAt.id}
              value={props.addEntityForm.fields.establishedAt.value as any}
              placeholder={props.addEntityForm.fields.establishedAt.placeholder}
              onChange={(e) => props.onFormChange(e, null)}
              style={{ width: '60%', float: 'right' }}
              type='date'
            />
          </Form.Field>

          <Form.Field
            inline
            error={props.addEntityForm.fields.ratePlan.error}
          >
            <label>{props.addEntityForm.fields.ratePlan.label}</label>
            <Dropdown
              id={props.addEntityForm.fields.ratePlan.id}
              name={props.addEntityForm.fields.ratePlan.id}
              placeholder={props.addEntityForm.fields.ratePlan.placeholder}
              value={props.addEntityForm.fields.ratePlan.value as any}
              options={props.addEntityForm.fields.ratePlan.options}
              onChange={props.onFormChange}
              selection
              style={{ width: '60%', float: 'right' }}
            />
          </Form.Field>

          <Form.Input
            icon
            inline
            iconPosition='left'
            error={props.addEntityForm.fields.balanceForwardPrincipal.error}
            label={props.addEntityForm.fields.balanceForwardPrincipal.label}
            className='entity-form-balance-field'
          >
            <Icon name='dollar sign' />
            <MaskedInput
              id={props.addEntityForm.fields.balanceForwardPrincipal.id}
              name={props.addEntityForm.fields.balanceForwardPrincipal.id}
              placeholder={props.addEntityForm.fields.balanceForwardPrincipal.placeholder}
              value={props.addEntityForm.fields.balanceForwardPrincipal.value as number}
              mask={createNumberMask({
                prefix: '',
                suffix: '',
                allowDecimal: true,
                decimalLimit: 2,
                allowNegative: true
              })}
              onChange={(e) => props.onFormChange(e, null)}
              guide={false}
            />

          </Form.Input>
          <Form.Input
            icon
            iconPosition='left'
            error={props.addEntityForm.fields.balanceForwardInterest.error}
            label={props.addEntityForm.fields.balanceForwardInterest.label}
            inline
            className='entity-form-balance-field'
          >
            <Icon name='dollar sign' />
            <MaskedInput
              id={props.addEntityForm.fields.balanceForwardInterest.id}
              name={props.addEntityForm.fields.balanceForwardInterest.id}
              placeholder={props.addEntityForm.fields.balanceForwardInterest.placeholder}
              value={props.addEntityForm.fields.balanceForwardInterest.value as number}
              mask={createNumberMask({
                prefix: '',
                suffix: '',
                allowDecimal: true,
                decimalLimit: 2,
                allowNegative: true
              })}
              onChange={(e) => props.onFormChange(e, null)}
              guide={false}
            />
          </Form.Input>
        </Grid.Column>
      </Grid>
      <Message
        error
        icon='exclamation'
        header='Oops. Something went wrong adding this entity.'
        list={props.addEntityForm.errorMessages}
      />
    <Button
      size='mini'
      color='olive'
      icon='plus circle'
      labelPosition='right'
      content='Add Entity'
      floated='right'
      type='submit'
      loading={props.addEntityForm.loading}
      disabled={props.addEntityForm.loading}
      onClick={props.onFormSubmit}
    />
    <Button
      size='mini'
      icon='undo'
      labelPosition='right'
      content='Close'
      floated='right'
      onClick={props.onFormCancel}
      disabled={props.addEntityForm.loading}
    />
    <div style={{ clear: 'both' }} />
  </Form>
)

export default AddEntityView