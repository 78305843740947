import React, { FunctionComponent } from "react";
import { Link } from 'react-router-dom';
import { Breadcrumb, Header, Icon, Message, Segment, Statistic } from "semantic-ui-react";
import { EntityViewProps } from "./interfaces/EntityViewProps";

const EntityView: FunctionComponent<EntityViewProps> =
  (props: EntityViewProps) =>
    (
      <Segment basic>
        {props.loading === false &&
          <Header as='h3' style={{ position: 'relative', top: '.25em' }}>
            <>
              <Icon name='book' style={{ display: 'inline', paddingRight: '.25em' }} />
              {props.entity.name}
              <Breadcrumb>
                <Breadcrumb.Divider icon='right angle' />
                {props.administrativeMode === true &&
                  <>
                    <Breadcrumb.Section>
                      <Icon name='university' />
                      <Link
                        className='scottland-link'
                        to={`/branches/${props.entity.branch.key}`}
                      >
                        {props.entity.branch.value}
                      </Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section>
                      <Link className='scottland-link' to='/dashboard'>
                        Back to branches
                  </Link>
                    </Breadcrumb.Section>
                  </>
                }
                {props.administrativeMode === false &&
                  <Breadcrumb.Section>
                    <Icon name='university' />
                    <Link
                      className='scottland-link'
                      to={`/dashboard`}
                    >
                      Back to dashboard
                    </Link>
                  </Breadcrumb.Section>
                }

              </Breadcrumb>
            </>
          </Header>
        }
        <hr className='scottland-divider' />
        <Message color='olive'>
          <Statistic.Group widths='five' size='mini'>
            <Statistic>
              <Statistic.Value>
                <span style={{ color: props.entity.principal < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {props.entity.formattedPrincipal}
                </span>
              </Statistic.Value>
              <Statistic.Label>Principal</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>
                <span style={{ color: props.entity.interest < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {props.entity.formattedInterest}
                </span>
              </Statistic.Value>
              <Statistic.Label>Interest</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>
                <span style={{ color: props.entity.totalBalance < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {props.entity.formattedTotalBalance}
                </span>
              </Statistic.Value>
              <Statistic.Label>Total</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{props.entity.ratePlan.formattedDepositRate}</Statistic.Value>
              <Statistic.Label>Investment Rate</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{props.entity.ratePlan.formattedAdvanceRate}</Statistic.Value>
              <Statistic.Label>Borrowing Rate</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Message>
        {props.children}

      </Segment>
    )

export default EntityView