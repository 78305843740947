import moment from 'moment';
import * as React from 'react';
import { Comment, Form, Icon, Segment, Header, Label } from 'semantic-ui-react';
import { TransactionLogViewProps } from './interfaces/TransactionLogViewProps';

const TransactionLogView = (props: TransactionLogViewProps) => (
  <>
    <Form.Field inline fluid style={{}}>
      {props.transaction !== null &&
        <>
          <label
            className='transaction-status-label'
            onClick={props.onToggleOpen}
          >
            <Icon
              name={props.open === true ? 'angle down' : 'angle right'}
            />
            <span>Status</span>
          </label>
          <div style={{ float: 'right', marginRight:'.25em' }}>
            <span
              style={{
                paddingRight: '1em',
                fontSize: '.92857143em',
                fontWeight: 700
              }}
            >
              <i>{props.transaction.status}</i>
            </span>
            {props.transaction.status === 'Approved' &&
              <Icon size='small' inverted circular name='checkmark' color='green' />
            }
            {props.transaction.status === 'Pending' &&
              <Icon size='small' inverted circular name='hourglass one' color='grey' />
            }
            {props.transaction.status === 'Declined' &&
              <Icon size='small' inverted circular name='thumbs down' color='red' />
            }
            {props.transaction.status === 'Admin Contact' &&
              <Icon size='small' inverted circular name='question' color='yellow' />
            }
          </div>
        </>
      }
      {props.showTransactionBranchWarning &&
        <Label basic color='orange' style={{ width: '100%', marginTop: '.5em' }}>
          <Icon name='exclamation circle' />
          This transaction would exceed the available funds of the branch.
       </Label>
      }
    </Form.Field>
    {props.transaction !== null && props.open &&
      <Segment basic style={{paddingTop:0}}>
        {props.transaction.log.length > 0 ?
          <Comment.Group size='mini'>
            {props.transaction.log.map(logEntry => (
              <Comment>
                <Comment.Content>
                  <Comment.Author as='a'>
                    {logEntry.createdBy}
                  </Comment.Author>
                  <Comment.Metadata>
                    <div>
                      {`${moment.unix(logEntry.createdAt).format('MM/DD/YYYY hh:mm a')} (${moment.duration(moment.unix(logEntry.createdAt).diff(new Date())).humanize(true)}) `}
                    </div>
                  </Comment.Metadata>
                  <Comment.Text>{logEntry.note}</Comment.Text>
                </Comment.Content>
              </Comment>
            ))}
          </Comment.Group>
          :
          <Header as='h5' style={{ margin: 0, padding: 0 }} textAlign='center'>
            This transaction has no log entries.
          </Header>
        }
      </Segment>
    }
  </>

)

export default TransactionLogView