import * as React from 'react';
import { Header, Icon, Segment, Modal, Button } from 'semantic-ui-react';
import { ActionModalContentProps } from '../interfaces/ActionModalProps';

const DeleteModal = (props: ActionModalContentProps) => (
  <Modal
    basic
    size='tiny'
    closeIcon
    onClose={props.onCloseModal}
    open={props.actionModalForm.open}
  >
    <Modal.Header>
      Confirm Delete
  </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        Are you sure you wish to DELETE the transaction?
    </Modal.Description>
    <Modal.Description>
        All record of this transaction will be deleted!
    </Modal.Description>
    <Modal.Description>
        <strong>This action is <i>not</i> reversible.</strong>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button
        color='grey'
        labelPosition='right'
        icon='undo'
        content='Close'
        inverted
        size='tiny'
        onClick={props.onCloseModal}
      />
      <Button
        color='red'
        labelPosition='right'
        icon='x'
        content='Delete'
        inverted
        size='tiny'
        onClick={props.onConfirm}
      />
    </Modal.Actions>
  </Modal>
)

export default DeleteModal