
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ScottlandRateChangeView } from '../../lib/interfaces/ScottlandRateChangeView';
import { FormState } from '../../redux/interfaces/common/FormState';
import { RateChangeTableSettingsFormFields } from '../../redux/interfaces/RatePlanState';
import StoreState from '../../redux/interfaces/StoreState';
import { RatePlanContainerProps } from './interfaces/RatePlanContainerProps';
import { onAddRateChange, onAddRatePlan, onDeleteRatePlan, onCancelFormEdits, onDeleteRateChange, onFormChange, onLoad, onSetAddRateChangeRatePlanId, onSetDeleteRateChangeId, onSetDeleteRatePlanId, onSetEditRatePlanId, onSetFormOpen, onUpdateRatePlan } from './thunks/RatePlanThunks';
import { RateChangeTableSettingsProps, RatePlanDetailsViewProps } from './views/interfaces/RatePlanDetailsViewProps';
import { RatePlanLayoutViewProps } from './views/interfaces/RatePlanLayoutViewProps';
import RatePlanDetailsView from './views/RatePlanDetailsView';
import RatePlanErrorView from './views/RatePlanErrorView';
import RatePlanView from './views/RatePlanLayoutView';
import RatePlanLoadingView from './views/RatePlanLoadingView';

export class RatePlanContainer extends React.Component<RatePlanContainerProps> {

  public componentWillMount() {

    if (this.props.App.authenticationLoading === false &&
      (this.props.App.authenticationToken === null ||
      this.props.App.authenticationToken.claims.is_administrator === false)) {
      this.props.history.push('/404');
      return;
    }

    this.props.onLoad();
  }

  public render(): React.ReactNode {

    if (this.props.RatePlan.loading) {
      return React.createElement(RatePlanLoadingView);
    }

    if (this.props.RatePlan.error) {
      return React.createElement(RatePlanErrorView, { errorMessages: this.props.RatePlan.errorMessages })
    }

    if (this.props.RatePlan.ratePlanData != null) {

      let children: React.ReactNode[] = [];

      const rateChangeTablesSettings: RateChangeTableSettingsProps = this.calculateRateChangeTableSettings();

      const ratePlanEditForms: any = {};

      for (const ratePlan of this.props.RatePlan.ratePlanData) {

        ratePlanEditForms[(ratePlan.id + 'edit')] = this.props.RatePlan[(ratePlan.id + 'edit' as any)];
      }

      const rateplanDetailsViewProps: RatePlanDetailsViewProps = {
        editRatePlanId: this.props.RatePlan.editRatePlanId,
        deleteRateChangeId: this.props.RatePlan.deleteRateChangeId,
        deleteRatePlanId: this.props.RatePlan.deleteRatePlanId,
        addRateChangeRatePlanId: this.props.RatePlan.addRateChangeRatePlanId,
        onSetEditRatePlanId: this.props.onSetEditRatePlanId,
        onSetDeleteRateChangeId: this.props.onSetDeleteRateChangeId,
        onSetDeleteRatePlanId: this.props.onSetDeleteRatePlanId,
        onSetAddRateChangeRatePlanId: this.props.onSetAddRateChangeRatePlanId,
        addRateChangeForm: this.props.RatePlan.addRateChangeForm,
        onAddRateChange: this.props.onAddRateChange,
        deleteRateChangeForm: this.props.RatePlan.deleteRateChangeForm,
        onEditRatePlan: this.props.onUpdateRatePlan,
        onDeleteRateChange: this.props.onDeleteRateChange,
        onDeleteRatePlan: this.props.onDeleteRatePlan,
        deleteRatePlanForm: this.props.RatePlan.deleteRatePlanForm,
        onSetFormOpen: (formId: string, open: boolean) => this.props.onSetFormOpen(formId, open),
        onCancelFormEdits: (formId: string) => this.props.onCancelFormEdits(formId),
        onFormChange: this.props.onFormChange,
        ratePlans: this.props.RatePlan.ratePlanData,
        ...rateChangeTablesSettings,
        ...ratePlanEditForms
      }

      children.push(React.createElement(RatePlanDetailsView, rateplanDetailsViewProps));

      const rateplanViewProps: RatePlanLayoutViewProps = {
        addRatePlanForm: this.props.RatePlan.addRatePlanForm,
        onFormChange: (e: any, data: any) => this.props.onFormChange(this.props.RatePlan.addRatePlanForm.id, e, data),
        onFormCancel: () => this.props.onCancelFormEdits(this.props.RatePlan.addRatePlanForm.id),
        onFormSubmit: this.props.onAddRatePlan,
        onSetFormOpen: (open: boolean) => this.props.onSetFormOpen(this.props.RatePlan.addRatePlanForm.id, open),
        ratePlanCount: this.props.RatePlan.ratePlanData.length,
        children
      }

      return React.createElement(RatePlanView, rateplanViewProps);
    }

    return null;
  }

  private calculateRateChangeTableSettings(): RateChangeTableSettingsProps {

    const rateChangeTablesSettings: RateChangeTableSettingsProps = {};

    if (this.props.RatePlan.ratePlanData == null) {
      return rateChangeTablesSettings;
    }

    for (const ratePlan of this.props.RatePlan.ratePlanData) {

      const tableSettingsForm: FormState<RateChangeTableSettingsFormFields> = this.props.RatePlan[ratePlan.id];

      const filteredRateChanges = this.props.RatePlan.ratePlanData.find(x => x.id === ratePlan.id)!
        .rateChanges.filter((rateChange: ScottlandRateChangeView) => (
          (rateChange.isDepositRate === true && tableSettingsForm.fields.showDepositRates.value as unknown as boolean === true ||
            rateChange.isDepositRate === false && tableSettingsForm.fields.showAdvanceRates.value as unknown as boolean === true)
        ));

      let currentPage = parseInt(tableSettingsForm.fields.activePage.value as string, 10);
      const pageSize = parseInt(tableSettingsForm.fields.itemsPerPage.value as string, 10);
      const totalPages = Math.ceil(filteredRateChanges.length / pageSize);

      const startIndex = currentPage <= totalPages ?
        (currentPage - 1) * pageSize :
        (totalPages - 1) * pageSize;

      const endIndex = Math.min(startIndex + pageSize - 1, filteredRateChanges.length - 1);

      const paginatedFilteredRateChanges = filteredRateChanges.slice(startIndex, endIndex + 1)

      rateChangeTablesSettings[ratePlan.id] = {
        totalPages: totalPages,
        totalItems: this.props.RatePlan.ratePlanData.find(x => x.id === ratePlan.id)!.rateChanges.length,
        filteredItemsCount: filteredRateChanges.length,
        filteredPaginatedRateChanges: paginatedFilteredRateChanges,
        tableSettingsForm: tableSettingsForm,
        onTableSettingFormChange: (e: any, data: any) => this.props.onFormChange(ratePlan.id as unknown as string, e, data)
      }

    };

    return rateChangeTablesSettings;
  }
}


const mapStateToProps = (state: StoreState) => ({ App: state.App, RatePlan: state.RatePlan });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  onFormChange,
  onLoad,
  onCancelFormEdits,
  onSetFormOpen,
  onAddRatePlan,
  onUpdateRatePlan,
  onSetEditRatePlanId,
  onSetDeleteRateChangeId,
  onSetDeleteRatePlanId,
  onSetAddRateChangeRatePlanId,
  onAddRateChange,
  onDeleteRateChange,
  onDeleteRatePlan
}, dispatch);

export default withRouter(<any>connect(
  mapStateToProps,
  mapDispatchToProps
)(RatePlanContainer));


