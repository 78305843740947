import * as React from 'react';
import { Button, Form, Grid, Image, Message, Segment } from 'semantic-ui-react';
import { LoginViewProps } from './interfaces/LoginViewProps';
import Config from '../../../config/Config';

const LoginView = (props: LoginViewProps) => (
  <Segment
    placeholder
    basic
    style={{ backgroundColor: '#563922', height: '100vh' }}
  >
    <Image
      src={`${Config.apiUrl}/login-image`}
      size='big'
      centered
      bordered
      rounded
    />
    <Grid columns='1' textAlign='center' verticalAlign='middle'>
      <Grid.Column>
        <Form
          size='large'
          className='scottland-login-form'
          error={props.form.error}
          onSubmit={props.onFormSubmit}
        >
          <Message
            icon='exclamation triangle'
            header='Sorry!'
            content=' Your login attempt failed.'
            error
          />
          <Form.Input
            id={props.form.fields.username.id}
            name={props.form.fields.username.id}
            placeholder={props.form.fields.username.placeholder}
            value={props.form.fields.username.value}
            error={props.form.fields.username.error}
            onChange={props.onFormChange}
            fluid
            icon='user'
            iconPosition='left'
          />
          <Form.Input
            id={props.form.fields.password.id}
            name={props.form.fields.password.id}
            placeholder={props.form.fields.password.placeholder}
            value={props.form.fields.password.value}
            error={props.form.fields.password.error}
            onChange={props.onFormChange}
            icon='lock'
            iconPosition='left'
            type='password'
          />
          <Button
            color='olive'
            fluid
            size='large'
            type='submit'
            disabled={props.form.loading}
            loading={props.form.loading}
          >
            Login
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  </Segment>
)

export default LoginView