import * as React from 'react';
import { Segment, Comment, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { TransactionNoteViewProps } from './interfaces/TransactionNoteViewProps';

const TransactionNoteView = (props: TransactionNoteViewProps) => (
  <>
    <Comment.Author as='a'>
      {props.note.createdBy}
    </Comment.Author>
    <Comment.Metadata>
      <div>
        {`${moment.unix(props.note.createdAt).format('MM/DD/YYYY hh:mm a')} (${moment.duration(moment.unix(props.note.createdAt).diff(new Date())).humanize(true)}) `}
      </div>
    </Comment.Metadata>
    {props.allowAdministrativeNotes &&
    <div>
      <Comment.Metadata>
        <div>
          <Icon
            name={props.note.isAdministrative ? 'eye slash' : 'eye'}
          />
          {props.note.isAdministrative ? 'Administrative note' : 'Visible to members'}
        </div>
      </Comment.Metadata>
    </div>
    }
    <Comment.Text>{props.note.note}</Comment.Text>
    <Comment.Actions>
      <a onClick={() => props.onSetEditNoteId(props.note.id)}><Icon name='pencil' />Edit</a>
      <a onClick={() => props.onSetDeleteNoteId(props.note.id)}><Icon name='delete' />Delete</a>
    </Comment.Actions>
  </>
)

export default TransactionNoteView 