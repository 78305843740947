import * as React from 'react';
import { Header, Icon, Segment, Table, Dropdown, Pagination, Label, Form, Button, Grid, Input } from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PendingTransactionTableProps } from './interfaces/PendingTransactionsTableProps';

const PendingTransactionsTable = (props: PendingTransactionTableProps) => (
  <Segment basic>
    <Header as='h3' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
      Pending Transactions
      {props.loading === true &&
        <Icon
          name='sync alternate'
          color='olive'
          loading
          size='mini'
          style={{fontSize:'.75em', marginLeft:'.5em'}}
        />
      }
      {props.loading === false &&
        <Label
          color='olive'
          circular
          size='tiny'
        >
          {`${props.totalTransactionsCount}`}
        </Label>
      }
    </Header>
    <Button
      as={Link}
      to='/add-transaction'
      color='olive'
      icon='plus'
      size='mini'
      content='Add Transaction'
      labelPosition='right'
      floated='right'
      style={{ float: 'right' }}
    />
    <hr className='scottland-divider-small' style={{ clear: 'both', marginBottom: '1em', marginTop: '.5em' }} />
    {props.transactions.length > 0 && props.loading === false &&
      <Form style={{ paddingTop: '.5em' }}>
        <Form.Group inline widths='equal'>
          <Form.Field>
            <Dropdown
              id={props.tableSettingsForm.fields.itemsPerPage.id}
              name={props.tableSettingsForm.fields.itemsPerPage.id}
              label={props.tableSettingsForm.fields.itemsPerPage.label}
              placeholder={props.tableSettingsForm.fields.itemsPerPage.placeholder}
              value={props.tableSettingsForm.fields.itemsPerPage.value as any}
              options={props.tableSettingsForm.fields.itemsPerPage.options}
              onChange={props.onTableSettingFormChange}
              inline
            />
            <label>&nbsp;{props.tableSettingsForm.fields.itemsPerPage.label}</label>
          </Form.Field>
          <Form.Field
            style={{
              textAlign: 'right',
              padding: 0
            }}
          >
            {props.totalPages > 1 &&
              <Pagination
                activePage={(props.tableSettingsForm.fields.activePage.value as any) <= props.totalPages ?
                  props.tableSettingsForm.fields.activePage.value as string :
                  props.totalPages}
                boundaryRange={2}
                onPageChange={(e, { activePage }) => props.onTableSettingFormChange(
                  { target: { name: props.tableSettingsForm.fields.activePage.id, value: activePage } }, null)
                }
                size='mini'
                siblingRange={1}
                totalPages={props.totalPages}
              />
            }
          </Form.Field>
        </Form.Group>
      </Form>
    }
    {props.transactions.length <= 0 && props.loading === false &&
      <Segment basic textAlign='center'>
        <Header as='h5' style={{ margin: 0 }}>
          There are no pending transactions.
        </Header>
      </Segment>
    }
    {props.transactions.length > 0 && props.loading === false &&
      <Table
        compact
        striped
        size='small'
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell collapsing>Effective Date</Table.HeaderCell>
            <Table.HeaderCell>For Entity</Table.HeaderCell>
            <Table.HeaderCell collapsing>Type</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Amount</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.transactions.map(transaction => (
            <Table.Row>
              <Table.Cell collapsing>
                {transaction.status === 'Approved' &&
                  <Icon size='small' inverted circular name='checkmark' color='green' />
                }
                {transaction.status === 'Pending' &&
                  <Icon size='small' inverted circular name='hourglass one' color='grey' />
                }
                {transaction.status === 'Declined' &&
                  <Icon size='small' inverted circular name='x' color='red' />
                }
                {transaction.status === 'Admin Contact' &&
                  <Icon size='small' inverted circular name='question' color='yellow' />
                }
              </Table.Cell>
              <Table.Cell>
                {moment.unix(transaction.effectiveAt).format("l")}
              </Table.Cell>
              <Table.Cell collapsing>
                <Link className='scottland-link' to={`/entity/${transaction.forEntity.key}`}>
                  {transaction.forEntity.value}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {transaction.type}
              </Table.Cell>
              <Table.Cell>
                {transaction.description}
              </Table.Cell>
              <Table.Cell textAlign='right' collapsing >
                <span style={{ color: transaction.amount < 0 ? '#AA0000' : '#666666', fontWeight: 'bold' }}>
                  {transaction.formattedAmount}
                </span>
              </Table.Cell>
              <Table.Cell textAlign='right' collapsing={true}>
                <Link to={`/transaction/${transaction.id}`}>
                  <Icon name='pencil' color='brown' />
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    }
  </Segment >
)

export default PendingTransactionsTable