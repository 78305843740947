import * as React from 'react';
import { Comment, Header, Icon, Segment, Label, Grid, Button, Container } from 'semantic-ui-react';
import { EntityDetailsViewProps } from './interfaces/EntityDetailsViewProps';
import moment from 'moment';

const EntityDetailsView = (props: EntityDetailsViewProps) => (
  <Segment basic>
    <Header as='h5' style={{ width: '50%', marginRight: 0, display: 'inline' }}>
      Entity Details
    </Header>
    {props.allowEdit === true && props.editEntityForm.open === false &&
      <Button
        icon='edit'
        color='olive'
        basic
        compact
        floated='right'
        size='tiny'
        onClick={(e: any) => props.onSetFormOpen(true)}
        disabled={props.editEntityForm.loading}
        style={{ marginBottom: '.25em' }}
      />
    }
    <hr className='scottland-divider-small' style={{ clear: 'both', marginBottom: '1em', marginTop: '.5em' }} />
    <Grid columns='2'>
      <Grid.Column>
        <Comment.Group size='large'>
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Name</Comment.Author>
              <Comment.Metadata>
                {props.entity.name}
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Branch</Comment.Author>
              <Comment.Metadata>
                {props.entity.branch.value}
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
          {props.allowEdit === true &&
            <Comment>
              <Comment.Content>
                <Comment.Author as='span'>Archived</Comment.Author>
                <Comment.Metadata>
                  {props.entity.active ? 'No' : 'Yes'}
                </Comment.Metadata>
              </Comment.Content>
            </Comment>
          }
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Members</Comment.Author>
              <Comment.Metadata>
                {Object.keys(props.entity.users)
                  .map(user => (
                    <Label>{props.entity.users[user as any]}</Label>
                  ))
                }
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
        </Comment.Group>
      </Grid.Column>
      <Grid.Column>
        <Comment.Group size='large'>
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Established</Comment.Author>
              <Comment.Metadata>
                {moment.unix(props.entity.establishedAt).format("MMMM Do, YYYY")}
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Rate Plan</Comment.Author>
              <Comment.Metadata>
                {props.entity.ratePlan.name}
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Balance Forward Principal</Comment.Author>
              <Comment.Metadata>
                {props.entity.formattedBalanceForwardPrincipal}
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
          <Comment>
            <Comment.Content>
              <Comment.Author as='span'>Balance Forward Interest</Comment.Author>
              <Comment.Metadata>
                {props.entity.formattedBalanceForwardInterest}
              </Comment.Metadata>
            </Comment.Content>
          </Comment>
        </Comment.Group>
      </Grid.Column>
    </Grid>

  </Segment>
)

export default EntityDetailsView