import moment from "moment";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import Config from "../../../config/Config";
import * as AuthenticationService from '../../../lib/Authentication/AuthenticationService';
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";
import { ScottlandEntityRequest } from "../../../lib/interfaces/ScottlandEntityRequest";
import * as ScottlandApi from "../../../lib/ScottlandApi";
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import * as EntityCreators from "../../../redux/creators/EntityCreators";
import { FormField } from "../../../redux/interfaces/common/FormField";
import { FormState } from "../../../redux/interfaces/common/FormState";
import { TransactionsTableSettingsFormFields } from "../../../redux/interfaces/EntityState";
import StoreState from "../../../redux/interfaces/StoreState";

export function onLoadEntity(entityId: string): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(EntityCreators.ResetToInitialState())
		dispatch(EntityCreators.SetLoading(true));
		dispatch(EntityCreators.SetError(false));

		const appState = getState().App;

		try {


			const entityDataPromise = ScottlandApi.GetEntityView(entityId);


			const promises: Promise<any>[] = [entityDataPromise];

			let usersPromise = null;
			let ratePlansPromise = null;
			let branchesPromise = null;

			if (appState.authenticationToken !== null &&
				appState.authenticationToken.claims.is_administrator === true) {

				usersPromise = ScottlandApi.GetUsers();
				ratePlansPromise = ScottlandApi.GetRatePlans();
				branchesPromise = ScottlandApi.GetBranches();

				promises.push(usersPromise);
				promises.push(ratePlansPromise);
				promises.push(branchesPromise);
			}

			await Promise.all(promises);

			dispatch(EntityCreators.SetEntityData(await entityDataPromise));
			usersPromise && dispatch(EntityCreators.SetUsers(await usersPromise));
			branchesPromise && dispatch(EntityCreators.SetBranches(await branchesPromise));
			ratePlansPromise && dispatch(EntityCreators.SetRatePlans(await ratePlansPromise));

		}
		catch {
			dispatch(EntityCreators.SetError(true));
		}
		finally {
			dispatch(EntityCreators.SetLoading(false));
		}
	}
}

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		let targetField: (string | null | undefined) = e.target.name;
		let fieldValue: (string | null | undefined) = e.target.value;

		if (targetField === null || targetField === undefined || targetField === '') {
			targetField = data.name;

			if (data.type === 'checkbox') {
				fieldValue = data.checked;
			}
			else {
				fieldValue = data.value;
			}
		}

		if (targetField === null ||
			targetField === undefined) {
			throw new TypeError(`No target field sent to change handler: ${targetField}`);
		}

		const formState = (getState().Entity as any)[formId as any] as FormState<TransactionsTableSettingsFormFields>;

		if (formState === null ||
			formState === undefined) {
			throw new TypeError(`Unknown form ${formId}`);
		}

		const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

		if (formFieldState === null ||
			formFieldState === undefined) {
			throw new TypeError(`Unknown form field ${targetField}`);
		}

		const fieldIsValid = Validate(fieldValue,
			formFieldState.validation.required,
			formFieldState.validation.validators);

		dispatch(EntityCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

	}
}

export function onCancelFormEdits(formId: string): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(EntityCreators.CancelFormEdits(formId));
	}
}

export function onSetFormOpen(formId: string, open: boolean): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {


		dispatch(EntityCreators.CancelFormEdits(formId));
		dispatch(EntityCreators.SetFormOpen(formId, open));
	}
}

export function onEditEntity(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const entityState = getState().Entity;
		const entityId = entityState.entityData!.entity.id;

		const entityEditForm = entityState.entityEditForm;

		dispatch(EntityCreators.ClearFormError(entityEditForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(EntityCreators.FormChange(entityEditForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(entityEditForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(EntityCreators.SetFormError(entityEditForm.id, true));
			dispatch(EntityCreators.SetFormErrorMessages(entityEditForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const editRequest: ScottlandEntityRequest = {

				name: entityEditForm.fields.name.value as string,
				branchId: entityEditForm.fields.branch.value as number,
				users: entityEditForm.fields.members.value as unknown as number[],
				establishedAt: moment(entityEditForm.fields.establishedAt.value as string).unix(),
				ratePlanId: entityEditForm.fields.ratePlan.value as number,
				balanceForwardPrincipal: entityEditForm.fields.balanceForwardPrincipal.value as number || 0,
				balanceForwardInterest: entityEditForm.fields.balanceForwardInterest.value as number || 0,
				active: entityEditForm.fields.active.value ? true : false as boolean
			}

			dispatch(EntityCreators.SetFormLoading(entityEditForm.id, true));

			try {

				const updatedEntity = await ScottlandApi.EditEntity(entityId.toString(), editRequest);

				dispatch(EntityCreators.SetEntityData(updatedEntity));
				dispatch(EntityCreators.CancelFormEdits(entityEditForm.id));
				dispatch(EntityCreators.SetFormOpen(entityEditForm.id, false));

			}
			catch (e) {
				const error = e as ScottlandApiError;

				dispatch(EntityCreators.SetFormError(entityEditForm.id, true));
				dispatch(EntityCreators.SetFormErrorMessages(entityEditForm.id, error.errors));

			}
			finally {
				dispatch(EntityCreators.SetFormLoading(entityEditForm.id, false));
			}
		}
	}
}

export function onDeleteEntity(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const entityState = getState().Entity;
		const entityId = entityState.entityData!.entity.id;

		const deleteEntityForm = entityState.deleteEntityForm;

		dispatch(EntityCreators.ClearFormError(deleteEntityForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(EntityCreators.FormChange(deleteEntityForm.id, targetField, fieldValue, !fieldIsValid));
		}

		let formIsValid: boolean = ValidateValidationForm(deleteEntityForm.fields, formChangeCallback);

		formIsValid = formIsValid && (deleteEntityForm.fields.confirmEntityName.value === entityState.entityData!.entity.name);

		if (formIsValid === false) {
			dispatch(EntityCreators.SetFormError(deleteEntityForm.id, true));
			dispatch(EntityCreators.SetFormErrorMessages(deleteEntityForm.id, ['Please type the exact name of the entity to delete.']));
		}
		else {

			dispatch(EntityCreators.SetFormLoading(deleteEntityForm.id, true));

			try {

				const updatedEntity = await ScottlandApi.DeleteEntity(entityId.toString());

				dispatch(EntityCreators.SetEntityDeleted())

			}
			catch (e) {
				const error = e as ScottlandApiError;

				dispatch(EntityCreators.SetFormError(deleteEntityForm.id, true));
				dispatch(EntityCreators.SetFormErrorMessages(deleteEntityForm.id, error.errors));

			}
			finally {
				dispatch(EntityCreators.SetFormLoading(deleteEntityForm.id, false));
			}
		}
	}
}

export function onDownloadLedgerFile(ledgerFileDownloadRef: React.RefObject<HTMLFormElement>): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const authToken = await (AuthenticationService.GetAccessToken() as any);

		const entityState = getState().Entity;
		const entityId = entityState.entityData!.entity.id;

		const transactionsTableSettingsForm = entityState.transactionsTableSettingsForm;
		const startDateMoment = moment(transactionsTableSettingsForm.fields.startDate.value as string);
		const endDateMoment = moment(transactionsTableSettingsForm.fields.endDate.value as string);
		const includeEphemeral =
			transactionsTableSettingsForm.fields.showEphermalTransactions.value as unknown as boolean === true ||
				transactionsTableSettingsForm.fields.showEphermalTransactions.value as string === 'true' ? true : false;


		ledgerFileDownloadRef!.current!.action = ScottlandApi.BuildTransactionLedgerFileUrl(
			entityId,
			startDateMoment.isValid() === true ? startDateMoment.unix() : 0,
			endDateMoment.isValid() === true ? endDateMoment.unix() : 0,
			includeEphemeral
		);
		
		(ledgerFileDownloadRef!.current!.children!.namedItem('clientId')! as any).value = Config.oktaClientId;
		(ledgerFileDownloadRef!.current!.children!.namedItem('token')! as any).value = authToken.accessToken;

		ledgerFileDownloadRef!.current!.submit();

		ledgerFileDownloadRef!.current!.action = '';
		(ledgerFileDownloadRef!.current!.children!.namedItem('clientId')! as any).value = '';
		(ledgerFileDownloadRef!.current!.children!.namedItem('token')! as any).value = '';

	}
}





