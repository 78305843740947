import * as React from 'react';
import { Segment, Comment, Icon, Form, Message } from 'semantic-ui-react';
import moment from 'moment';
import { TransactionNoteEditViewProps } from './interfaces/TransactionNoteEditViewProps';

const TransactionNoteEditView = (props: TransactionNoteEditViewProps) => (
  <>

    <Comment.Author as='a'>
      {props.note.createdBy}
    </Comment.Author>
    <Comment.Metadata>
      <div>
        {`${moment.unix(props.note.createdAt).format('MM/DD/YYYY hh:mm a')} (${moment.duration(moment.unix(props.note.createdAt).diff(new Date())).humanize(true)}) `}
      </div>
    </Comment.Metadata>
    {props.allowAdministrativeNotes &&
      <div>
        <Comment.Metadata>
          <div>
            <Icon
              name={props.form.fields.isAdministrative.value as any === true ? 'eye slash' : 'eye'}
            />
            {props.form.fields.isAdministrative.value as any === true ? 'Administrative note' : 'Visible to members'}
          </div>
        </Comment.Metadata>
      </div>
    }
    <Form
      reply
      style={{ marginBottom: '1em' }}
      error={props.form.error}
      loading={props.form.loading}
    >
      <Form.TextArea
        id={props.form.fields.note.id}
        name={props.form.fields.note.id}
        placeholder={props.form.fields.note.placeholder}
        value={props.form.fields.note.value as any}
        error={props.form.fields.note.error}
        onChange={props.onFormChange}
        rows={5}
        style={{
          height: 'unset'
        }}
      />
      <Form.Group inline widths='equal'>
        {props.allowAdministrativeNotes &&
          <Form.Field style={{ cursor: 'pointer', position: 'relative', top: '-.3em' }}
            onClick={() => props.onFormChange(
              {
                target: {
                  name: props.form.fields.isAdministrative.id,
                  value: !(props.form.fields.isAdministrative.value as any)
                }
              }, null
            )}
          >
            <Icon
              size='large'
              name={props.form.fields.isAdministrative.value as any === true ?
                'check circle outline' : 'circle outline'
              }
              color={props.form.fields.isAdministrative.value as any === true ?
                'olive' : 'grey'
              }
            />
            {props.form.fields.isAdministrative.label}
          </Form.Field>
        }
        <Form.Field className='add-transaction-note-form-field actions'>
          <div style={{ float: 'right' }}>
            <a onClick={() => props.onSetEditNoteId(null)}><Icon name='undo' />Cancel</a>
            <a onClick={() => props.onEditNote()}><Icon name='save' />Save</a>
          </div>
        </Form.Field>
      </Form.Group>
      <Message
        error
        size='mini'
        icon='exclamation'
        header='Oops. There was an error updating this note.'
        list={props.form.errorMessages}
      />
    </Form>
  </>
)

export default TransactionNoteEditView 