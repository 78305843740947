
import { Security } from '@okta/okta-react';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import AppContainer from './components/App/AppContainer';
import './css/styles.css';
import { oktaConfig } from './lib/Authentication/AuthenticationService';
import store, { history } from './redux/store';
import * as serviceWorker from './serviceWorker';

const domTarget = document.getElementById('root');

const handleAuthRequired = (params: any) => (params.history.push('/'));

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Security
        issuer={oktaConfig.issuer}
        client_id={oktaConfig.clientId}
        audience={oktaConfig.clientId}
        onAuthRequired={handleAuthRequired}
        redirect_uri={oktaConfig.baseUri}
      >
        <AppContainer />
      </Security>
    </ConnectedRouter>
  </Provider>,
  domTarget
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
