

import { Reducer } from 'redux';
import * as MembersActions from './creators/interfaces/MembersCreators';
import { MembersAction } from './creators/interfaces/MembersCreators';
import { MembersActionTypes } from './creators/MembersCreators';
import { handleCancelFormEdits, handleClearFormError, handleFormChange, handleSetFormError, handleSetFormErrorMessages, handleSetFormLoading, handleSetFormOpen } from './FormReducerHandlers';
import { MembersState } from './interfaces/MembersState';


const initialState: MembersState = {
  loading: false,
  error: false,
  errorMessages: [],
  members: [],
  branches: [],
  entities: [],
  addMemberForm: {
    id: 'addMemberForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      id: {
        id: 'id',
        label: 'id',
        placeholder: 'id',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      firstName: {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'First Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      middleName: {
        id: 'middleName',
        label: 'Middle Name',
        placeholder: 'Middle Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      lastName: {
        id: 'lastName',
        label: 'Last Name',
        placeholder: 'Last Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      email: {
        id: 'email',
        label: 'Email',
        placeholder: 'Email',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      branch: {
        id: 'branch',
        label: 'Branch',
        placeholder: 'Branch',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      entities: {
        id: 'entities',
        label: 'Entities',
        placeholder: 'Select one or more entities',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  },
  editMemberForm: {
    id: 'editMemberForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      id: {
        id: 'id',
        label: 'id',
        placeholder: 'id',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
        countsAsTouched: false
      },
      firstName: {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'First Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      middleName: {
        id: 'middleName',
        label: 'Middle Name',
        placeholder: 'Middle Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      },
      lastName: {
        id: 'lastName',
        label: 'Last Name',
        placeholder: 'Last Name',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      email: {
        id: 'email',
        label: 'Email',
        placeholder: 'Email',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      branch: {
        id: 'branch',
        label: 'Branch',
        placeholder: 'Branch',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: true },
      },
      entities: {
        id: 'entities',
        label: 'Entities',
        placeholder: 'Select one or more entities',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  },
  deleteMemberForm: {
    id: 'deleteMemberForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      id: {
        id: 'id',
        label: 'id',
        placeholder: 'id',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  },
  memberFilterForm:{
    id: 'memberFilterForm',
    loading: false,
    error: false,
    errorMessages: [],
    open: false,
    touched: false,
    touchedCount: 0,
    fields: {
      filter: {
        id: 'filter',
        label: 'filter',
        placeholder: 'Type to filter',
        value: null,
        originalValue: null,
        error: false,
        touched: false,
        validation: { required: false },
      }
    }
  }
};

const handleResetToInitialState = (state: MembersState, action: MembersAction): MembersState => {
  return {
    ...initialState
  };
}

const handleSetLoading = (state: MembersState, action: MembersActions.SetLoadingAction): MembersState => {
  return {
    ...state,
    loading: action.loading
  };
}

const handleSetError = (state: MembersState, action: MembersActions.SetErrorAction): MembersState => {
  return {
    ...state,
    error: action.error
  };
}

const handleSetErrorMessages = (state: MembersState, action: MembersActions.SetErrorMessagesAction): MembersState => {
  return {
    ...state,
    errorMessages: action.errorMessages
  };
}

const handleSetMembers = (state: MembersState, action: MembersActions.SetMembersAction): MembersState => {
  return {
    ...state,
    members: action.members
  };
}

const handleSetBranches = (state: MembersState, action: MembersActions.SetBranchesAction): MembersState => {
  return {
    ...state,
    branches: action.branches,
    addMemberForm: {
      ...state.addMemberForm,
      fields: {
        ...state.addMemberForm.fields,
        branch: {
          ...state.addMemberForm.fields.branch,
          options:
            action.branches.map(branch => ({ key: branch.id.toString(), value: branch.id.toString(), text: branch.name }))
        }
      }
    },
    editMemberForm: {
      ...state.editMemberForm,
      fields: {
        ...state.editMemberForm.fields,
        branch: {
          ...state.editMemberForm.fields.branch,
          options:
            action.branches.map(branch => ({ key: branch.id.toString(), value: branch.id.toString(), text: branch.name }))
        }
      }
    }
  };
}

const handleSetEntities = (state: MembersState, action: MembersActions.SetEntitiesAction): MembersState => {
  return {
    ...state,
    entities: action.entities,
    addMemberForm: {
      ...state.addMemberForm,
      fields: {
        ...state.addMemberForm.fields,
        entities: {
          ...state.addMemberForm.fields.entities,
          options:
            action.entities.map(entity => ({ key: entity.id.toString(), value: entity.id.toString(), text: entity.name }))
        }
      }
    },
    editMemberForm: {
      ...state.editMemberForm,
      fields: {
        ...state.editMemberForm.fields,
        entities: {
          ...state.editMemberForm.fields.entities,
          options:
            action.entities.map(entity => ({ key: entity.id.toString(), value: entity.id.toString(), text: entity.name }))
        }
      }
    }
  };
}

const handleSetEditMember = (state: MembersState, action: MembersActions.SetEditMemberAction): MembersState => {


  const editMember = state.members.find(member => member.id === action.memberIdToEdit)!;

  const entitiesFieldValue = action.memberIdToEdit === null ? null : editMember.entities.map( entity => ( entity.key.toString() )) as any;

  return {
    ...state,
    editMemberForm: {
      ...state.editMemberForm,
      error: false,
      touched: false,
      touchedCount: 0,
      fields: {
        ...state.editMemberForm.fields,
        id: {
          ...state.editMemberForm.fields.id,
          value: action.memberIdToEdit === null ? null : editMember.id,
          originalValue: action.memberIdToEdit === null ? null : editMember.id,
          error: false,
          touched: false
        },
        firstName: {
          ...state.editMemberForm.fields.firstName,
          value: action.memberIdToEdit === null ? null : editMember.firstName,
          originalValue: action.memberIdToEdit === null ? null : editMember.firstName,
          error: false,
          touched: false
        },
        middleName: {
          ...state.editMemberForm.fields.middleName,
          value: action.memberIdToEdit === null ? null : editMember.middleName,
          originalValue: action.memberIdToEdit === null ? null : editMember.middleName,
          error: false,
          touched: false
        },
        lastName: {
          ...state.editMemberForm.fields.lastName,
          value: action.memberIdToEdit === null ? null : editMember.lastName,
          originalValue: action.memberIdToEdit === null ? null : editMember.lastName,
          error: false,
          touched: false
        },
        email: {
          ...state.editMemberForm.fields.email,
          value: action.memberIdToEdit === null ? null : editMember.email,
          originalValue: action.memberIdToEdit === null ? null : editMember.email,
          error: false,
          touched: false
        },
        branch: {
          ...state.editMemberForm.fields.branch,
          value: action.memberIdToEdit === null ? null : editMember.branch.key.toString(),
          originalValue: action.memberIdToEdit === null ? null : editMember.branch.key.toString(),
          error: false,
          touched: false
        },
        entities: {
          ...state.editMemberForm.fields.entities,
          value: entitiesFieldValue,
          originalValue: entitiesFieldValue,
          error: false,
          touched: false
        },
      }
    }
  };
}

const handleAddMember = (state: MembersState, action: MembersActions.AddMemberAction): MembersState => {
  return {
    ...state,
    members: [action.member].concat(state.members)
  };
}

const handleUpdateMember = (state: MembersState, action: MembersActions.UpdateMemberAction): MembersState => {

  return {
    ...state,
    members: state.members.map( member => {

      if(member.id === action.member.id) {
        return action.member
      }
      return member;
    })
  };
}

const handleRemoveMember = (state: MembersState, action: MembersActions.RemoveMemberAction): MembersState => {
  return {
    ...state,
    members: state.members.filter( member => member.id !== action.memberIdToRemove)
  };
}

const handlers: { [x: string]: (state: MembersState, action: MembersAction) => MembersState } =
  {
    [MembersActionTypes.ResetToInitialState]: handleResetToInitialState,
    [MembersActionTypes.SetLoading]: handleSetLoading,
    [MembersActionTypes.SetError]: handleSetError,
    [MembersActionTypes.SetErrorMessages]: handleSetErrorMessages,
    [MembersActionTypes.SetFormLoading]: handleSetFormLoading,
    [MembersActionTypes.SetFormError]: handleSetFormError,
    [MembersActionTypes.SetFormErrorMessages]: handleSetFormErrorMessages,
    [MembersActionTypes.ClearFormError]: handleClearFormError,
    [MembersActionTypes.FormChange]: handleFormChange,
    [MembersActionTypes.SetFormOpen]: handleSetFormOpen,
    [MembersActionTypes.CancelFormEdits]: handleCancelFormEdits,
    [MembersActionTypes.SetMembers]: handleSetMembers,
    [MembersActionTypes.SetBranches]: handleSetBranches,
    [MembersActionTypes.SetEntities]: handleSetEntities,
    [MembersActionTypes.AddMember]: handleAddMember,
    [MembersActionTypes.UpdateMember]: handleUpdateMember,
    [MembersActionTypes.RemoveMember]: handleRemoveMember,
    [MembersActionTypes.SetEditMember]: handleSetEditMember
  } as any

const RatePlanReducer: Reducer<MembersState, MembersAction> = (state: MembersState | undefined, action: MembersAction): MembersState => {
  if (state === undefined) {
    return initialState;
  }

  if (action && action.type && handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  }
  else {
    return state;
  }
}

export default RatePlanReducer