import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const LoginRedirectView = () => (
	<Segment placeholder basic>
		<Header icon>
      <Icon name='sync alternate' color='olive' loading />
      Just a sec. We are checking your authentication...
    </Header>
	</Segment>
)

export default LoginRedirectView