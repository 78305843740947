import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { Validate, ValidateValidationForm } from "../../../lib/Validation/Validation";
import * as RatePlanCreators from '../../../redux/creators/RatePlanCreators';
import { FormField } from "../../../redux/interfaces/common/FormField";
import { FormState } from "../../../redux/interfaces/common/FormState";
import { RatePlanFormFields } from "../../../redux/interfaces/RatePlanState";
import StoreState from "../../../redux/interfaces/StoreState";
import * as ScottlandApi from '../../../lib/ScottlandApi'
import { ScottlandRatePlanRequest } from "../../../lib/interfaces/ScottlandRatePlanRequest";
import { ScottlandRateChangeRequest } from "../../../lib/interfaces/ScottlandRateChangeRequest";
import moment from "moment";
import { ScottlandApiError } from "../../../lib/interfaces/ScottlandApiError";

export function onLoad(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>) => {

		dispatch(RatePlanCreators.ResetToInitialState())
		dispatch(RatePlanCreators.SetLoading(true));
		dispatch(RatePlanCreators.SetError(false));

		try {

			const entityData = await ScottlandApi.GetRatePlanView();

			dispatch(RatePlanCreators.SetRatePlanData(entityData));

		}
		catch {
			dispatch(RatePlanCreators.SetError(true));
		}
		finally {
			dispatch(RatePlanCreators.SetLoading(false));
		}
	}
}

export function onFormChange(formId: string, e: any, data: any): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		let targetField: (string | null | undefined) = e.target.name;
		let fieldValue: (string | null | undefined) = e.target.value;

		if (targetField === null || targetField === undefined || targetField === '') {
			targetField = data.name;

			if (data.type === 'checkbox') {
				fieldValue = data.checked;
			}
			else {
				fieldValue = data.value;
			}
		}

		if (targetField === null ||
			targetField === undefined) {
			throw new TypeError(`No target field sent to change handler: ${targetField}`);
		}

		const formState = (getState().RatePlan as any)[formId as any] as FormState<RatePlanFormFields>;

		if (formState === null ||
			formState === undefined) {
			throw new TypeError(`Unknown form ${formId}`);
		}

		const formFieldState: FormField = (formState.fields as any)[targetField as any] as FormField;

		if (formFieldState === null ||
			formFieldState === undefined) {
			throw new TypeError(`Unknown form field ${targetField}`);
		}

		const fieldIsValid = Validate(fieldValue,
			formFieldState.validation.required,
			formFieldState.validation.validators);

		dispatch(RatePlanCreators.FormChange(formId, targetField, fieldValue, !fieldIsValid));

	}
}

export function onCancelFormEdits(formId: string): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(RatePlanCreators.CancelFormEdits(formId));
	}
}

export function onSetFormOpen(formId: string, open: boolean): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {


		dispatch(RatePlanCreators.CancelFormEdits(formId));
		dispatch(RatePlanCreators.SetFormOpen(formId, open));
	}
}

export function onAddRatePlan(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const addRatePlanForm = getState().RatePlan.addRatePlanForm;

		dispatch(RatePlanCreators.ClearFormError(addRatePlanForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(RatePlanCreators.FormChange(addRatePlanForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(addRatePlanForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(RatePlanCreators.SetFormError(addRatePlanForm.id, true));
			dispatch(RatePlanCreators.SetFormErrorMessages(addRatePlanForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const newRatePlan: ScottlandRatePlanRequest = {
				name: addRatePlanForm.fields.name.value as string,
				description: addRatePlanForm.fields.description.value as string,
				initialDepositRate: addRatePlanForm.fields.initialDepositRate.value as number / Math.pow(10, 2),
				initialAdvanceRate: addRatePlanForm.fields.initialAdvanceRate.value as number / Math.pow(10, 2)
			}

			dispatch(RatePlanCreators.SetFormLoading(addRatePlanForm.id, true));

			try {
				const addedRatePlan = await ScottlandApi.CreateRatePlan(newRatePlan);

				dispatch(RatePlanCreators.AddRatePlan(addedRatePlan));
				dispatch(RatePlanCreators.CancelFormEdits(addRatePlanForm.id));
				dispatch(RatePlanCreators.SetFormOpen(addRatePlanForm.id, false));


			}
			catch {
				dispatch(RatePlanCreators.SetFormError(addRatePlanForm.id, true));
			}
			finally {
				dispatch(RatePlanCreators.SetFormLoading(addRatePlanForm.id, false));
			}
		}
	}
}

export function onUpdateRatePlan(ratePlanId: number): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const ratePlanState = getState().RatePlan;

		const ratePlanEditFormId = ratePlanId + 'edit';

		const editRatePlanForm = (getState().RatePlan as any)[ratePlanEditFormId] as unknown as FormState<RatePlanFormFields>;

		dispatch(RatePlanCreators.ClearFormError(editRatePlanForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(RatePlanCreators.FormChange(editRatePlanForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(editRatePlanForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(RatePlanCreators.SetFormError(editRatePlanForm.id, true));
			dispatch(RatePlanCreators.SetFormErrorMessages(editRatePlanForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const ratePlanToUpdate: ScottlandRatePlanRequest = {
				name: editRatePlanForm.fields.name.value as string,
				description: editRatePlanForm.fields.description.value as string,
				initialDepositRate: editRatePlanForm.fields.initialDepositRate.value as number / Math.pow(10, 2),
				initialAdvanceRate: editRatePlanForm.fields.initialAdvanceRate.value as number / Math.pow(10, 2)
			}

			dispatch(RatePlanCreators.SetFormLoading(editRatePlanForm.id, true));

			try {

				const updatedRatePlan = await ScottlandApi.UpdateRatePlan(ratePlanId.toString(), ratePlanToUpdate);

				dispatch(RatePlanCreators.SetRatePlan(updatedRatePlan));

			}
			catch {
				dispatch(RatePlanCreators.SetFormError(editRatePlanForm.id, true));
			}
			finally {
				dispatch(RatePlanCreators.SetFormLoading(editRatePlanForm.id, false));
			}
		}
	}
}

export function onDeleteRatePlan(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const ratePlanState = getState().RatePlan;
		const deleteRatePlanId = ratePlanState.deleteRatePlanId
		const deleteRatePlanForm = ratePlanState.deleteRatePlanForm;

		if (deleteRatePlanId === null) {
			throw new TypeError('We do not have a rate plan id to delete!');
		}


		dispatch(RatePlanCreators.ClearFormError(deleteRatePlanForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(RatePlanCreators.FormChange(deleteRatePlanForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(deleteRatePlanForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(RatePlanCreators.SetFormError(deleteRatePlanForm.id, true));
			dispatch(RatePlanCreators.SetFormErrorMessages(deleteRatePlanForm.id, ['Please fill out all the required fields.']));
		}
		else {

			dispatch(RatePlanCreators.SetFormLoading(deleteRatePlanForm.id, true));

			try {

				await ScottlandApi.DeleteRatePlan(deleteRatePlanId.toString());

				dispatch(RatePlanCreators.RemoveRatePlan(deleteRatePlanId));
				dispatch(RatePlanCreators.CancelFormEdits(deleteRatePlanForm.id));
				dispatch(RatePlanCreators.SetDeleteRatePlanId(null));


			}
			catch {
				dispatch(RatePlanCreators.SetFormError(deleteRatePlanForm.id, true));
			}
			finally {
				dispatch(RatePlanCreators.SetFormLoading(deleteRatePlanForm.id, false));
			}
		}

	}
}

export function onSetEditRatePlanId(ratePlanId: number | null): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(RatePlanCreators.SetEditRatePlanId(ratePlanId));
	}
}

export function onSetDeleteRateChangeId(rateChangeId: number | null): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(RatePlanCreators.SetDeleteRateChangeId(rateChangeId));
	}
}

export function onSetDeleteRatePlanId(ratePlanId: number | null): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(RatePlanCreators.SetDeleteRatePlanId(ratePlanId));
	}
}

export function onSetAddRateChangeRatePlanId(ratePlanId: number | null): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		dispatch(RatePlanCreators.CancelFormEdits(getState().RatePlan.addRateChangeForm.id));
		dispatch(RatePlanCreators.SetAddRateChangeRatePlanId(ratePlanId));
	}
}

export function onAddRateChange(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const ratePlanState = getState().RatePlan;
		const addRateChangeRatePlanId = ratePlanState.addRateChangeRatePlanId
		const addRateChangeForm = ratePlanState.addRateChangeForm;

		if (addRateChangeRatePlanId === null) {
			throw new TypeError('We do not have a rate plan to add the rate change to.')
		}

		dispatch(RatePlanCreators.ClearFormError(addRateChangeForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(RatePlanCreators.FormChange(addRateChangeForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(addRateChangeForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(RatePlanCreators.SetFormError(addRateChangeForm.id, true));
			dispatch(RatePlanCreators.SetFormErrorMessages(addRateChangeForm.id, ['Please fill out all the required fields.']));
		}
		else {

			const newRateChange: ScottlandRateChangeRequest = {
				effectiveAt: moment(addRateChangeForm.fields.effectiveAt.value as string).unix(),
				isDepositRate: addRateChangeForm.fields.isDepositRate.value as unknown as boolean,
				rate: addRateChangeForm.fields.rate.value as number / Math.pow(10, 2)
			}

			dispatch(RatePlanCreators.SetFormLoading(addRateChangeForm.id, true));

			try {

				const updatedRatePlan = await ScottlandApi.CreateRateChange(addRateChangeRatePlanId.toString(), newRateChange);

				dispatch(RatePlanCreators.SetRatePlan(updatedRatePlan));
				dispatch(RatePlanCreators.CancelFormEdits(addRateChangeForm.id));
				dispatch(RatePlanCreators.SetAddRateChangeRatePlanId(null));


			}
			catch(e) {
				const error = e as ScottlandApiError;

				dispatch(RatePlanCreators.SetFormError(addRateChangeForm.id, true));
				dispatch(RatePlanCreators.SetFormErrorMessages(addRateChangeForm.id, error.errors));

			}
			finally {
				dispatch(RatePlanCreators.SetFormLoading(addRateChangeForm.id, false));
			}
		}
	}
}

export function onDeleteRateChange(): ThunkAction<void, StoreState, void, any> {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {

		const ratePlanState = getState().RatePlan;
		const deleteRateChangeId = ratePlanState.deleteRateChangeId
		const deleteRateChangeForm = ratePlanState.deleteRateChangeForm;

		if (deleteRateChangeId === null) {
			throw new TypeError('We do not have a rate change id to delete!');
		}

		const ratePlan = ratePlanState.ratePlanData!
			.find(ratePlan =>
				ratePlan.rateChanges.find( rateChange => rateChange.id ===deleteRateChangeId) !== undefined
			);

		if(ratePlan === undefined) {
			throw new TypeError('We can not find the parent rate plan to delete from!');
		}

		dispatch(RatePlanCreators.ClearFormError(deleteRateChangeForm.id, false));

		const formChangeCallback = (targetField: string, fieldValue: any, fieldIsValid: boolean) => {
			dispatch(RatePlanCreators.FormChange(deleteRateChangeForm.id, targetField, fieldValue, !fieldIsValid));
		}

		const formIsValid: boolean = ValidateValidationForm(deleteRateChangeForm.fields, formChangeCallback);

		if (formIsValid === false) {
			dispatch(RatePlanCreators.SetFormError(deleteRateChangeForm.id, true));
			dispatch(RatePlanCreators.SetFormErrorMessages(deleteRateChangeForm.id, ['Please fill out all the required fields.']));
		}
		else {

			dispatch(RatePlanCreators.SetFormLoading(deleteRateChangeForm.id, true));

			try {

				const updatedRatePlan = await ScottlandApi.DeleteRateChange(ratePlan.id.toString(), deleteRateChangeId.toString());

				dispatch(RatePlanCreators.SetRatePlan(updatedRatePlan));
				dispatch(RatePlanCreators.CancelFormEdits(deleteRateChangeForm.id));
				dispatch(RatePlanCreators.SetDeleteRateChangeId(null));


			}
			catch {
				dispatch(RatePlanCreators.SetFormError(deleteRateChangeForm.id, true));
			}
			finally {
				dispatch(RatePlanCreators.SetFormLoading(deleteRateChangeForm.id, false));
			}
		}
	}
}

