import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const TransactionNotesEmptyView = () => (
	<Segment basic textAlign='center'>
		<Header as='h5' style={{margin:0}}>
      This transaction has no notes.
    </Header>
	</Segment>
)

export default TransactionNotesEmptyView